import jspreadsheet from 'jspreadsheet';
import {
  ALIGNMENTS,
  CellCurrencies,
  CellNumberFormat,
  CURRENCIES,
  NUMBER_FORMAT,
  ToolbarItem,
  ToolbarOptions
} from '.';
import {
  getDeepNestedKeybyValue,
  getKeyByValue,
  META_NAME
} from '../../../pages/loggerRefactor/helper';
import { countDecimal } from '../../../pages/loggerRefactor/utils';
import {
  currencyCellFormatter,
  extractNumberFormat,
  getCellDecimalLength,
  numericCellformatter,
  setDecimalMask,
  validateDecimalFormat
} from '../../../utils/helpersFunction';
import { isNaN } from '../../../utils/lodash';
import { getCellName } from './utils';

interface Item {
  type?: 'icon' | 'divisor' | 'label' | 'select';
  content?: string;
  options?: Array<string>;
  title?: string;
  tooltip?: string;
  value?: string | number;
  width?: number;
  render?: (c: string) => void;
  onclick?: (
    el: HTMLElement,
    iconObject: any,
    toolbarItem: HTMLElement
  ) => void;
  onchange?: (
    el: HTMLElement,
    pickerObject: any,
    reservedValue: any,
    itemValue: string,
    itemKey: string,
    mouseEvent: any
  ) => void;
  updateState?: (
    toolbarObject: any,
    toolbarItem: HTMLElement,
    option: any,
    extendOption: any
  ) => void;
}

const Alignment = (action: any): Item => {
  return {
    type: 'select',
    options: Array.from(ALIGNMENTS.keys()),
    render: (icon: string) => {
      return '<i class="material-icons">' + icon + '</i>';
    },
    onchange: (a: any, b: any, selectedAlignment: string) => {
      // @ts-ignore
      const currentWorksheet = jspreadsheet.current;
      if (currentWorksheet) {
        const selected = currentWorksheet.getSelected(false, true);
        const selectedCells = selected.filter((cell: any) => {
          const splitted: any = currentWorksheet
            .getStyle(getCellName(cell.x, cell.y))
            .replace(/\s/g, '')
            .split(';')
            .find((element: any) => element.includes('text-align'));
          if (splitted) {
            if (splitted.split(':')[1] !== selectedAlignment.split('_')[2])
              return { row: cell.y, column: cell.x };
          } else {
            return { row: cell.y, column: cell.x };
          }
        });

        currentWorksheet.setStyle(
          selectedCells,
          'text-align',
          selectedAlignment.split('_')[2]
        );
        action(selectedCells, ALIGNMENTS.get(selectedAlignment));
      }
    },
    updateState: (a: any, b: any, item: any, sheet: any) => {
      const cell = sheet.selectedCell;
      if (cell && cell.length) {
        if (sheet.records[cell[1]][cell[0]].element) {
          const value =
            'format_align_' +
            (sheet.records[cell[1]][cell[0]].element.style.textAlign ||
              sheet.options.defaultColAlign ||
              'center');
          const index = item.picker.options.data.indexOf(value);
          item.picker.setValue(index);
        }
      }
    }
  };
};

export const FROZEN_COLUMN_DEFAULT_WIDTH = 100;
export const FROZEN_COLUMN_LIMIT = 4;

const FreezeColumn = (action: any, payload: any): Item => {
  const value = payload.value || 0;
  const options = [
    'No columns',
    '1 column',
    '2 columns',
    '3 columns',
    '4 columns'
  ];
  return {
    type: 'select',
    options,
    value,
    render: (option: string) => {
      return '<span>' + option + '</span>';
    },
    onchange: (a: any, b: any, selectedValue: string) => {
      // @ts-ignore
      const currentWorksheet = jspreadsheet.current;
      const numberOfColumn = Number.parseInt(selectedValue.split(' ')[0]);
      if (currentWorksheet) {
        if (isNaN(numberOfColumn)) currentWorksheet.resetFreezeColumns();
        else {
          for (let i = 0; i < numberOfColumn; i++) {
            currentWorksheet.setProperties(i, {
              width: FROZEN_COLUMN_DEFAULT_WIDTH
            });
          }
          currentWorksheet.setFreezeColumns(numberOfColumn);
        }
      }
      action(isNaN(numberOfColumn) ? 0 : numberOfColumn);
    },
    updateState: () => {}
  };
};

const FreezeRow = (action: any, payload: any): Item => {
  const value = payload.value || 0;
  const options = [
    'No rows',
    '1 row',
    '2 rows',
    '3 rows',
    '4 rows',
    '5 rows',
    '6 rows',
    '7 rows',
    '8 rows',
    '9 rows',
    '10 rows'
  ];
  return {
    type: 'select',
    value,
    options,
    render: (option: string) => {
      return '<span>' + option + '</span>';
    },
    onchange: (a: any, b: any, selectedValue: string) => {
      // @ts-ignore
      const currentWorksheet = jspreadsheet.current;
      const numberOfRow = Number.parseInt(selectedValue.split(' ')[0]);
      if (currentWorksheet) {
        if (isNaN(numberOfRow)) currentWorksheet.resetFreezeRows();
        else currentWorksheet.setFreezeRows(numberOfRow);
      }
      action(isNaN(numberOfRow) ? 0 : numberOfRow);
    },
    updateState: () => {}
  };
};

const Currency = (action: any, payload?: any): Item => {
  return {
    type: 'select',
    options: Array.from(CURRENCIES.keys()),
    render: (option: string) => {
      return '<span>' + option + '</span>';
    },
    onchange: (a: any, b: any, selectedCurrency: string) => {
      // @ts-ignore
      const currentWorksheet = jspreadsheet.current;
      if (currentWorksheet) {
        const selected = currentWorksheet.getSelected(false, true);
        const cellAttributes: any = payload?.value ?? {};
        selected.map((cell: any) => {
          const cellName = getCellName(cell.x, cell.y);
          const cellValue = currentWorksheet.getValue(cellName);
          const selectedCell = currentWorksheet.getCells(cellName);

          // generate new masking format based on current format and selected option
          const option = CURRENCIES.get(selectedCurrency);
          const existingFormat = extractNumberFormat(selectedCell?.mask);
          const newFormat = currencyCellFormatter(
            existingFormat,
            option,
            selectedCell?.formatOption
          );

          if (newFormat) {
            cellAttributes[cellName] = {
              ...selectedCell,
              type: 'numeric',
              mask: newFormat,
              formatOption: selectedCell?.formatOption
                ? selectedCell?.formatOption
                : META_NAME.PREFIX
            };
            // update cell properties;
            currentWorksheet.setProperties(
              cell.x,
              cell.y,
              cellAttributes[cellName]
            );
            // trigger cell update after cell attribute change
            currentWorksheet.setValue(cellName, cellValue);
          }
        });
        action(cellAttributes);
      }
    },
    updateState: (a: any, b: any, item: any, sheet: any) => {
      const cell = sheet.selectedCell;
      if (cell && cell.length) {
        const cellName = getCellName(cell[0], cell[1]);
        const cellProperties = sheet.getCells(cellName);
        if (cellProperties) {
          const mask = extractNumberFormat(cellProperties.mask);
          const option = getKeyByValue(
            CellCurrencies,
            mask?.currency === '' ? null : mask?.currency
          );
          const index = item.picker.options.data.indexOf(option);
          item.picker.setValue(index);
        } else {
          item.picker.setValue(0);
        }
      }
    }
  };
};

const NumberFormat = (action: any, payload?: any): Item => {
  return {
    type: 'select',
    options: ['Format', '$1,000.00', '$1,000', '1,000.00$', '1,000$'],
    render: (option: string) => {
      return '<span>' + option + '</span>';
    },
    onchange: (a: any, b: any, selectedFormat: string) => {
      // @ts-ignore
      const currentWorksheet = jspreadsheet.current;
      if (currentWorksheet) {
        const selected = currentWorksheet.getSelected(false, true);
        const cellAttributes: any = payload?.value ?? {};
        selected.map((cell: any) => {
          const cellName = getCellName(cell.x, cell.y);
          const cellValue = currentWorksheet.getValue(cellName);
          const selectedCell = currentWorksheet.getCells(cellName);
          // generate new masking format based on current format and selected option
          const option: any = NUMBER_FORMAT.get(selectedFormat);
          const existingFormat = extractNumberFormat(selectedCell?.mask);
          const newFormat = numericCellformatter(
            existingFormat,
            option[0],
            option[1]
          );
          if (newFormat) {
            cellAttributes[cellName] = {
              ...selectedCell,
              type: 'numeric',
              mask: newFormat,
              formatOption: option[0]
            };
            // update cell properties
            currentWorksheet.setProperties(
              cell.x,
              cell.y,
              cellAttributes[cellName]
            );
            // trigger cell update after cell properties change
            currentWorksheet.setValue(cellName, cellValue);
          }
        });
        action(cellAttributes);
      }
    },
    updateState: (a: any, b: any, item: any, sheet: any) => {
      const cell = sheet.selectedCell;
      if (cell && cell.length) {
        const cellName = getCellName(cell[0], cell[1]);
        const cellProperties = sheet.getCells(cellName);
        if (cellProperties) {
          const mask = extractNumberFormat(cellProperties.mask);
          const option = getDeepNestedKeybyValue(
            CellNumberFormat,
            mask?.format,
            cellProperties?.formatOption
          );
          const index = item.picker.options.data.indexOf(option);
          item.picker.setValue(index);
        } else {
          item.picker.setValue(0);
        }
      }
    }
  };
};

const IncreaseDecimal = (action: any, payload?: any): Item => {
  return {
    type: 'icon',
    content:
      '<img src="https://rekava-files.s3.ap-southeast-1.amazonaws.com/calculations/increase.png" width="18">',
    onclick() {
      // @ts-ignore
      const currentWorksheet = jspreadsheet.current;
      if (currentWorksheet) {
        const selected = currentWorksheet.getSelected(false, true);
        const cellAttributes: any = payload?.value ?? {};
        selected.map((cell: any) => {
          const cellName = getCellName(cell.x, cell.y);
          const cellValue = currentWorksheet.getValue(cellName);
          const cellValueDecimal = countDecimal(cellValue);
          if (!cellValue.toString().includes(',')) {
            const selectedCell = currentWorksheet.getCells(cellName);
            const propDecPlace = selectedCell?.mask;
            const existingFormatValue = (
              propDecPlace?.split('.')[1]?.match(/0/g) || []
            ).length;
            const currentDecPlace = getCellDecimalLength(
              cellValueDecimal,
              existingFormatValue
            );
            currentWorksheet.setProperties(cell.x, cell.y, {
              mask: setDecimalMask(currentDecPlace + 1),
              type: 'number'
            });
            currentWorksheet.setValue(cellName, cellValue);
          }
        });
        action(cellAttributes);
      }
    }
  };
};

const DecreaseDecimal = (action: any, payload?: any): Item => {
  return {
    type: 'icon',
    content:
      '<img src="https://rekava-files.s3.ap-southeast-1.amazonaws.com/calculations/decrease.png" width="18">',
    onclick() {
      // @ts-ignore
      const currentWorksheet = jspreadsheet.current;
      if (currentWorksheet) {
        const selected = currentWorksheet.getSelected(false, true);
        // const existingDecimal = countDecimal(selected[0]?.v);
        const cellAttributes: any = payload?.value ?? {};
        selected.map((cell: any) => {
          const cellName = getCellName(cell.x, cell.y);
          const cellValue = currentWorksheet.getValue(cellName);
          const cellValueDecimal = countDecimal(cellValue);
          if (!cellValue.toString().includes(',')) {
            const selectedCell = currentWorksheet.getCells(cellName);
            const existingMask = selectedCell?.mask;
            const existingFormatValue = (
              existingMask?.split('.')[1]?.match(/0/g) || []
            ).length;
            const currentDecPlace = getCellDecimalLength(
              cellValueDecimal,
              existingFormatValue
            );
            const newFormat = setDecimalMask(
              currentDecPlace === 0 ? existingMask : currentDecPlace - 1
            );
            currentWorksheet.setProperties(cell.x, cell.y, {
              mask: validateDecimalFormat(newFormat),
              type: 'number'
            });
            currentWorksheet.setValue(cellName, cellValue);
          }
        });
        action(cellAttributes);
      }
    }
  };
};

const FreezeIcon = {
  type: 'icon',
  content: 'ac_unit_outlined'
};

const NumberFormatIcon = {
  type: 'icon',
  content: 'attach_money_outlined'
};

const Separator = { type: 'divisor' };

export function getToolbar(options: Array<ToolbarItem>) {
  const toolbarOptions: Map<string, any> = new Map([
    [ToolbarOptions.DecreaseDecimal, DecreaseDecimal],
    [ToolbarOptions.IncreaseDecimal, IncreaseDecimal],
    [ToolbarOptions.TextAlignment, Alignment],
    [ToolbarOptions.FreezeColumn, FreezeColumn],
    [ToolbarOptions.FreezeRow, FreezeRow],
    [ToolbarOptions.Currency, Currency],
    [ToolbarOptions.NumberFormat, NumberFormat]
  ]);

  const items: Array<any> = [];
  options.forEach((option: ToolbarItem) => {
    const { item, action } = option;
    if (item === ToolbarOptions.FreezeRow) {
      items.push(Separator);
      items.push(FreezeIcon);
    }
    if (item === ToolbarOptions.Currency) {
      items.push(Separator);
      items.push(NumberFormatIcon);
    }
    if (item === ToolbarOptions.TextAlignment) {
      items.push(Separator);
    }
    if (option.payload)
      items.push(toolbarOptions.get(item)(action, option.payload));
    else items.push(toolbarOptions.get(item)(action));
  });

  return { items };
}
