import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import parse from 'html-react-parser';

import Icon from '../../../atoms/Icon';
import Text from '../../../atoms/Text';
import { ComposerInput } from '../composerInput';
import ComposerAtoms from '../../../atoms/composer';
import {
  CardsWrapper,
  HeadSection,
  SearchWrapper,
  ToolkitWrapper
} from './styles';

import { inputSwitchActionMenuComposer } from '../../../../store/composer/composerMenu/composerMenuActions';
import {
  // concatStringHTML,
  filterVariableManagerArray
} from '../../../../utils/helpersFunction';

import { VariableManagerPropsI } from './types';

const VariableManager = ({
  dataVariableManager,
  currentFocus,
  expandTableCellEditing,
  tooltip,
  navigating,
  register,
  id,
  setValue,
  getValues,
  itemClick,
  navigateClick
}: VariableManagerPropsI) => {
  const theme = useTheme();

  const [search, setSearch] = useState<string>('');

  const dispatch = useDispatch();

  useEffect(() => {
    setValue(id, '');
  }, []);

  function handlerClickVariableManager(
    event: React.SyntheticEvent,
    variable: string
  ) {
    if (itemClick) {
      event.stopPropagation();
      if (currentFocus?.includes('formula')) {
        itemClick('componentFormula', variable);
      } else if (expandTableCellEditing) {
        itemClick('expandTable', variable);
      }
    }
  }

  return (
    <ToolkitWrapper type="VARIABLE_MANAGER">
      <HeadSection>
        <Text variant="smallBold" color="#A3B3C2">
          VARIABLE MANAGER
        </Text>
        {id === 'searchVariableManagerMenu' ? (
          <Icon
            icon="closeNoBorder"
            color={theme.palette.action.disabled}
            size={20}
            onClick={() =>
              dispatch(inputSwitchActionMenuComposer('exitVariableManager'))
            }
          />
        ) : null}
      </HeadSection>
      <SearchWrapper>
        <ComposerInput.Text
          register={register}
          id={id}
          typeInput="transparant"
          placeholder="Search"
        />
        <Icon icon="search" onClick={() => setSearch(getValues(id))} />
      </SearchWrapper>
      <CardsWrapper>
        {filterVariableManagerArray(dataVariableManager, search).map(
          (value: { [key: string]: any }) => (
            <ComposerAtoms.VMCard
              currentFocus={currentFocus}
              expandTableCellEditing={expandTableCellEditing}
              id={value.id}
              description={
                value.description ? parse(value.description) : value.description
              }
              unit={value.unit ? parse(value.unit) : value.unit}
              variable={
                value.type === 'TABLE'
                  ? value.variable.replace('!', '')
                  : value.variable
              }
              notation={value.notation ? parse(value.notation) : value.notation}
              pageIndex={value.pageIndex?.name ? value.pageIndex?.name : null}
              tooltip={tooltip ? tooltip : null}
              navigating={navigating ? navigating : null}
              type={value.type}
              onMouseDown={(event: React.SyntheticEvent) =>
                currentFocus?.includes('formula')
                  ? event.preventDefault()
                  : null
              }
              onClick={(event: React.SyntheticEvent) =>
                handlerClickVariableManager(event, value.variable)
              }
              navigateClick={(id: string | JSX.Element | JSX.Element[]) =>
                navigateClick && navigateClick(id)
              }
            />
          )
        )}
      </CardsWrapper>
    </ToolkitWrapper>
  );
};

export default VariableManager;
