import { Menu, MenuProps, styled } from '@mui/material';

export const DefaultMenu = styled('div')(() => ({
  cursor: 'pointer'
}));
export const StyledMenu = styled(Menu)<MenuProps>({
  '& .MuiList-root': {
    padding: 0
  },
  '& .MuiMenu-List': {
    padding: 0
  }
});
