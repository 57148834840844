import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ErrorTemplate from '../pages/error/ErrorTemplate';

import api from '../service/api';
import ax from '../service/generic/axiosInstance';

const OPERATIONAL_ERROR = [400, 422];

const ErrorHandler = (props: any) => {
  const history = useHistory();

  const [error, setError] = useState<any>();

  const redirect = (error: any) => {
    const { message, action, actionLabel } = generateErrorProps(
      error.status,
      history
    );
    const buttonOnClick = () => {
      setError(null);
      action();
    };
    const actions = [
      {
        color: 'primary',
        variant: 'contained',
        label: actionLabel,
        onClick: buttonOnClick
      }
    ];
    const errorProps = {
      status: error.status.toString(),
      message,
      buttonLabel: actionLabel,
      actions
    };
    return (error.status === 404 &&
      (window.location.pathname.includes('accountSettings') ||
        window.location.pathname.includes('billingDetails'))) ||
      (error.status === 403 &&
        window.location.pathname.includes('businessDrives')) ? (
      ''
    ) : (
      <ErrorTemplate {...errorProps} />
    );
  };

  const toast = () => {
    setError(null);
  };

  api.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      const { status, message } = error.response.data;
      const { responseURL } = error.response.request;

      if (status === 401) {
        localStorage.clear();
        history.push('/');
        return Promise.reject(error);
      } else if (
        responseURL.includes('knowledge/book?companyId') &&
        status !== 403
      ) {
        return Promise.reject(error);
      } else {
        setError({ status, message });
        return Promise.reject(error);
      }
    }
  );

  ax.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      const { data, request } = error?.response;
      const { status, message } = data;
      const { responseURL } = request;

      if (status === 401) {
        localStorage.clear();
        history.push('/');
        return Promise.reject(error);
      } else if (responseURL.includes('calculations') && status === 403) {
        return Promise.reject(error);
      } else {
        setError({ status, message });
        return Promise.reject(error);
      }
    }
  );

  const isOperationalError = OPERATIONAL_ERROR.includes(error?.status);
  if (error && isOperationalError) toast();

  return (error &&
    !isOperationalError &&
    error?.status === 404 &&
    (window.location.pathname.includes('accountSettings') ||
      window.location.pathname.includes('billingDetails'))) ||
    (error &&
      !isOperationalError &&
      error.status === 403 &&
      window.location.pathname.includes('businessDrives'))
    ? props.children
    : error && !isOperationalError
      ? redirect(error)
      : props.children;
};

function generateErrorProps(status: number, history: any) {
  let message, action, actionLabel;
  switch (status) {
    case 301:
      message = "Oops, we're not there anymore.";
      actionLabel = 'Back to previous page';
      action = () => history.goBack();
      break;
    case 302:
      message = 'Hmm, something has gone wrong.';
      actionLabel = 'Back to previous page';
      action = () => history.goBack();
      break;
    case 401:
      message = "We couldn't find what you are looking for.";
      actionLabel = 'Unauthorized';
      action = () => {};
      break;
    case 404:
      message = "We couldn't find what you are looking for.";
      actionLabel = 'Back to Home';
      action = () => history.push('/');
      break;
    case 403:
      message = "We're sorry. But you do not have access to this page.";
      actionLabel = 'Back to Home';
      action = () => history.push('/');
      break;
    case 409:
      message = "We have a problem. We're sorry for the inconvienence.";
      actionLabel = 'Back to previous page';
      action = () => history.goBack();
      break;
    case 410:
      message = "Oops, we're not there yet.";
      actionLabel = 'Back to Home';
      action = () => history.push('/');
      break;
    case 500:
      message = 'There is an error in our end. Please bear with us.';
      actionLabel = 'Try again';
      action = () => window.location.reload();
      break;
    case 502:
      message =
        'The server encounter a temporary error and could not complete your request. Please try again later.';
      actionLabel = 'Try again';
      action = () => window.location.reload();
      break;
    case 503:
    default:
      message = 'We encountered an unexpected problem.';
      actionLabel = 'Try again';
      action = () => window.location.reload();
      break;
  }
  return { message, action, actionLabel };
}

export default ErrorHandler;
