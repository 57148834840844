import { styled } from '@mui/material';
import React from 'react';

interface Map {
  [key: string]: React.CSSProperties;
}

const mapRole: Map = {
  REKAVA: {
    backgroundColor: '#CDCED9',
    color: '#353C68'
  },
  ADMIN: {
    backgroundColor: '#FFF8CC',
    color: '#CCAF00'
  },
  PRINCIPAL: {
    backgroundColor: '#F4F2FD',
    color: '#8E7FEC'
  },
  COMPOSER: {
    backgroundColor: '#E3F3FF',
    color: '#118CFF'
  },
  LOGGER: {
    backgroundColor: '#EBFAF4',
    color: '#46CF91'
  },
  CHECKER: {
    backgroundColor: '#FEF1EC',
    color: '#F67650'
  },
  RECOMMENDED: {
    backgroundColor: '#FFDB00',
    color: '#29333D'
  },
  FREE: {
    backgroundColor: '#BAE2FF',
    color: '#118CFF;'
  },
  PRO: {
    backgroundColor: '#2CC077',
    color: '#FFFFFF'
  },
  'Coming Soon': {
    backgroundColor: '#8B98B1',
    color: 'white'
  }
} as const;

interface PropsI {
  role:
    | 'REKAVA'
    | 'ADMIN'
    | 'PRINCIPAL'
    | 'COMPOSER'
    | 'LOGGER'
    | 'CHECKER'
    | string;
  style?: React.CSSProperties;
  text?: string;
}

const DefaultPill = styled('div')<PropsI>(({ role }) => ({
  backgroundColor: mapRole[role as keyof typeof mapRole]?.backgroundColor,
  color: mapRole[role as keyof typeof mapRole]?.color,
  padding: '.25rem 1.2rem',
  borderRadius: 6,
  fontSize: 12,
  fontWeight: 700,
  lineHeight: 1.5,
  textAlign: 'center'
}));

export default function Pill(props: PropsI) {
  return <DefaultPill {...props}>{props.text ?? props.role}</DefaultPill>;
}
