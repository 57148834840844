import { Description, Item, OptionBox } from './styles';
import { Menu, useTheme } from '@mui/material';
import { MemberRolesI, RoleOptionI } from '../types';

import Text from '../../../../../../atoms/Text';
import images from '../../../../../../../assets';

interface MemberRoleOptionI {
  isShow: boolean;
  anchorPosition: HTMLElement | null;
  data: Array<MemberRolesI>;
  handlerModalSelect: (
    action: string,
    e?: React.SyntheticEvent,
    payload?: RoleOptionI
  ) => void;
  selectedRole: string | undefined;
  userId: string | undefined;
}

export const MemberRoleOption = ({
  isShow,
  anchorPosition,
  data,
  handlerModalSelect,
  selectedRole,
  userId
}: MemberRoleOptionI) => {
  const theme = useTheme();
  return (
    <Menu
      open={isShow}
      onClose={() => handlerModalSelect('CLOSE')}
      anchorEl={anchorPosition}
      MenuListProps={{
        disablePadding: true
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          boxShadow: '0px 0px 12px rgba(104, 109, 142, 0.24)',
          mt: 0.5
        }
      }}
    >
      <OptionBox>
        {data?.map((value: MemberRolesI, index: number) => (
          <Item
            key={`option_${index}`}
            onClick={(e: React.SyntheticEvent) =>
              handlerModalSelect('CHANGE_OPTION', e, {
                id: userId,
                memberRoleId: value.id
              })
            }
            data-testid={value}
          >
            <Description>
              <Text variant="body2" color={theme.customText.high}>
                {value.name}{' '}
              </Text>
              <Text variant="body3" color={theme.customText.low}>
                {value.description}
              </Text>
            </Description>
            {selectedRole === value.id && (
              <img src={images.thick} style={{ width: 16, height: 11 }} />
            )}
          </Item>
        ))}
      </OptionBox>
    </Menu>
  );
};
