import { InputProps } from '../../logger.types';
import { MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { useController } from 'react-hook-form';
import parser from 'html-react-parser';
import { DescriptionBoxField, InputBoxField, InputWrapper } from './styles';
import { useEffect } from 'react';
import Placeholder from '../../../../components/atoms/Placeholder';

function DropdownField({
  name,
  control,
  properties,
  defaultValue
}: InputProps) {
  // const [options, setOptions] = useState<any[]>([]);
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error }
  } = useController({
    control,
    name,
    rules: {
      validate: {
        required: value => {
          if (!value) return `${properties.description} is required`;
          return true;
        }
      }
    },
    defaultValue
  });

  const handleOptions = () => {
    // const tmpOptions = properties?.options?.map((item: string) => {
    //   return {
    //     value: item,
    //     label: item
    //   };
    // });
    // setOptions(tmpOptions);
  };

  useEffect(() => {
    handleOptions();
  }, [properties?.options]);

  return (
    <InputWrapper>
      <DescriptionBoxField contentEditable={false}>
        <Typography variant="body3">
          {properties.description
            ? parser(properties.description)
            : properties.description}
        </Typography>
      </DescriptionBoxField>

      <InputBoxField>
        <Tooltip title={properties.tooltip === null ? '' : properties.tooltip}>
          <Select
            onChange={onChange}
            onBlur={onBlur}
            value={value ?? ''}
            name={name}
            inputRef={ref}
            error={Boolean(error) || value === null || value === ''}
            displayEmpty
            renderValue={value =>
              value?.length ? (
                Array.isArray(value) ? (
                  value.join(', ')
                ) : value ? (
                  value
                ) : (
                  () => value
                )
              ) : (
                <Placeholder>Select option</Placeholder>
              )
            }
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #E0E6EB'
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #E0E6EB'
              },
              width: '100%',
              height: 35,
              fontSize: '12px',
              fontWeight: 400,
              backgroundColor: '#FFF'
            }}
          >
            {properties?.options?.map((option: any, idx: number) => (
              <MenuItem key={idx} value={option} dense sx={{ fontSize: 12 }}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Tooltip>
      </InputBoxField>
    </InputWrapper>
  );
}

export default DropdownField;
