import api from './api';

const URL_ACE = 'chatbot';
const URL_ACE_V2 = 'v2/chatbot';
const URL_ACE_HISTORIES = 'chat-histories';
const URL_ACE_HISTORY = 'chat-history';
const URL_CHAT_SESSION = 'chat-session';
const URL_CHAT_SESSIONS = 'chat-sessions';
const URL_ACE_RATING = 'rating/ratingtype';

export interface ChatHistoryPaylodI {
  question: string;
  answer: string;
  classification: string;
  companyId?: string;
  sessionId?: string;
}

export interface ChatSessionPayloadI {
  title: string;
  type: string;
}

export interface IAceVotePayload {
  id: string;
  voteType: string;
  request: {
    upvote: boolean;
    downvote: boolean;
    ratingtype: string[];
    description: string;
  };
}

export interface IUpdateBookPayload {
  id: string;
  title: string;
  author: string[];
  fileKey: string;
  companyId: string;
  fileName: string;
}

export default class AceServices {
  async get(
    chatHistory: Record<string, string>,
    question: string
  ): Promise<any> {
    try {
      const res = await api.post(`${URL_ACE}/ask?q=${question}`, {
        chatHistory: chatHistory
      });
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getSources(params?: any): Promise<any> {
    try {
      const res = await api.get(`${URL_ACE}/knowledge/sources`, params);
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async importBook(
    id: string,
    file: File,
    progress: (e: number) => void
  ): Promise<any> {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const res = await api.post(
        `${URL_ACE_V2}/knowledge/book?companyId=${id}&force=true`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: progressEvent => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            progress(percentCompleted);
          }
        }
      );
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async updateBook(body: IUpdateBookPayload): Promise<any> {
    try {
      const res = await api.put(
        `${URL_ACE}/knowledge/book/${body.id}`,
        body
      );
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async deleteBook(id: string): Promise<any> {
    try {
      const res = await api.delete(`${URL_ACE}/knowledge/book/${id}`);
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getChatHistories(): Promise<any> {
    try {
      const res = await api.get(`${URL_ACE_HISTORIES}`);
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getSessionChatHistories(
    page: number,
    size: number,
    sessionId: string
  ): Promise<any> {
    try {
      const res = await api.get(
        `${URL_ACE_HISTORIES}?page=${page}&size=${size}&sessionId=${sessionId}`
      );
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getRating(): Promise<any> {
    try {
      const res = await api.get(`${URL_ACE_RATING}`);
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async voteAnswer(payload: IAceVotePayload): Promise<any> {
    const { id, request } = payload;
    try {
      const res = await api.put(`${URL_ACE_HISTORY}/${id}/vote`, request);
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async createChatHistory(payload: ChatHistoryPaylodI): Promise<any> {
    try {
      const res = await api.post(`${URL_ACE_HISTORY}`, payload); //todo: Get excerpts integration
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async createChatSession(payload: ChatSessionPayloadI): Promise<any> {
    try {
      const res = await api.post(`${URL_CHAT_SESSION}`, payload);
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getChatSessions(type: string): Promise<any> {
    try {
      const res = await api.get(`${URL_CHAT_SESSIONS}?type=${type}`);
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async renameChatSession(id: string, title: string): Promise<any> {
    try {
      const res = await api.put(`${URL_CHAT_SESSION}/${id}`, { title });
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async deleteChatSession(id: string): Promise<any> {
    try {
      const res = await api.delete(`${URL_CHAT_SESSION}/${id}`);
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getCitationByHistory(id: string): Promise<any> {
    try {
      const res = await api.get(`${URL_ACE_HISTORY}/${id}`);
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
