import * as actions from '../types/reportTypes';

import { Dispatch } from 'redux';
import InputFileService from '../../service/InputFileService';
import Service from '../../service/generic/generic';
import dayjs from '../../utils/dayjs';
import { submitLoading } from '../appState/global/globalActions';
import { fetchSuccess } from './loggerAction';

const service = new Service('reports');
const printService = new Service('report');
const calculationService = new Service('calculation');

export const fetchReportList = (calculationId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.fetchReportByCalculationId());
    try {
      const response = await service.getDataWithParams({
        calculationId: calculationId,
        isActive: true
      });

      if (response.message === 'Success') {
        dispatch(actions.fetchReportByCalculationIdSuccess(response.data));
        return Promise.resolve(response.data);
      }
    } catch (error: any) {
      dispatch(actions.fetchReportByCalculationIdFailed(error));
    }
  };
};

export const fetchCalculationReportList = (calculationId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.fetchCalculationReportList());
    try {
      const response = await calculationService.get(`${calculationId}/reports`);
      if (response.message === 'Success') {
        dispatch(actions.fetchCalculationReportListSuccess(response.data));
      }
    } catch (error: any) {
      dispatch(actions.fetchCalculationReportListFailed(error));
    }
  };
};

export const createNewReport = (inputFIleId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.createReport());
    try {
      const response = await printService.post({ inputFileId: inputFIleId });

      if (response.message === 'Success') {
        dispatch(actions.createReportSuccess(response.data));
      }
    } catch (error: any) {
      dispatch(actions.createReportFailed(error));
      console.error(error?.response?.data);
      return Promise.reject(error);
    }
  };
};

export function loadReport(reportId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(actions.loadReportLoading());
    try {
      const response = await printService.get(reportId);
      dispatch(actions.loadReportSuccess(response.data));
      dispatch(fetchSuccess(response.data.inputFile));
      return Promise.resolve(response.data);
    } catch (error: any) {
      console.error(error?.response?.data);
      dispatch(actions.loadReportFailed(error.response.data));
      return Promise.reject(error);
    }
  };
}

export function renameReport(reportId: string, payload: any) {
  return async (dispatch: Dispatch) => {
    dispatch(submitLoading(true));
    try {
      const dateNow: string = dayjs().utc(false).format('YYYY-MM-DD HH:mm:ss');
      await printService.putResource(reportId, 'title', payload);

      dispatch(actions.reportTitle(payload?.title));
      dispatch(actions.reportUpdatedAt(dateNow));
      setTimeout(() => {
        dispatch(submitLoading(false));
      }, 500);
    } catch (error: any) {
      dispatch(submitLoading(false));
      return Promise.reject(error);
    }
  };
}

export function getPages(inputFileId: string) {
  return async (dispatch: Dispatch) => {
    const inputFile = await InputFileService.getByIdNew(inputFileId);
    dispatch(
      actions.fetchPages(
        inputFile.pages,
        inputFile.primaryLogo,
        inputFile.secondaryLogo
      )
    );
  };
}

export function summaryReport(reportId: string) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await printService.getSubresource(reportId, 'summary');
      dispatch(actions.fetchSummaryReport(response.data));
      return Promise.resolve(response.data);
    } catch (error: any) {
      dispatch(actions.loadReportFailed(error.response.data));
      return Promise.reject(error);
    }
  };
}

export function deleteReport(reportId: string) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await printService.deleteResource(reportId, '');
      return Promise.resolve(response);
    } catch (error: any) {
      dispatch(submitLoading(false));
      return Promise.reject(error);
    }
  };
}

export async function putReportAfterDownload(
  reportId: string,
  resource: string
) {
  try {
    const response = await printService.putReportAfterDownload(
      reportId,
      resource
    );

    return response.data;
  } catch (error: any) {
    console.error(error, 'Error from report action');
    return Promise.reject(error);
  }
}
export function saveReport(reportId: string, payload: any, file?: any) {
  console.warn(file);
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.loadingReport(true));
      // const formData = new FormData();
      // formData.append("file", file, file.name);
      const response = await printService.putResource(reportId, '', payload);
      // await printService.putResource(reportId, "file", formData);
      dispatch(actions.loadingReport(false));
      return Promise.resolve(response);
    } catch (error: any) {
      dispatch(submitLoading(false));
      return Promise.reject(error);
    }
  };
}
