import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress';

import { styled as MuiStyled } from '@mui/material';
import NumberFormat from 'react-number-format';
import images from '../assets';
import styled from 'styled-components';

interface Props {
  imageLeft?: string;
  imageRight?: string;
  justify?: string;
  backgroundColorBar?: string;
  valueBar?: string;
  latex?: boolean;
  expandFormula?: boolean;
  twoLines?: boolean;
  error?: boolean;
  disabled?: boolean;
  inputValueType?: string;
  visible?: boolean;
}

interface LatexPropsI {
  hideLatex?: boolean;
}

export const Container = styled.div({
  padding: '24px 0',
  display: 'flex',
  backgroundColor: '#F0F0F1',
  alignItems: 'flex-start',
  gap: '10px',
  justifyContent: 'center',
  position: 'absolute',
  width: '100%'
});

export const MainContent = styled.div({
  flexGrow: 1,
  display: 'flex'
});

export const FlexWrapper = styled.div({
  display: 'flex'
});

export const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '684px',
  paddingLeft: '286px'
});

export const HeaderMainContent = styled.div({
  padding: '0 1.5rem',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  boxShadow: 'none',
  marginBottom: '12px',

  '& > div': {
    display: 'flex',
    gap: '0.5rem',
    width: '100%',
    overflow: 'hidden'
  },
  '& > .arrowIcon': {
    position: 'absolute',
    cursor: 'pointer',
    ':active': {
      transform: 'scale(0.8)'
    }
  },
  '& > .left': {
    left: 0
  },
  '& > .right': {
    right: 0
  },
  '& > div > button': {
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: 700,
    minWidth: '105px',
    letterSpacing: '0.4px',
    backgroundColor: '#FFFFFF',
    fontFamily: 'Inter',
    height: '32px',
    padding: '8px'
  }
});

export const BodyMainContent = styled.div({
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '854px',
  overflow: 'hidden'
});

export const HeadAtBodyMainContent = styled.div({
  borderBottom: '1px solid #E0E6EB',
  height: '108px'
});

export const HeadAtBodyMainContentWrapper = styled.div({
  padding: '16px',
  display: 'flex',
  flexDirection: 'row'
});

export const HeadAtBodyMainContentLeft = styled.div<Props>(props => ({
  gap: '8px',
  display: 'flex',
  flexDirection: 'row',
  marginRight: '16px',
  paddingTop: '6px',
  paddingBottom: '6px',
  '& > #divImgLeft': {
    width: '64px',
    height: '64px',
    backgroundImage: `url("${props.imageLeft}")`,
    cursor: 'pointer',
    borderRadius: '100%'
  },
  '& > #divImgRight': {
    width: '64px',
    height: '64px',
    backgroundImage: `url("${props.imageRight}")`,
    cursor: 'pointer',
    borderRadius: '100%'
  }
}));

export const HeadAtBodyMainContentCenter = styled.div({
  width: '420px',
  '& > #topMainContentCenter': {
    fontFamily: 'Inter',
    fontSize: '18px',
    letterSpacing: '0.44px',
    marginBottom: '12px'
  },
  '& > #botMainContentCenter': {
    color: '#9A9DB3',
    fontFamily: 'Inter',
    fontSize: '10px',
    letterSpacing: '0.4px',
    '& > #botMainContentCenterWrapperTop': {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '8px',
      '& > div': {
        width: '50%',
        display: 'flex',
        flexDirection: 'row',
        '& > .titleHeadBody': {
          width: '70px'
        },
        '& > .dotHeadBody': {
          marginLeft: '4px',
          marginRight: '4px'
        }
      }
    },
    '& > #botMainContentCenterWrapperBot': {
      display: 'flex',
      flexDirection: 'row',
      '& > div': {
        width: '50%',
        display: 'flex',
        flexDirection: 'row',
        '& > .titleHeadBody': {
          width: '70px'
        },
        '& > .dotHeadBody': {
          marginLeft: '4px',
          marginRight: '4px'
        }
      }
    }
  }
});

export const HeadAtBodyMainContentRight = styled.div({
  // empty div :)
});

export const SideBar = styled.div({
  width: '236px',
  backgroundColor: '#FFFFFF',
  padding: '24px',
  maxheight: '289px',
  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.24), 0px 0px 16px rgba(0, 0, 0, 0)',
  borderTopRightRadius: '4px',
  marginBottom: '8px',
  '& > p': {
    color: '#9A9DB3',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '1.5px'
  },
  '& > h1': {
    margin: '4px 0',
    fontSize: '15px',
    letterSpacing: '0.75px',
    lineHeight: '16.95px',
    fontWeight: 700
  },
  '& > h2': {
    color: '#9A9DB3',
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '1.5px',
    paddingBottom: '24px'
  },
  '& > h4': {
    paddingBottom: '24px',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '21px',
    overflowWrap: 'anywhere'
  }
});

export const ButtonSideBar = styled.button({
  border: 'none',
  backgroundColor: '#F0F2F5',
  padding: '8.5px 8px',
  textAlign: 'center',
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '14px',
  fontWeight: 700,
  width: '188px',
  margin: '24px',
  lineHeight: '14.52px',
  borderRadius: 5
});

export const ButtonSideBar2 = styled.div({
  border: 'none',
  color: 'gray',
  backgroundColor: '#F0F2F5',
  padding: '8.5px 8px',
  textAlign: 'center',
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '14px',
  fontWeight: 700,
  width: '188px',
  lineHeight: '14.52px',
  margin: '24px 0',
  borderRadius: 5
});

export const SideBar2 = styled.div({
  width: '236px',
  backgroundColor: '#FFFFFF',
  padding: '24px',
  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.24), 0px 0px 16px rgba(0, 0, 0, 0)',
  borderTopRightRadius: '4px',
  '& > p': {
    color: '#9A9DB3',
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    paddingBottom: '24px'
  },
  '& > h1': {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '17px',
    paddingTop: '24px'
  },
  '& > h2': {
    color: '#118CFF',
    fontWeight: 700,
    height: '17px',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    margin: '8px 0'
  }
});

export const BorderLinearProgress = styled(LinearProgress)(() => ({
  borderRadius: 4,
  padding: '2px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#E3F3FF'
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: '#1a90ff'
  }
}));

export const BodyAtBodyMainContent = styled.div({
  display: 'flex',
  flex: 1
});

export const EmptyBody = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  pointerEvents: 'none',
  userSelect: 'none',
  flex: 1
});

export const BodyAtBodyMainContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '11px',
  border: '1px dashed white',
  borderRadius: '4px',
  flex: 1,
  margin: '0px 44px'
});

export const formText = styled.div({
  maxWidth: '500px',
  margin: '0 auto'
});

export const LabelLogger = styled.label({
  padding: '5px',
  margin: '10px',
  maxWidth: '296px'
});

export const formLogger = styled.form({
  padding: '5px',
  margin: '10px',
  width: '160px'
});

export const MainLoggerDiv = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  // marginTop: "5px",
  padding: '4px 0'
});

export const MainLatexDiv = styled.div<LatexPropsI>(props => ({
  paddingBottom: props.hideLatex ? 0 : 12
}));

export const RightMainTextLogger = styled.div({
  width: '340px',
  minHeight: '18px',

  '& > span': {
    overflowWrap: 'anywhere'
  }
});

export const LeftMainTextLogger = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& > p': {
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '16px'
  }
});

export const InputFieldNumeric = MuiStyled(NumberFormat)<Props>(props => ({
  padding: '4px 8px',
  margin: '0',
  width: '160px',
  fontSize: '12px',
  gap: '4px',
  border: props.error ? '1px solid red' : '1px solid #e0e6eb ',
  height: '28px',
  borderRadius: '6px',
  textAlign: 'right',
  '&.danger': {
    border: '1px solid red !important'
  },
  '&.warning': {
    border: '1px solid orange !important'
  }
}));

export const InputLeftMainTextLogger = styled.input<Props>(props => ({
  padding: '4px 8px',
  margin: '0',
  width: '160px',
  fontSize: '12px',
  gap: '4px',
  border: '1px solid #e0e6eb',
  height: '28px',
  borderRadius: '6px',
  textAlign: props.inputValueType === 'TEXT' ? 'left' : 'right',
  '&.danger': {
    border: '1px solid red !important'
  },
  '&.warning': {
    border: '1px solid orange !important'
  }
}));

export const DropdownInput = styled.select<Props>(() => ({
  boxSizing: 'content-box',
  padding: '5px 8px',
  margin: '0',
  width: '152px',
  fontSize: '12px',
  gap: '4px',
  outline: '1px solid #e0e6eb',
  border: '1px solid transparent',
  borderRightWidth: '8px',
  height: '28px',
  borderRadius: '6px',
  textAlign: 'left',
  '&.danger': {
    border: '1px solid red !important'
  },
  '&.warning': {
    border: '1px solid orange !important'
  }
}));

export const DateInput = styled.input({
  padding: '4px 8px',
  margin: '0',
  width: '160px',
  fontSize: '12px',
  gap: '4px',
  border: '1px solid #e0e6eb',
  height: '28px',
  borderRadius: '6px',
  fontFamily: "'Inter', sans-serif",
  '&::-webkit-calendar-picker-indicator': {
    backgroundImage: `url(${images.iconCalendar})`
  }
});

export const RadioButtonLogger = styled.div({
  // paddingTop: "5px",
  // marginTop: "10px",
  display: 'flex',
  flexDirection: 'column',
  fontSize: '12px',
  fontWeight: 'normal'
});

export const OutRadioButton = styled.div({
  // paddingTop: "10px",
  padding: '4px 0',
  marginTop: '10px',
  '& > #radioDescription': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > #radioNotation': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      '& > #notation': {
        fontSize: '12px',
        margin: '0px 0px 0px 19px',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      },
      '& > #blankInput': {
        width: '319px',
        margin: '0px 0px 0px 8px'
      }
    }
  }
});

export const SidebarWrapper = styled.div({
  display: 'flex'
});

export const StickyWrapper = styled.div({
  flexDirection: 'column',
  position: 'fixed'
});

export const TextLabel = styled.label({
  fontSize: '12px',
  fontWeight: 'normal',
  lineHeight: '16px'
});

export const ContainerFormStyled = styled.form({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 'calc(100vh - 72px)',
  flex: 1,
  select: {
    flex: 1
  },
  input: {
    backgroundColor: '#FFFFFF',
    flex: '1',
    display: 'flex'
  }
});

export const ImageDiv = styled.div<Props>(props => ({
  display: 'flex',
  padding: '.5rem 0',
  justifyContent:
    props.justify === 'CENTER'
      ? 'center'
      : props.justify === 'RIGHT'
        ? 'flex-end'
        : props.justify === 'LEFT' || 'JUSTIFY'
          ? 'flex-start'
          : 'center'
}));

export const BarProgress = styled.div({
  display: 'flex'
});

export const PerBarProgress = styled.div<Props>(props => ({
  backgroundColor: props.backgroundColorBar,
  width: props.valueBar + '%',
  float: 'left',
  height: '10px',
  '&:first-child': {
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px'
  },
  '&:last-child': {
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px'
  }
}));
export const Notation = styled.p({
  width: 64,
  paddingLeft: 12,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  height: '18px'
});

export const Unit = styled.p({
  width: 64,
  height: '20px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  padding: '0px 0px 0px 4px'
});

export const SidePanelButtonContainer = styled.div({
  padding: '.825rem 0 0'
});

export const ImageDesc = styled.div<Props>(props => ({
  display: 'flex',
  justifyContent: props.justify || 'center',
  alignItems: 'center',
  fontSize: '12px',
  lineHeight: '16px'
}));

export const DividerComp = styled.div({
  padding: '16px 0'
});

export const LatexImageComponent = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  width: '100%',
  transform: 'translateY(-200%)',
  textAlign: 'center'
});

//////// INPUT SELECT

export const InputSelect = styled.div<Props>(props => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: '10px',
  paddingBottom: '10px',
  paddingRight: '12px',
  paddingLeft: '12px',
  border: props.error ? '1px solid #DE294C' : '1px solid #E0E6EB',
  borderRadius: '4px',
  cursor: 'pointer',
  backgroundColor: props.disabled ? '#F0F2F5' : '#ffffff',
  marginBottom: '24px',
  marginTop: '4px',
  '& > .defaultValueSelect': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#B3BFCC'
  },
  '& > .valueSelect': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px'
  }
}));

export const OptionBox = styled.div({
  width: '232px',
  paddingTop: '4px',
  backgroundColor: '#FFFFFF',
  borderRadius: '4px',
  maxHeight: '256px',
  overflowY: 'auto',
  '& > .optionValue': {
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingRight: '16px',
    paddingLeft: '16px',
    borderRadius: '4px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E3F3FF'
    }
  },
  '& > .createNewInputFile': {
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingRight: '16px',
    paddingLeft: '16px',
    borderRadius: '4px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#118CFF',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E3F3FF'
    }
  }
});

export const ReferenceWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  columnGap: '12px',
  marginTop: '4px',
  // padding : "80px 0",

  '& > .reference': {
    width: '58px'
  },
  '& > .referenceValue': {
    width: '304'
  }
});
