import { useState } from 'react';
import { IManageUpgradePlans } from './styles';
import Text from '../../../../../atoms/Text';
import { handleDisabled } from '../../../../../../utils/helpersFunction';
import Pill from '../../../../../atoms/Pill';
import SubscriptionBody from '../../../../../../pages/subscriptionPage/SubscriptionBody';
import { ReduxState } from '../../../../../../store';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { errorSubscription } from '../../../../../../utils/genericHandler';
import { formModalSetupActionPopUp } from '../../../../../../store/appState/popUp/formModal/formModalAction';
import images from '../../../../../../assets';
import {
  BUSINESS,
  ENTERPRISE,
  FREE,
  PRO
} from '../../../../../../utils/constant';
import { toastSetupActionPopUp } from '../../../../../../store/appState/popUp/toast/toastAction';
import {
  isAfterDate,
  isoToLocalDateTimeStrip
} from '../../../../../../utils/dateConvert';
import dayjs from '../../../../../../utils/dayjs';

export function UpgradePlansPopUp({
  plan,
  // onClose,
  contentHead
}: IManageUpgradePlans) {
  const dispatch = useDispatch();
  const { userProfile } = useSelector(
    (state: ReduxState) => state.users,
    shallowEqual
  );

  const [hoverEl, setHoverEl] = useState<string>('');

  const { subscription } = userProfile;

  const handleUpgradeAccount = (upgradeAcc: string) => {
    const dateNow = isoToLocalDateTimeStrip(new Date());

    try {
      if (upgradeAcc == PRO) {
        if (isAfterDate(dateNow, subscription?.pro_TRIAL_EXPIRED_TIME)) {
          errorSubscription(new Error('Not supported yet'));
        } else {
          dispatch(
            formModalSetupActionPopUp('UPGRADE_PLAN_SUBSCRIPTION', {
              image: images.freeSubscription,
              text: {
                title: 'FREE',
                subtitle: dayjs(subscription?.pro_TRIAL_EXPIRED_TIME).format(
                  'D MMMM YYYY'
                )
              }
            })
          );
        }
      } else if (upgradeAcc !== FREE) {
        errorSubscription(new Error('Not supported yet'));
      } else {
        dispatch(toastSetupActionPopUp('ERROR', "Can't downgrade for now."));
      }
    } catch (error: any) {
      errorSubscription(error);
    }
  };

  const subscriptionAction = [
    {
      type: 'For individuals',
      name: 'Basic',
      text: 'Utilizing Rekava core features',
      title: [
        {
          title: '0',
          textDecoration: false,
          variant: 'h1',
          secondTitle: 'IDR per user month'
        }
      ],
      disabled: handleDisabled(plan, FREE),
      currentPlan: FREE,
      subText: 'What’s included',
      included: ['Calculation templates', '3 calculation'],
      colorBorder: '#D6DFEB',
      buttonText: plan === FREE ? 'Current Plan' : 'Select Plan'
    },
    {
      type: 'For individuals',
      name: 'Pro',
      pill: (
        <Pill
          text="Recommended"
          role="RECOMMENDED"
          style={{ height: 20, padding: '7px 9px', borderRadius: 10 }}
        />
      ),
      text: 'Get unlimited access to Rekava Pro tools',
      title: [
        {
          title: '149,000',
          variant: 'h1',
          secondTitle: 'IDR per user per month'
        }
      ],
      disabled: handleDisabled(plan, PRO),
      currentPlan: PRO,

      subText: 'Everything in Basic, plus',
      included: ['Table component', 'Advance component'],
      colorBorder: '#D6DFEB',
      buttonText: plan === PRO ? 'Current Plan' : 'Upgrade for Free'
    },
    {
      type: 'For business',
      name: 'Business',
      text: 'Collaborate with your entier team in one place',
      title: [
        {
          title: '349,000',
          textDecoration: false,
          variant: 'h1',
          secondTitle: 'IDR per user per month'
        }
      ],
      disabled: handleDisabled(plan, BUSINESS),
      currentPlan: BUSINESS,
      subText: 'Everything in Pro, plus',
      included: ['Collaborative workspace', 'Sharing permissions'],
      colorBorder: '#D6DFEB',
      buttonText: 'Select Plan'
    },
    {
      type: 'For teams',
      name: 'Enterprise',
      text: 'Limits and assistance in running business',
      title: [
        {
          title: 'Contact Us',
          textDecoration: false,
          variant: 'h1'
        }
      ],
      disabled: handleDisabled(plan, ENTERPRISE),
      currentPlan: ENTERPRISE,
      subText: 'What’s included',
      included: ['subscriptionAction', 'Dedicated support', 'Custom features'],
      colorBorder: '#D6DFEB',
      buttonText: 'Contact Us'
    }
  ];

  return (
    <>
      <Text variant="h1" style={{ paddingBottom: 24 }} align="center">
        {contentHead}
      </Text>
      <div style={{ display: 'flex', gap: '1rem' }}>
        {subscriptionAction.map((item: any, index: number) => (
          <SubscriptionBody
            key={`subscription_body_${index}`}
            item={item}
            subscription={subscription}
            handleUpgradeAccount={handleUpgradeAccount}
            hoverEl={hoverEl}
            setHoverEl={setHoverEl}
          />
        ))}
      </div>
    </>
  );
}
