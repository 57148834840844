import {
  ButtonWrapper,
  Container,
  Header,
  InputWrapper,
  MemberWrapper
} from './styles';
import {
  ManageMembersPropsI,
  MemberPropsI,
  MemberRolesI,
  ModalPropsI,
  RoleChangesI,
  RoleOptionI
} from './types';
import React, { ChangeEvent, useEffect, useState } from 'react';

import DriveService from '../../../../../../service/DriveService';
import Input from '../../../../../atoms/Input';
import { LoadingSpinner } from '../../../../../atoms/loading';
import { MemberCard } from './MemberCard';
import { MemberRoleOption } from './MemberRoleOption';

import { Spinner } from '../../../../../atoms/loading/elements';
import StyledButton from '../../../../../atoms/Button';
import Text from '../../../../../atoms/Text';
import { toastSetupActionPopUp } from '../../../../../../store/appState/popUp/toast/toastAction';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material';
import { OWNER } from '../../../../../../pages/businessDrives/businessDrives.types';
import { getBusinessDriveRole } from '../../../../../../utils/storage';
import { getUserCalculationLimit } from '../../../../../../store/actions/userAction';
import { useLocation } from 'react-router-dom';

export const ManageMembers = ({ folderId, onClose }: ManageMembersPropsI) => {
  const location: Record<string, any> = useLocation();
  const [email, setEmail] = useState<string>('');
  const [member, setMember] = useState<Array<MemberPropsI>>([]);
  const [memberRoles, setMemberRoles] = useState<Array<MemberRolesI>>([]);
  const [isLoadingGetMember, setLoadingGetMember] = useState<boolean>(false);
  const [isLoadingInviteMember, setLoadingInviteMember] =
    useState<boolean>(false);
  const [isLoadingChangeRoles, setLoadingChangeRoles] =
    useState<boolean>(false);
  const isOwner = getBusinessDriveRole() === OWNER;

  const handleOnChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const dispatch = useDispatch();
  const theme = useTheme();
  const addMember = async () => {
    setLoadingInviteMember(true);
    try {
      const response: Array<MemberPropsI> = await DriveService.addMember({
        folderId: folderId,
        emails: email
          .split(',')
          .map((item: string) => item.replaceAll(/\s/g, ''))
      });
      setEmail('');
      setMember(response);
      dispatch(getUserCalculationLimit());
      setLoadingInviteMember(false);
      return dispatch(toastSetupActionPopUp('INFO', 'Invitation sent'));
    } catch (error: any) {
      setLoadingInviteMember(false);
      return errorHandler(
        error.response.data?.message,
        error.response.data?.status
      );
    }
  };

  const getMembers = async () => {
    setLoadingGetMember(true);
    try {
      Promise.all([
        DriveService.getMember(location.state.driveId),
        DriveService.getMemberRoles()
      ])
        .then(response => {
          const members: Array<MemberPropsI> = response[0];
          const memberRoles: Array<MemberRolesI> = response[1];
          setMember(members);
          setMemberRoles(memberRoles);
        })
        .finally(() => setLoadingGetMember(false));
    } catch (error: any) {
      setLoadingGetMember(false);
      return errorHandler(
        error.response.data?.message,
        error.response.data?.status
      );
    }
  };

  useEffect(() => {
    getMembers();
  }, []);

  const addRoleName = (
    roleId: string | undefined,
    memberRoles: Array<MemberRolesI>
  ) => {
    const data = memberRoles.find((item: MemberRolesI) => item.id === roleId);
    return data?.name;
  };

  const [openModalSelect, setOpenModalSelect] = useState<ModalPropsI>({
    isOpen: false,
    anchorPosition: null,
    payload: {
      id: '',
      memberRoleId: ''
    }
  });

  const handlerModalSelect = (
    typeAction: any,
    event?: React.SyntheticEvent,
    payload?: RoleOptionI
  ) => {
    if (typeAction === 'OPEN') {
      setOpenModalSelect({
        isOpen: true,
        anchorPosition: event?.currentTarget,
        payload: {
          id: payload?.id,
          memberRoleId: payload?.memberRoleId
        }
      });
    } else if (typeAction === 'CLOSE') {
      setOpenModalSelect({
        isOpen: false,
        anchorPosition: null,
        payload: {
          id: '',
          memberRoleId: ''
        }
      });
    } else {
      setOpenModalSelect({
        isOpen: false,
        anchorPosition: null,
        payload: {
          id: payload?.id,
          memberRoleId: payload?.memberRoleId
        }
      });
      payload !== undefined && updateMemberRoleUser(payload);
    }
  };

  const updateMemberRoleUser = (payload: RoleOptionI) => {
    const newMemberRole: Array<MemberPropsI> = member.map(
      (item: MemberPropsI) => {
        return {
          ...item,
          memberRoleId:
            item.id === payload.id ? payload.memberRoleId : item.memberRoleId
        };
      }
    );
    setMember(newMemberRole);
  };

  const onChangeMemberRoles = async () => {
    setLoadingChangeRoles(true);
    try {
      const data: Array<RoleChangesI> = [];
      member.forEach((item: MemberPropsI) => {
        data.push({
          folderMemberId: item.id,
          memberRoleId: item.memberRoleId
        });
      });
      await DriveService.changeMemberRole({
        changes: data
      });
      setLoadingChangeRoles(false);
      onClose();
    } catch (error: any) {
      setLoadingChangeRoles(false);
      return errorHandler(
        error.response.data?.message,
        error.response.data?.status
      );
    }
  };

  const errorHandler = (message: string, status: number) => {
    switch (status) {
      case 422:
        switch (message) {
          case 'Unable to change OWNER member role':
            dispatch(
              toastSetupActionPopUp(
                'ERROR_SECONDARY',
                'You’re not allowed to change the permission'
              )
            );
            break;
          case 'User has been invited':
            dispatch(toastSetupActionPopUp('INFO', message));
            break;
          case 'FREE user is not allowed to have OWNER member role':
          case 'PRO user is not allowed to have OWNER member role':
          case 'BUSINESS user is not allowed to have OWNER member role':
            dispatch(
              toastSetupActionPopUp(
                'ERROR_SECONDARY',
                'This user have no access to manage drive'
              )
            );
            break;
          default:
            dispatch(toastSetupActionPopUp('ERROR_SECONDARY', message));
            break;
        }
        break;
      default:
        dispatch(toastSetupActionPopUp('ERROR_SECONDARY', message));
        break;
    }
  };

  return (
    <Container>
      {isLoadingGetMember && <LoadingSpinner />}
      <Header>
        <Text variant="h1" align={'center'}>
          {isOwner ? 'Manage Members' : 'View Members'}
        </Text>
      </Header>
      {isOwner && (
        <>
          <Text variant="body3" color={theme.customText.low}>
            Invite team member
          </Text>

          <InputWrapper>
            <Input
              style={{ width: 330, marginRight: 12 }}
              placeholder="Email, comma separated"
              onChange={handleOnChange}
              value={email}
            />
            <StyledButton
              key={'manageMembers'}
              type="button"
              variant="outlined"
              sx={{ width: 72 }}
              data-testid={'emailName'}
              onClick={addMember}
            >
              {isLoadingInviteMember ? <Spinner /> : 'Send'}
            </StyledButton>
          </InputWrapper>
        </>
      )}

      <MemberWrapper>
        {Array.isArray(member) &&
          member.map((item: MemberPropsI, idx: number) => (
            <MemberCard
              isOwner={isOwner}
              key={`member_${idx}`}
              data={item}
              isDivider={member.length !== idx + 1}
              roleName={addRoleName(item.memberRoleId, memberRoles)}
              onSelect={handlerModalSelect}
            />
          ))}
      </MemberWrapper>

      <ButtonWrapper isOwner={isOwner}>
        {isOwner && (
          <StyledButton
            key={0}
            type="button"
            variant="outlined"
            data-testid={'submit'}
            onClick={onClose}
            sx={{ width: 194 }}
          >
            Cancel
          </StyledButton>
        )}
        <StyledButton
          key={0}
          type="button"
          variant="contained"
          data-testid={'submit'}
          onClick={isOwner ? onChangeMemberRoles : onClose}
          sx={{ width: 194 }}
        >
          {isLoadingChangeRoles ? <Spinner /> : 'Done'}
        </StyledButton>
      </ButtonWrapper>
      <MemberRoleOption
        isShow={openModalSelect.isOpen}
        anchorPosition={openModalSelect.anchorPosition}
        data={memberRoles}
        handlerModalSelect={handlerModalSelect}
        selectedRole={openModalSelect.payload.memberRoleId}
        userId={openModalSelect.payload.id}
      />
    </Container>
  );
};
