import {
  Container,
  Divider,
  InfoWrapper,
  MemberInfo,
  RolePills,
  Subscription
} from './styles';
import { MemberPropsI, RoleOptionI } from '../types';

import Icon from '../../../../../../atoms/Icon';
import React from 'react';
import Text from '../../../../../../atoms/Text';
import images from '../../../../../../../assets';

interface MemberInterface {
  data: MemberPropsI;
  isDivider: boolean;
  roleName: string | undefined;
  onSelect: (
    action: string,
    e: React.SyntheticEvent,
    payload: RoleOptionI
  ) => void;
  isOwner: boolean;
}

export const MemberCard = ({
  data,
  isDivider,
  roleName,
  onSelect,
  isOwner
}: MemberInterface) => {
  return (
    <>
      <Container>
        <InfoWrapper>
          <img
            src={data.profilePictureUrl ?? images.avatar}
            style={{
              width: 24,
              height: 24,
              marginRight: 4,
              objectFit: 'scale-down',
              borderRadius: 50
            }}
          />
          <MemberInfo>
            <Text variant="h5">{data.name}</Text>
            <Text variant="body3">{data.email}</Text>
            <Subscription type={data.subscriptionPlan}>
              {data.subscriptionPlan === 'Free'
                ? 'Basic'
                : data.subscriptionPlan}
            </Subscription>
          </MemberInfo>
        </InfoWrapper>

        <RolePills
          disabled={!isOwner}
          onClick={(e: React.SyntheticEvent) =>
            isOwner
              ? onSelect('OPEN', e, {
                  id: data.id,
                  memberRoleId: data.memberRoleId
                })
              : undefined
          }
        >
          <Text variant="h6">{roleName}</Text>
          {isOwner && <Icon icon="arrowDown" color="currentColor" />}
        </RolePills>
      </Container>
      {isDivider && <Divider />}
    </>
  );
};
