import {
  DividerMainMenu,
  ExtendMenu,
  MainMenu,
  NavMenu,
  NavMenuList,
  NestedMenu,
  NormalMainMenu,
  RadioMainMenu
} from './elements';
import {
  // checkSubscriptionAndDate,
  getCalculationIdFromComposer,
  getFolderIdFromComposer
} from '../../../../utils/helpersFunction';
import {
  getCommunityWorkspaceId,
  getEnterpriseWorkspaceId
} from '../../../../utils/storage';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import CuppertinoSwitch from '../../../atoms/cupertinoSwitch/cupertinoSwitch';
// IMPORT PACKAGES
import { Menu } from '@mui/material';
import { ReduxState } from '../../../../store';
import { composerMainMenuList } from '../../../../utils/constant';
import { formModalSetupActionPopUp } from '../../../../store/appState/popUp/formModal/formModalAction';
// IMPORT MISC
import images from '../../../../assets';
import { inputSwitchActionMenuComposer } from '../../../../store/composer/composerMenu/composerMenuActions';
import { useState } from 'react';
// import { getUserCalculationLimit } from "../../../../store/actions/userAction";
import { useLocation } from 'react-router-dom';

export const ComposerMenu = () => {
  // REACT REDUX
  const location: Record<string, any> = useLocation();
  const isDriveCalculation: boolean =
    location.state?.calculationType === 'BUSINESS';
  const dispatch = useDispatch();
  const { dataSwitch } = useSelector(
    (state: ReduxState) => state.composerMenu,
    shallowEqual
  );

  // const { userLimit, isLoading } = useSelector(
  //   (state: ReduxState) => state.users,
  //   shallowEqual
  // );

  // const {subscription} = useSelector(
  //   (state: ReduxState) => state.login
  // );

  const [mainMenu, setMainMenu] = useState<any>({
    open: false,
    anchorPosition: null,
    index: null,
    content: null
  });

  // MAIN MENU FUNCTION

  const handlerMainMenu = (
    typeAction: any,
    value?: any,
    index?: any,
    event?: any
  ) => {
    switch (typeAction) {
      case 'OPEN':
        setMainMenu({
          open: true,
          anchorPosition: event.currentTarget,
          index: index,
          content: value.content
        });
        break;
      case 'CLOSE':
        setMainMenu({
          open: false,
          anchorPosition: null,
          index: null,
          content: null
        });
        break;
      case 'CLICK_NORMAL':
        switch (value.name) {
          case 'newCalculation':
            dispatch(
              formModalSetupActionPopUp('NEW_CALCULATION_FROM_COMPOSER', {
                folderIdCalculation: getFolderIdFromComposer(
                  window.location.href
                ),
                loggerPreview: true
              })
            );
            setMainMenu({
              open: false,
              anchorPosition: null,
              index: null,
              content: null
            });

            break;
          case 'save':
            // TO DO MANUAL SAVE HERE IF THE AUTOSAVE ALREADY COMPLETED
            setMainMenu({
              open: false,
              anchorPosition: null,
              index: null,
              content: null
            });
            break;
          case 'saveas':
            dispatch(
              formModalSetupActionPopUp('SAVE_AS_FROM_COMPOSER', {
                idCalculation: getCalculationIdFromComposer(
                  window.location.href
                ),
                folderIdCalculation: getFolderIdFromComposer(
                  window.location.href
                )
              })
            );
            setMainMenu({
              open: false,
              anchorPosition: null,
              index: null,
              content: null
            });

            break;
          case 'publishEnterprise':
            dispatch(
              formModalSetupActionPopUp(
                'PUBLISH_TO_ENTERPRISE_CALCULATION_FROM_COMPOSER',
                {
                  idCalculation: getCalculationIdFromComposer(
                    window.location.href
                  ),
                  location: getEnterpriseWorkspaceId()
                }
              )
            );
            setNestedMenu({
              open: false,
              anchorPosition: null,
              index: null,
              content: null
            });
            setTimeout(() => {
              setMainMenu({
                open: false,
                anchorPosition: null,
                index: null,
                content: null
              });
            }, 25);
            break;
          case 'publishCommunity':
            dispatch(
              formModalSetupActionPopUp(
                'PUBLISH_TO_COMMUNITY_CALCULATION_FROM_COMPOSER',
                {
                  idCalculation: getCalculationIdFromComposer(
                    window.location.href
                  ),
                  location: getCommunityWorkspaceId()
                }
              )
            );
            setNestedMenu({
              open: false,
              anchorPosition: null,
              index: null,
              content: null
            });
            setTimeout(() => {
              setMainMenu({
                open: false,
                anchorPosition: null,
                index: null,
                content: null
              });
            }, 25);
            break;
          case 'exitCalculation':
            dispatch(
              formModalSetupActionPopUp('EXIT_CALCULATION_FROM_COMPOSER', {
                isDriveCalculation,
                folderId: location.pathname.split('/').reverse()[0]
              })
            );
            setMainMenu({
              open: false,
              anchorPosition: null,
              index: null,
              content: null
            });
            break;
          default:
            console.error('Not Implemented Yet');
            break;
        }
        break;
      default:
        console.error(`Wrong typeAction clickMenu`);
        break;
    }
  };

  const mainMenuRender = () => {
    return (
      <Menu
        open={mainMenu.open}
        onClose={() => handlerMainMenu('CLOSE')}
        anchorEl={mainMenu.anchorPosition}
        MenuListProps={{
          disablePadding: true
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            boxShadow: '0px 0px 12px rgba(104, 109, 142, 0.24)',
            mt: 0.5,
            ml: -1.5
          }
        }}
      >
        <MainMenu>
          {mainMenu.content?.map((value: any, index: any) =>
            value.type === 'normal' ? (
              <NormalMainMenu
                onClick={() => handlerMainMenu('CLICK_NORMAL', value)}
                data-testid={value.dataTestid}
                key={`normal_menu_${index}`}
              >
                <p>{value.label}</p>
              </NormalMainMenu>
            ) : value.type === 'divider' ? (
              <DividerMainMenu key={`divider_menu_${index}`} />
            ) : value.type === 'radio' ? (
              <RadioMainMenu key={`radio_menu_${index}`}>
                <p>{value.label}</p>
                <CuppertinoSwitch
                  onClick={() =>
                    dispatch(inputSwitchActionMenuComposer(value.name))
                  }
                  checked={dataSwitch[value.name]}
                  inputProps={
                    {
                      'data-testid': value.dataTestid
                    } as any
                  }
                />
              </RadioMainMenu>
            ) : value.type === 'extend' ? (
              <ExtendMenu
                isActivated={index === nestedMenu.index}
                onClick={e => handlerNestedMenu('OPEN', value, index, e)}
                data-testid={value.dataTestid}
                key={`extend_menu_${index}`}
              >
                <p>{value.label}</p>
                <img src={images.arrowRightComposer} />
              </ExtendMenu>
            ) : (
              <></>
            )
          )}
        </MainMenu>
      </Menu>
    );
  };

  // NESTED MENU FUNCTION

  const [nestedMenu, setNestedMenu] = useState<any>({
    open: false,
    anchorPosition: null,
    index: null,
    content: null
  });

  const handlerNestedMenu = (
    typeAction: any,
    value?: any,
    index?: any,
    event?: any
  ) => {
    switch (typeAction) {
      case 'OPEN':
        setNestedMenu({
          open: true,
          anchorPosition: event.currentTarget,
          index: index,
          content: value.content
        });
        break;
      case 'CLOSE':
        setNestedMenu({
          open: false,
          anchorPosition: null,
          index: null,
          content: null
        });
        break;
      default:
        console.error(`Wrong typeAction clickMenu`);
        break;
    }
  };

  const nestedMenuRender = () => (
    <Menu
      open={nestedMenu.open}
      onClose={() => handlerNestedMenu('CLOSE')}
      anchorEl={nestedMenu.anchorPosition}
      MenuListProps={{
        disablePadding: true
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          boxShadow: '0px 0px 12px rgba(104, 109, 142, 0.24)',
          mt: -1,
          ml: 1.5
        }
      }}
    >
      <NestedMenu>
        {nestedMenu.content?.map((value: any, index: any) =>
          value.type === 'normal' ? (
            <NormalMainMenu
              onClick={() => handlerMainMenu('CLICK_NORMAL', value)}
              data-testid={value.dataTestid}
              key={`normal_nested_menu_${index}`}
            >
              <p>{value.label}</p>
            </NormalMainMenu>
          ) : (
            <></>
          )
        )}
      </NestedMenu>
    </Menu>
  );

  // RENDER

  return (
    <>
      <NavMenu>
        {composerMainMenuList?.map((value: any, index: any) => (
          <NavMenuList
            onClick={e => handlerMainMenu('OPEN', value, index, e)}
            data-testid={value.dataTestid}
            key={`nav_menu_${index}`}
          >
            <p className="navMenuList">{value.label}</p>
            <div className="navMenuStrip" />
            {index === mainMenu.index ? (
              <div className="navMenuStripActivated" />
            ) : (
              <></>
            )}
          </NavMenuList>
        ))}
      </NavMenu>
      <>
        {mainMenuRender()}
        {nestedMenuRender()}
      </>
    </>
  );
};
