import React, { useRef, useState } from 'react';

import Icon from '../../../../components/atoms/Icon';
import { IconMore } from './styles';
import { InputField } from '../../../../components/molecules/FormattedInput/styles';
import { InputFieldNumeric } from '../../styles';
import InputToolbar from './InputToolbar';
import { formatPercentage } from '../../../../utils/helpersFunction';

interface FormattedNumberPropsI extends React.HTMLAttributes<HTMLInputElement> {
  value: any;
  onChange?: (value: any) => void;
  disabled?: boolean;
  onToolbarMenuSelected: (e: any, item?: any, data?: any) => void;
  properties: any;
  placeholder: string;
  sessionName?: any;
}

export const Percentage = React.forwardRef<any, FormattedNumberPropsI>(
  (props, ref) => {
    const {
      value,
      onChange,
      // disabled,
      properties,
      onToolbarMenuSelected,
      placeholder,
      sessionName
    } = props;
    const DEFAULT_DECIMAL = '2';
    const decimalNumber =
      properties?.format?.value?.decimal ??
      parseInt(sessionStorage.getItem(sessionName) || DEFAULT_DECIMAL);

    const [anchor, setAnchor] = useState<null | HTMLInputElement>(null);
    const toolbarRef = useRef<HTMLInputElement>(null);
    const [decimals, setDecimals] = useState(decimalNumber);
    const handleClickIcon = () => {
      const { current: el } = toolbarRef;
      setAnchor(el);
    };

    const handleCloseMenu = () => {
      setAnchor(null);
    };

    const handleClickMenu = (e: any, item: string) => {
      switch (item) {
        case 'increment':
          const newValue = decimals + 1;
          setDecimals(newValue);
          onToolbarMenuSelected(e, 'decimal', newValue);
          sessionStorage.setItem(sessionName, newValue.toString());
          break;
        case 'decrement':
          if (decimals !== 0) {
            const newValue = decimals - 1;
            setDecimals(newValue);
            onToolbarMenuSelected(e, 'decimal', newValue);
            sessionStorage.setItem(sessionName, newValue.toString());
          }
          break;
        default:
          break;
      }
    };

    const actions = [
      {
        type: 'default',
        title: 'decrement',
        onClick: (e: React.SyntheticEvent, item?: any) =>
          handleClickMenu(e, item),
        icon: 'decreaseDecimal'
      },
      {
        type: 'default',
        title: 'increment',
        onClick: (e: React.SyntheticEvent, item?: any) =>
          handleClickMenu(e, item),
        icon: 'increaseDecimal'
      }
    ];

    return (
      <React.Fragment>
        <InputField>
          {
            <InputFieldNumeric
              getInputRef={ref}
              value={value ? formatPercentage(value, decimals) : ''}
              type="text"
              displayType="input"
              suffix={' %'}
              decimalScale={decimals}
              decimalSeparator="."
              fixedDecimalScale={true}
              onValueChange={onChange}
              placeholder={placeholder}
              data-error={Boolean(!value)}
            />
          }
          <IconMore onClick={handleClickIcon} ref={toolbarRef}>
            <Icon icon="moreVertical" />
          </IconMore>
        </InputField>
        <InputToolbar
          anchor={anchor}
          onCloseMenu={handleCloseMenu}
          actions={actions}
        />
      </React.Fragment>
    );
  }
);
