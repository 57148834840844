//TODO: define internal function
import { Box } from '@mui/material';
import { Controller } from 'react-hook-form';
import parse from 'html-react-parser';

import images from '../../../assets';
import Text from '../../../components/atoms/Text';
import StyledToggle from '../../../components/atoms/Toggle';
import BootstrapTooltip from '../../../components/atoms/Tooltip';
import TooltipIcon from '../../../components/atoms/tooltipIcon';
import { ComposerInput } from '../../../components/molecules/composer/composerInput';
import ComposerProperties from '../../../components/molecules/composer/composerProperties';
import { ExpandIcon } from '../../../components/molecules/infoPanel/components';
import RichTextInput from '../../../components/molecules/RichText';
import {
  Properties,
  SubHeaderProperties,
  ContainerSubHeaderToggle,
  TypeProperties,
  SmallProperties,
  BigProperties,
  OptionProperties,
  FormulaProperties,
  ImageProperties,
  TextSubMenuField
} from '../../../pageElements/composer';
import { propertiesList } from '../../../utils/constant';
import {
  convertTextLevel,
  capitalizeFirstLetter,
  checkDuplication,
  notContainSpecialCharacterAndSpace,
  checkOptionsArray,
  isValidURL,
  fieldTypeActiveCheck,
  validateCharAfterNumber
} from '../../../utils/helpersFunction';

import { IComposerRight } from './composer-right.type';

export const ComposerRight = ({
  dataProperties,
  toggleExpandSubHeader,
  expandSubHeader,
  handlerShowCurrencyComponent,
  handlerShowTextComponent,
  handlerShowImageComponent,
  handlerShowInputComponent,
  handlerShowFieldComponent,
  register,
  handlerImportProperties,
  control,
  handlerGlobalComponent,
  dataBodyOfCalculationCloned,
  activePage,
  activeId
}: IComposerRight) => {
  return (
    <>
      {dataProperties.showProperties === true ? (
        <>
          <Properties
            onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
          >
            {propertiesList.map((valueParent: any) => {
              if (valueParent.type === dataProperties.propertiesType) {
                return valueParent.menu.map((valueMenu: any) => (
                  <>
                    {valueMenu.type === 'subHeader' && (
                      <div>
                        <SubHeaderProperties>
                          {valueMenu.label}
                        </SubHeaderProperties>
                      </div>
                    )}
                    {valueMenu.type === 'subHeaderToggle' && (
                      <ContainerSubHeaderToggle
                        onClick={() => {
                          toggleExpandSubHeader(
                            `${valueParent.type}_${valueMenu.label}`
                          );
                        }}
                      >
                        <SubHeaderProperties>
                          {valueMenu.label}
                        </SubHeaderProperties>
                        <ExpandIcon
                          expanded={
                            !expandSubHeader[
                              `${valueParent.type}_${valueMenu.label}`
                            ]
                          }
                          style={{ marginTop: '-3px' }}
                        />
                      </ContainerSubHeaderToggle>
                    )}
                    {expandSubHeader[
                      `${valueParent.type}_${valueMenu.label}`
                    ] &&
                      valueMenu.subMenu.map(
                        (valueSubMenu: { [key: string]: any }) =>
                          valueSubMenu.type === 'typeProperties' ? (
                            <TypeProperties
                              isDisabled={valueSubMenu.isDisabled}
                            >
                              <div id="typePropertiesHead">
                                <p>{valueSubMenu.label}</p>
                                <BootstrapTooltip
                                  placement="left"
                                  title={valueSubMenu.tooltip}
                                >
                                  <TooltipIcon symbol="?" />
                                </BootstrapTooltip>
                              </div>
                              {valueSubMenu.id === 'currency' ? (
                                <div
                                  id="typePropertiesBody"
                                  onClick={(e: React.SyntheticEvent) =>
                                    handlerShowCurrencyComponent('open', e)
                                  }
                                  data-testid={valueSubMenu.dataTestid}
                                >
                                  <p>{dataProperties.currency}</p>
                                  <img src={images.arrowDownComposer}></img>
                                </div>
                              ) : valueSubMenu.id === 'level' ? (
                                <div
                                  id="typePropertiesBody"
                                  onClick={(e: React.SyntheticEvent) =>
                                    handlerShowTextComponent('open', e)
                                  }
                                  data-testid={valueSubMenu.dataTestid}
                                >
                                  <p>
                                    {convertTextLevel(dataProperties.level)}
                                  </p>
                                  <img src={images.arrowDownComposer}></img>
                                </div>
                              ) : valueSubMenu.id === 'image' ? (
                                <div
                                  id="typePropertiesBody"
                                  onClick={(e: React.SyntheticEvent) =>
                                    handlerShowImageComponent('open', e)
                                  }
                                  data-testid={valueSubMenu.dataTestid}
                                >
                                  <p>
                                    {capitalizeFirstLetter(
                                      dataProperties.imageAlignment
                                    )}
                                  </p>
                                  <img src={images.arrowDownComposer}></img>
                                </div>
                              ) : valueSubMenu.id === 'field' ? (
                                <div
                                  id="typePropertiesBody"
                                  onClick={(e: React.SyntheticEvent) =>
                                    handlerShowFieldComponent('open', e)
                                  }
                                >
                                  {dataBodyOfCalculationCloned[activePage].map(
                                    (el: any) => {
                                      if (el.id === activeId) {
                                        return (
                                          <TextSubMenuField>
                                            <>
                                              {el.fieldType === 'RADIO_BUTTON'
                                                ? 'Radio Button'
                                                : el.fieldType.toLowerCase()}
                                            </>
                                          </TextSubMenuField>
                                        );
                                      }
                                    }
                                  )}
                                  <img src={images.arrowDownComposer}></img>
                                </div>
                              ) : (
                                <div
                                  id="typePropertiesBody"
                                  onClick={(e: React.SyntheticEvent) =>
                                    handlerShowInputComponent('open', e)
                                  }
                                >
                                  <p>{valueSubMenu.content}</p>
                                  <img src={images.arrowDownComposer}></img>
                                </div>
                              )}
                            </TypeProperties>
                          ) : valueSubMenu.type === 'smallProperties' ? (
                            <SmallProperties
                              formulaError={dataProperties.error}
                              mandatoryError={
                                valueSubMenu.mandatory &&
                                (dataProperties[valueSubMenu.id] === null ||
                                  dataProperties[valueSubMenu.id] === '' ||
                                  (valueSubMenu.id === 'variable' &&
                                    (checkDuplication(
                                      'variable',
                                      dataProperties.variable,
                                      dataProperties.idComponent
                                    ) ||
                                      !notContainSpecialCharacterAndSpace(
                                        dataProperties.variable,
                                        'variable'
                                      ))) ||
                                  (valueSubMenu.id === 'notation' &&
                                    (checkDuplication(
                                      'notation',
                                      dataProperties.notation,
                                      dataProperties.idComponent
                                    ) ||
                                      !notContainSpecialCharacterAndSpace(
                                        dataProperties.notation
                                      ))))
                              }
                            >
                              <div id="smallPropertiesHead">
                                <div id="labelSmallProperties">
                                  <p id="textLabelSmallProperties">
                                    {valueSubMenu.label}
                                  </p>
                                  {valueSubMenu.mandatory ? (
                                    <p id="starLabelSmallProperties">*</p>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <BootstrapTooltip
                                  placement="left"
                                  title={valueSubMenu.tooltip}
                                >
                                  <TooltipIcon symbol="?" />
                                </BootstrapTooltip>
                              </div>
                              {valueSubMenu.isNumber === true ? (
                                valueSubMenu.id === 'hardMax' ? (
                                  <input
                                    data-testid={valueSubMenu.dataTestid}
                                    {...register('hardMaxSmallProperties')}
                                    type="number"
                                    onBlur={(e: React.SyntheticEvent) =>
                                      handlerImportProperties(
                                        `ONBLUR_HARDMAX`,
                                        e
                                      )
                                    }
                                  ></input>
                                ) : valueSubMenu.id === 'hardMin' ? (
                                  <input
                                    data-testid={valueSubMenu.dataTestid}
                                    {...register('hardMinSmallProperties')}
                                    type="number"
                                    onBlur={(e: React.SyntheticEvent) =>
                                      handlerImportProperties(
                                        `ONBLUR_HARDMIN`,
                                        e
                                      )
                                    }
                                  ></input>
                                ) : valueSubMenu.id === 'softMax' ? (
                                  <input
                                    data-testid={valueSubMenu.dataTestid}
                                    {...register('softMaxSmallProperties')}
                                    type="number"
                                    onBlur={(e: React.SyntheticEvent) =>
                                      handlerImportProperties(
                                        `ONBLUR_SOFTMAX`,
                                        e
                                      )
                                    }
                                  ></input>
                                ) : valueSubMenu.id === 'softMin' ? (
                                  <input
                                    data-testid={valueSubMenu.dataTestid}
                                    {...register('softMinSmallProperties')}
                                    type="number"
                                    onBlur={(e: React.SyntheticEvent) =>
                                      handlerImportProperties(
                                        `ONBLUR_SOFTMIN`,
                                        e
                                      )
                                    }
                                  ></input>
                                ) : (
                                  <></>
                                )
                              ) : valueSubMenu.id === 'description' ? (
                                <>
                                  <Controller
                                    name="descriptionSmallProperties"
                                    control={control}
                                    render={({ field: { value } }) => {
                                      return (
                                        <RichTextInput.RichText
                                          component="properties"
                                          error={Boolean(
                                            !dataProperties[valueSubMenu.id]
                                          )}
                                          source={value}
                                          data-testid={valueSubMenu.dataTestid}
                                          handleSaveChange={(
                                            e: React.SyntheticEvent,
                                            data?: string
                                          ) =>
                                            handlerImportProperties(
                                              `ONBLUR_DESCRIPTION`,
                                              e,
                                              null,
                                              data
                                            )
                                          }
                                        />
                                      );
                                    }}
                                  />
                                  {valueSubMenu.mandatory &&
                                  !dataProperties[valueSubMenu.id] ? (
                                    <p id="smallPropertiesMandatoryError">
                                      Description must be filled
                                    </p>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : valueSubMenu.id === 'notation' ? (
                                <>
                                  <Controller
                                    name="notationSmallProperties"
                                    control={control}
                                    render={({ field: { value } }) => {
                                      return (
                                        <RichTextInput.RichText
                                          component="properties"
                                          maxLength={8}
                                          error={Boolean(
                                            !dataProperties[valueSubMenu.id]
                                          )}
                                          source={value}
                                          data-testid={valueSubMenu.dataTestid}
                                          handleSaveChange={(
                                            e: React.SyntheticEvent,
                                            data?: string
                                          ) =>
                                            handlerImportProperties(
                                              `ONBLUR_NOTATION`,
                                              e,
                                              null,
                                              data
                                            )
                                          }
                                          onFocus={() =>
                                            handlerGlobalComponent(
                                              'FOCUS_WORKPAGES_COMPONENT',
                                              {
                                                focusId:
                                                  'notationSmallProperties'
                                              }
                                            )
                                          }
                                        />
                                      );
                                    }}
                                  />
                                  {valueSubMenu.mandatory &&
                                  (dataProperties[valueSubMenu.id] === null ||
                                    dataProperties[valueSubMenu.id] === '') ? (
                                    <p id="smallPropertiesMandatoryError">
                                      Notation must be filled.
                                    </p>
                                  ) : checkDuplication(
                                      'notation',
                                      dataProperties[valueSubMenu.id],
                                      dataProperties.idComponent
                                    ) ? (
                                    <p id="smallPropertiesMandatoryError">
                                      Notation name already exists, please
                                      choose different notation name.
                                    </p>
                                  ) : !validateCharAfterNumber(
                                      dataProperties[valueSubMenu.id]
                                    ) ? (
                                    <p id="smallPropertiesMandatoryError">
                                      Can not input letter or special characters
                                      after number value.
                                    </p>
                                  ) : null}
                                </>
                              ) : valueSubMenu.id === 'variable' ? (
                                <>
                                  <input
                                    data-testid={valueSubMenu.dataTestid}
                                    {...register('variableSmallProperties')}
                                    type="text"
                                    onBlur={(e: React.SyntheticEvent) =>
                                      handlerImportProperties(
                                        `ONBLUR_VARIABLE`,
                                        e
                                      )
                                    }
                                    disabled={valueSubMenu.disabled}
                                  ></input>
                                  {valueSubMenu.mandatory &&
                                    (dataProperties[valueSubMenu.id] === null ||
                                    dataProperties[valueSubMenu.id] === '' ? (
                                      <p id="smallPropertiesMandatoryError">
                                        Variable must be filled.
                                      </p>
                                    ) : checkDuplication(
                                        'variable',
                                        dataProperties[valueSubMenu.id],
                                        dataProperties.idComponent
                                      ) ? (
                                      <p id="smallPropertiesMandatoryError">
                                        Variable name already exists, please
                                        choose different variable name.
                                      </p>
                                    ) : !notContainSpecialCharacterAndSpace(
                                        dataProperties.variable,
                                        'variable'
                                      ) ? (
                                      <p id="smallPropertiesMandatoryError">
                                        Please input alphanumeric format and
                                        without space for variable.
                                      </p>
                                    ) : (
                                      <></>
                                    ))}
                                </>
                              ) : valueSubMenu.id === 'unit' ? (
                                <Controller
                                  name="unitSmallProperties"
                                  control={control}
                                  render={({ field: { value } }) => {
                                    return (
                                      <RichTextInput.RichText
                                        component="properties"
                                        source={value}
                                        data-testid={valueSubMenu.dataTestid}
                                        handleSaveChange={(
                                          e: React.SyntheticEvent,
                                          data?: string
                                        ) =>
                                          handlerImportProperties(
                                            `ONBLUR_UNIT`,
                                            e,
                                            null,
                                            data
                                          )
                                        }
                                        maxLength={5}
                                      />
                                    );
                                  }}
                                />
                              ) : valueSubMenu.id === 'formula' ? (
                                <>
                                  <ComposerInput.Text
                                    register={register}
                                    id="formulaSmallProperties"
                                    onFocus={() =>
                                      handlerGlobalComponent(
                                        'FOCUS_WORKPAGES_COMPONENT',
                                        {
                                          focusId: 'formulaSmallProperties'
                                        }
                                      )
                                    }
                                    onBlur={(e: React.SyntheticEvent) =>
                                      handlerImportProperties(
                                        `ONBLUR_FORMULA`,
                                        e
                                      )
                                    }
                                  />
                                  {Boolean(dataProperties.error) && (
                                    <p id="formulaErrorSmallProperties">
                                      {dataProperties.error}
                                    </p>
                                  )}
                                </>
                              ) : valueSubMenu.id === 'reference' ? (
                                <input
                                  data-testid={valueSubMenu.dataTestid}
                                  {...register('referenceSmallProperties')}
                                  type="text"
                                  onBlur={(e: React.SyntheticEvent) =>
                                    handlerImportProperties(
                                      `ONBLUR_REFERENCE`,
                                      e
                                    )
                                  }
                                ></input>
                              ) : valueSubMenu.id === 'title' ? (
                                <>
                                  <ComposerInput.Text
                                    register={register}
                                    id="titleSmallProperties"
                                    onBlur={(e: React.SyntheticEvent) =>
                                      handlerImportProperties(`ONBLUR_TITLE`, e)
                                    }
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </SmallProperties>
                          ) : valueSubMenu.type === 'bigProperties' ? (
                            <BigProperties
                              mandatoryError={
                                (valueSubMenu.mandatory &&
                                  valueSubMenu.id === 'text' &&
                                  (dataProperties.description === null ||
                                    dataProperties.description === '')) ||
                                (valueSubMenu.id === 'url' &&
                                  !isValidURL(dataProperties.url)) ||
                                (valueSubMenu.id === 'url' &&
                                  !dataProperties.url)
                              }
                            >
                              <div id="bigPropertiesHead">
                                <div id="labelBigProperties">
                                  <p id="textLabelBigProperties">
                                    {valueSubMenu.label}
                                  </p>
                                  {valueSubMenu.mandatory ? (
                                    <p id="starLabelBigProperties">*</p>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <BootstrapTooltip
                                  placement="left"
                                  title={valueSubMenu.tooltip}
                                >
                                  <TooltipIcon symbol="?" />
                                </BootstrapTooltip>
                              </div>
                              {valueSubMenu.id === 'tooltip' ? (
                                <textarea
                                  data-testid={valueSubMenu.dataTestid}
                                  {...register('tooltipBigProperties')}
                                  onBlur={(e: React.SyntheticEvent) =>
                                    handlerImportProperties(`ONBLUR_TOOLTIP`, e)
                                  }
                                ></textarea>
                              ) : valueSubMenu.id === 'notes' ? (
                                <textarea
                                  data-testid={valueSubMenu.dataTestid}
                                  {...register('notesBigProperties')}
                                  onBlur={(e: React.SyntheticEvent) =>
                                    handlerImportProperties(`ONBLUR_NOTES`, e)
                                  }
                                ></textarea>
                              ) : valueSubMenu.id === 'text' ? (
                                <>
                                  <Controller
                                    control={control}
                                    name="textBigProperties"
                                    render={({ field: { value } }) => {
                                      return (
                                        <RichTextInput.RichText
                                          component="properties"
                                          error={Boolean(
                                            !dataProperties.description
                                          )}
                                          source={value}
                                          data-testid={valueSubMenu.dataTestid}
                                          data-render-as="text-area"
                                          handleSaveChange={(
                                            e: React.SyntheticEvent,
                                            data: string
                                          ) => {
                                            handlerImportProperties(
                                              `ONBLUR_DESCRIPTION`,
                                              e,
                                              null,
                                              data
                                            );
                                          }}
                                        />
                                      );
                                    }}
                                  />
                                  {valueSubMenu.mandatory &&
                                  !dataProperties.description ? (
                                    <p id="bigPropertiesMandatoryError">
                                      Text must be filled,
                                    </p>
                                  ) : null}
                                </>
                              ) : valueSubMenu.id === 'caption' ? (
                                <Controller
                                  control={control}
                                  name="captionBigProperties"
                                  render={({ field: { value } }) => (
                                    <RichTextInput.RichText
                                      component="properties"
                                      source={value}
                                      data-testid={valueSubMenu.dataTestid}
                                      data-render-as="text-area"
                                      handleSaveChange={(
                                        e: React.SyntheticEvent,
                                        data: string
                                      ) => {
                                        handlerImportProperties(
                                          `ONBLUR_DESCRIPTION`,
                                          e,
                                          null,
                                          data
                                        );
                                      }}
                                    />
                                  )}
                                />
                              ) : valueSubMenu.id === 'url' ? (
                                <>
                                  <Controller
                                    control={control}
                                    name="urlBigProperties"
                                    render={({ field: { value } }) => {
                                      return (
                                        <RichTextInput.RichText
                                          component="properties"
                                          error={
                                            Boolean(!dataProperties.url) ||
                                            !isValidURL(dataProperties.url)
                                          }
                                          source={value}
                                          data-testid={valueSubMenu.dataTestid}
                                          data-render-as="link-area"
                                          handleSaveChange={(
                                            e: React.SyntheticEvent,
                                            data: string
                                          ) => {
                                            handlerImportProperties(
                                              `ONBLUR_URL`,
                                              e,
                                              null,
                                              data
                                            );
                                          }}
                                        />
                                      );
                                    }}
                                  />
                                  {valueSubMenu.mandatory &&
                                  !dataProperties.url ? (
                                    <p id="bigPropertiesMandatoryError">
                                      URL must be filled
                                    </p>
                                  ) : valueSubMenu.mandatory &&
                                    !isValidURL(dataProperties.url) ? (
                                    <p id="bigPropertiesMandatoryError">
                                      Please input a valid URL that begin with
                                      https:// or http://
                                    </p>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : null}
                            </BigProperties>
                          ) : // ) : valueSubMenu.type === 'summaryProperties' ? (
                          //   <SummaryProperties
                          //     onClick={(e: React.SyntheticEvent) =>
                          //       handlerImportProperties('SUMMARY_CHECK')
                          //     }
                          //     data-testid={valueSubMenu.dataTestid}
                          //   >
                          //     {dataProperties.inSummary ? (
                          //       <img
                          //         width={16}
                          //         src={images.checkBoxCheckComposer}
                          //       />
                          //     ) : (
                          //       <img
                          //         width={16}
                          //         src={images.checkBoxUncheckComposer}
                          //       />
                          //     )}
                          //     <p>Include in Summary</p>
                          //   </SummaryProperties>
                          valueSubMenu.type === 'optionProperties' ||
                            (valueSubMenu.type === 'optionPropertiesField' &&
                              fieldTypeActiveCheck(
                                dataBodyOfCalculationCloned,
                                activePage,
                                activeId
                              )) ? (
                            <OptionProperties
                              mandatoryError={
                                (valueSubMenu.mandatory &&
                                  checkOptionsArray(
                                    dataProperties[valueSubMenu.id]
                                  )) ||
                                (valueSubMenu.type ===
                                  'optionPropertiesField' &&
                                fieldTypeActiveCheck(
                                  dataBodyOfCalculationCloned,
                                  activePage,
                                  activeId
                                )
                                  ? checkOptionsArray(
                                      dataProperties[valueSubMenu.id]
                                    )
                                  : false)
                              }
                            >
                              <div id="optionPropertiesHead">
                                <div id="labelOptionProperties">
                                  <p id="textLabelOptionProperties">
                                    Input Option
                                  </p>
                                  {valueSubMenu.mandatory ||
                                  (valueSubMenu.type ===
                                    'optionPropertiesField' &&
                                    fieldTypeActiveCheck(
                                      dataBodyOfCalculationCloned,
                                      activePage,
                                      activeId
                                    )) ? (
                                    <p id="starLabelOptionProperties">*</p>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <BootstrapTooltip
                                  placement="left"
                                  title={valueSubMenu.tooltip}
                                >
                                  <TooltipIcon symbol="?" />
                                </BootstrapTooltip>
                              </div>
                              <div id="optionPropertiesBody">
                                {Boolean(dataProperties.options) &&
                                  dataProperties.options?.map(
                                    (valueSubMenu: any, index: number) => (
                                      <div>
                                        {valueSubMenu === '' ? (
                                          <p id="optionPropertiesPlaceHolder">{`Option ${
                                            index + 1
                                          }`}</p>
                                        ) : (
                                          <p id="optionPropertiesNoPlaceHolder">
                                            {parse(valueSubMenu)}
                                          </p>
                                        )}
                                        <img
                                          onClick={(e: React.SyntheticEvent) =>
                                            handlerImportProperties(
                                              `REMOVE_OPTIONS`,
                                              e,
                                              index
                                            )
                                          }
                                          src={images.closeIconComposer}
                                        ></img>
                                      </div>
                                    )
                                  )}
                                <input
                                  data-testid={valueSubMenu.dataTestid}
                                  {...register('optionRegisterInputProperties')}
                                  onBlur={(e: React.SyntheticEvent) =>
                                    handlerImportProperties(`ONBLUR_OPTIONS`, e)
                                  }
                                  type="text"
                                ></input>
                              </div>
                              {(valueSubMenu.mandatory &&
                                checkOptionsArray(
                                  dataProperties[valueSubMenu.id]
                                )) ||
                              (valueSubMenu.type === 'optionPropertiesField' &&
                                fieldTypeActiveCheck(
                                  dataBodyOfCalculationCloned,
                                  activePage,
                                  activeId
                                ) &&
                                checkOptionsArray(
                                  dataProperties[valueSubMenu.id]
                                )) ? (
                                <p id="optionPropertiesMandatoryError">
                                  Input option must be filled with at least 1
                                  characters for the description
                                </p>
                              ) : (
                                <></>
                              )}
                            </OptionProperties>
                          ) : valueSubMenu.type === 'formulaProperties' ? (
                            <FormulaProperties>
                              <div id="formulaPropertiesHead">
                                <p>Display Formula Ouput</p>
                                <BootstrapTooltip
                                  placement="left"
                                  title={valueSubMenu.tooltip}
                                >
                                  <TooltipIcon symbol="?" />
                                </BootstrapTooltip>
                              </div>
                              <div id="formulaPropertiesBody">
                                <div
                                  onClick={(e: React.SyntheticEvent) =>
                                    handlerImportProperties(
                                      'FORMULA_DISPLAY_ON',
                                      e
                                    )
                                  }
                                  data-testid="radioButtonShow"
                                >
                                  {dataProperties.isDisplayed ? (
                                    <img
                                      width={14}
                                      src={images.radioOnComposer}
                                    ></img>
                                  ) : (
                                    <img
                                      width={14}
                                      src={images.radioOffComposer}
                                    ></img>
                                  )}
                                  <p>Show</p>
                                </div>
                                <div
                                  onClick={(e: React.SyntheticEvent) =>
                                    handlerImportProperties(
                                      'FORMULA_DISPLAY_OFF',
                                      e
                                    )
                                  }
                                  data-testid="radioButtonHide"
                                >
                                  {dataProperties.isDisplayed ? (
                                    <img
                                      width={14}
                                      src={images.radioOffComposer}
                                    ></img>
                                  ) : (
                                    <img
                                      width={14}
                                      src={images.radioOnComposer}
                                    ></img>
                                  )}
                                  <p>Hide</p>
                                </div>
                              </div>
                            </FormulaProperties>
                          ) : valueSubMenu.type === 'imageProperties' ? (
                            <ImageProperties
                              isDisabled={!Boolean(dataProperties.imageUrl)}
                            >
                              <div id="imagePropertiesHead">
                                <p>Size</p>
                                <BootstrapTooltip
                                  placement="left"
                                  title={valueSubMenu.tooltip}
                                >
                                  <TooltipIcon symbol="?" />
                                </BootstrapTooltip>
                              </div>
                              <div id="imagePropertiesBody">
                                <div id="imageSizeContainer">
                                  <p>W</p>
                                  <input
                                    disabled={!Boolean(dataProperties.imageUrl)}
                                    {...register('imageWidthProperties')}
                                    onBlur={(e: React.SyntheticEvent) =>
                                      handlerImportProperties('ONBLUR_WIDTH', e)
                                    }
                                    type="number"
                                    data-testid="inputSizeWidthProperties"
                                  ></input>
                                </div>
                                <div
                                  onClick={() =>
                                    handlerImportProperties('IMAGE_LOCK')
                                  }
                                  id="imageImageContainer"
                                >
                                  {dataProperties.isLocked === true ? (
                                    <img
                                      src={images.linkIconComposerLock}
                                    ></img>
                                  ) : (
                                    <img
                                      src={images.linkIconComposerUnlock}
                                    ></img>
                                  )}
                                </div>
                                <div id="imageSizeContainer">
                                  <p>H</p>
                                  <input
                                    disabled={!Boolean(dataProperties.imageUrl)}
                                    {...register('imageHeightProperties')}
                                    onBlur={(e: React.SyntheticEvent) =>
                                      handlerImportProperties(
                                        'ONBLUR_HEIGHT',
                                        e
                                      )
                                    }
                                    type="number"
                                    data-testid="inputSizeHeightProperties"
                                  ></input>
                                </div>
                              </div>
                            </ImageProperties>
                          ) : valueSubMenu.type === 'booleanProperties' ? (
                            valueSubMenu.id === 'addRow' ? (
                              <ComposerProperties.Switch
                                value={dataProperties.isAllowedToAddRow}
                                text={valueSubMenu.label}
                                clicked={(
                                  e: React.SyntheticEvent,
                                  value: boolean
                                ) =>
                                  handlerImportProperties(
                                    `TOGGLE_ADD_ROW`,
                                    e,
                                    value
                                  )
                                }
                              />
                            ) : (
                              <></>
                            )
                          ) : valueSubMenu.type === 'formulaLatexProperties' ? (
                            <Box style={{ flexDirection: 'row' }}>
                              <Text variant="body3" style={{ marginRight: 5 }}>
                                {valueSubMenu.label}
                              </Text>
                              <StyledToggle
                                size="medium"
                                onChange={(e: any) =>
                                  handlerImportProperties(
                                    'FORMULA_DISPLAY',
                                    e,
                                    dataProperties.isDisplayed
                                  )
                                }
                                checked={Boolean(dataProperties.isDisplayed)}
                              />
                            </Box>
                          ) : (
                            <></>
                          )
                      )}
                  </>
                ));
              }
            })}
          </Properties>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
