import 'jspreadsheet/dist/jspreadsheet.css';
import 'jsuites/dist/jsuites.css';
import './styles.css';

import jspreadsheet, {
  Column,
  Contextmenu,
  Worksheet,
  worksheetInstance
} from 'jspreadsheet';
import {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle
} from 'react';

import {
  ALIGNMENTS,
  CellType,
  DISPLAYED_CONTENT_BORDER_NAME,
  FillHandlerDirection,
  ToolbarItem
} from '.';
import { Container } from './elements';
import {
  getCaretPosition,
  getCellName,
  getSelectedPos,
  setCaretPosition
} from './utils';
import { getToolbar } from './toolbar';
import { bannedKey } from '../../../utils/constant';

interface PropsI {
  cellStyle: CellStyle;
  columnResize?: boolean;
  columnSetting?: Array<Column>;
  columnSorting?: boolean;
  contextMenu?: Contextmenu;
  data: Array<Array<CellContent>>;
  dimensions: [number, number];
  displayedContent?: CellRange;
  editable?: boolean;
  hideHiddenCells?: boolean;
  name: string;
  nonEditable?: boolean;
  overflow?: boolean;
  selectedCellRange?: CellRange;
  showHeader?: boolean;
  showIndex?: boolean;
  stickyColumn?: number;
  stickyRow?: number;
  toolbar?: Array<ToolbarItem>;

  onAfterChanges?: (worksheet: any, updatedCells: Array<any>) => void;
  onCellRangeSelection?: (
    worksheet: any,
    columnStart?: number,
    rowStart?: number,
    columnEnd?: number,
    rowEnd?: number,
    e?: any
  ) => void;
  onColumnResize?: any;
  onDeleteRow?: (
    worksheet: any,
    rowRecords: any,
    rowData: any,
    cellAttributes: any
  ) => void;
  onInsertRow?: (
    worksheet: any,
    rowNumber: number,
    numOfRows: number,
    historyRecords: Array<any>,
    insertBefore: boolean
  ) => void;
  onValueChange?: any;
  size?: {
    height: number;
    width: number;
  };
  onEditing?: (
    worksheet: Record<string, unknown>,
    cell: Element,
    value: string,
    cursor: number,
    selectedPosition: Record<string, unknown>
  ) => void;
  clearEditingCell?: (worksheet: Record<string, unknown>) => void;
  rows?: Array<RowIndex>;
  cells?: Record<string, unknown>;
}

const DEFAULT_COL_WIDTH = 150;
const DEFAULT_COMPONENT_HEIGHT = 512;
const DEFAULT_COMPONENT_WIDTH = 1004;

const FormulaTable = ({ size, ...props }: PropsI, ref?: any) => {
  const [viewHeight, setViewHeight] = useState(
    size ?? {
      height: DEFAULT_COMPONENT_HEIGHT, // atau 712
      width: DEFAULT_COMPONENT_WIDTH
    }
  );

  useEffect(() => {
    const width = size?.width ?? DEFAULT_COMPONENT_WIDTH;
    const height = size?.height ?? DEFAULT_COMPONENT_HEIGHT;
    setViewHeight({
      width,
      height
    });
  }, [size]);

  useEffect(() => {
    const { width, height } = viewHeight;
    setViewPort(width, height);
  }, [viewHeight]);

  useImperativeHandle(ref, () => ({
    deleteRow: (rowNumber: number, numOfRows: number) =>
      deleteRow(rowNumber, numOfRows),
    getData: (processed: boolean) => getContent(processed),
    hideColumn: (colNumber: number) => hideColumn(colNumber),
    hideRow: (rowNumber: number) => hideRow(rowNumber),
    insertRow: (numOfRows: number, rowNumber: number, insertBefore: boolean) =>
      insertRow(numOfRows, rowNumber, insertBefore),
    resetBorder: () => resetBorder(),
    setBorder: (cellRange: CellRange) => setBorder(cellRange),
    getBorder: () => getBorder(),
    setColumnWidth: (colNumber: number, width: number) =>
      setColumnWidth(colNumber, width),
    setData: (data: Array<Array<CellContent>>) => setData(data),
    setDefinedNames: (definedNames: Record<string, string>) =>
      setDefinedNames(definedNames),
    setStyle: (style: Record<string, unknown>) => setStyle(style),
    showColumn: (colNumber: number) => showColumn(colNumber),
    showRow: (rowNumber: number) => showRow(rowNumber),
    resetStyles: (data: Array<Array<CellContent>>, cellRange: CellRange) =>
      resetStyles(data, cellRange),
    renameTableName: (tableName: string) => renameTableName(tableName),
    deleteTable: () => deleteTable(),
    setCellValue: (text: any, editingCell: any) =>
      setCellValue(text, editingCell),
    setFreeze: (row: number, column: number) => setFreeze(row, column),
    getCellProcessedValue: (x: number, y: number) =>
      getCellProcessedValue(x, y),
    setCellReadOnly: (cellName: string, readOnly: boolean) =>
      setCellReadOnly(cellName, readOnly),
    setCellProperties: (
      cellName: string,
      properties: Record<string, unknown>
    ) => {
      setCellProperties(cellName, properties);
    }
  }));

  function setCellValue(text: any, editingCell: any) {
    if (editingCell) {
      const { sheet, cell, value, cursor, selectedPosition } = editingCell;

      const string = value.replace(/\n/g, '');

      let first = string.substring(0, cursor);
      let last = string.substring(cursor);

      if (selectedPosition.start != selectedPosition.end) {
        first = string.substring(0, selectedPosition.start);
        last = string.substring(selectedPosition.end);
      }

      if (cell.target.x >= 0 && cell.target.y >= 0) {
        sheet.setValueFromCoords(
          cell.target.x,
          cell.target.y,
          first + text + last,
          true
        );

        sheet.openEditor(
          sheet.getCellFromCoords(cell.target.x, cell.target.y),
          'a',
          cell.target.x,
          cell.target.y,
          sheet
        );

        if (cell.from !== 'oneditionstart')
          setCaretPosition(cell.target, (first + text).length);
      }
    }
  }

  function deleteTable() {
    if (worksheet.length > 0) {
      worksheet[0].deleteWorksheet(0);
    }
  }

  function renameTableName(tableName: string) {
    if (worksheet.length > 0) {
      worksheet[0].renameWorksheet(0, tableName);
    }
  }

  function deleteRow(rowNumber: number, numOfRows: number) {
    worksheet[0].deleteRow(rowNumber, numOfRows);
  }

  function insertRow(
    numOfRows: number,
    rowNumber: number,
    insertBefore: boolean
  ) {
    worksheet[0].insertRow(numOfRows, rowNumber, insertBefore);
  }

  function hideRow(rowNumber: number) {
    worksheet[0].hideRow(rowNumber);
  }

  function showRow(rowNumber: number) {
    worksheet[0].showRow(rowNumber);
  }

  function hideColumn(colNumber: number) {
    worksheet[0].hideColumn(colNumber);
  }

  function setColumnWidth(colNumber: number, width: number) {
    worksheet[0].setWidth(colNumber, width);
  }

  function showColumn(colNumber: number) {
    worksheet[0].showColumn(colNumber);
  }

  const getContent = (processed?: boolean) => {
    if (worksheet) {
      return processed
        ? worksheet[0]?.getData(false, true)
        : worksheet[0]?.getData();
    }
  };

  function setData(data: Array<Array<CellContent>>) {
    if (worksheet && data) {
      try {
        worksheet[0].setData(getData(data));
      } catch (error) {}

      const style = getStyle(data);

      worksheet[0].resetStyle(Object.keys(style));
      setStyle(style);
    }
  }

  function setDefinedNames(definedNames: Record<string, string>) {
    if (worksheet && worksheet[0]?.getDefinedNames()) {
      const variableList: Array<any> = [];
      Object.entries(definedNames).forEach(([name, cell]) => {
        variableList.push({ index: name, value: cell });
      });

      Object.keys(worksheet[0].getDefinedNames()).forEach(name => {
        if (!variableList.find(variable => variable.index == name)) {
          variableList.push({ index: name, value: '' });
        }
      });
      worksheet[0].setDefinedNames(variableList);
    }
  }

  function setStyle(style: Record<string, unknown>) {
    worksheet[0].setStyle(style);
  }

  function setBorder(cellRange: CellRange) {
    if (!cellRange) return;

    const { row, column } = cellRange;
    worksheet[0].setBorder(
      column.start,
      row.start,
      column.end,
      row.end,
      DISPLAYED_CONTENT_BORDER_NAME,
      'black'
    );
  }

  function getBorder() {
    return worksheet[0].getBorder(DISPLAYED_CONTENT_BORDER_NAME);
  }

  function resetBorder() {
    worksheet[0].resetBorders(DISPLAYED_CONTENT_BORDER_NAME, true);
  }

  function setFreeze(row: number, column: number) {
    worksheet[0].setFreezeRows(row);
    worksheet[0].setFreezeColumns(column);
  }

  function setViewPort(
    width = DEFAULT_COMPONENT_WIDTH,
    height = DEFAULT_COMPONENT_HEIGHT
  ) {
    if (worksheet?.length && size) worksheet[0].setViewport(width, height);
  }

  const jssRef = useRef(null);

  const showHeader = props.showHeader ?? true;
  const showIndex = props.showIndex ?? true;
  const editable = props.editable ?? true;

  const [worksheet, setWorksheet] = useState<any>();
  let copyDistancePoint: any = null;
  let copySelectedCells: Array<number>;
  let fromInsideSource: boolean;
  let isCornerDrag = false;
  let isPasting = false;
  let selectedCells: CellRange;

  useEffect(() => {
    if (jssRef.current !== null) {
      setWorksheet(jspreadsheet(jssRef.current, getConfig()));
    }
  }, []);

  useEffect(() => {
    if (worksheet) {
      if (showIndex === false) worksheet[0].hideIndex();
      if (props.hideHiddenCells && props.displayedContent)
        hideUnselectedContent();
      if (props.selectedCellRange)
        setSelectedCell(
          props.selectedCellRange.row,
          props.selectedCellRange.column
        );
      if (
        props.hideHiddenCells !== true &&
        typeof props.displayedContent !== 'undefined'
      )
        setBorder(props.displayedContent);

      setTimeout(() => {
        if (props.stickyRow) worksheet[0].setFreezeRows(props.stickyRow);
        if (props.stickyColumn)
          worksheet[0].setFreezeColumns(props.stickyColumn);
      }, 500);
      disableContent();
      if (props.selectedCellRange)
        setSelectedCell(
          props.selectedCellRange.row,
          props.selectedCellRange.column
        );
    }
  }, [worksheet]);

  function setSelectedCell(rowRange: Range, columnRange: Range) {
    worksheet[0].updateSelectionFromCoords(
      columnRange.start,
      rowRange.start,
      columnRange.end,
      rowRange.end
    );
  }

  function hideUnselectedContent() {
    if (typeof props.displayedContent === 'undefined') return;

    const totalColumn = props.dimensions[0];
    const totalRow = props.dimensions[1];
    const { row, column } = props.displayedContent;

    for (let i = 0; i < totalColumn; i++) {
      if (i >= column.start && i <= column.end) continue;
      worksheet[0].hideColumn(i);
    }

    for (let i = 0; i < totalRow; i++) {
      if (i >= row.start && i <= row.end) continue;
      worksheet[0].hideRow(i);
    }
  }

  function disableContent(data?: Array<Array<CellContent>>) {
    const currentData = data ?? props.data;
    if (!currentData) return;
    currentData.forEach((row, rowIndex) => {
      row.forEach((cell, colIndex) => {
        const cellName = getCellName(colIndex, rowIndex);
        if (cell.isDisabled)
          worksheet[0].setReadOnly(cellName, cell.isDisabled);
      });
    });
  }

  function getData(data: Array<Array<CellContent>>) {
    return data.map(row => row.map(cell => cell.value));
  }

  function getStyle(data: Array<Array<CellContent>>) {
    const style: any = [];
    data.map((row, rowIndex) => {
      row.map((cell, colIndex) => {
        const cellName = getCellName(colIndex, rowIndex);
        style[cellName] = cellStyleMapper(cell.type);
        style[cellName] += getCellAlignment(cell.alignment);
      });
    });

    return style;
  }

  function getCellAlignment(cellAligment: string) {
    const alignmentOptions = Array.from(ALIGNMENTS.keys());
    const alignment = alignmentOptions.find(
      key => ALIGNMENTS.get(key) == cellAligment
    );
    if (alignment) return `text-align: ${alignment.split('_')[2]};`;
    return '';
  }

  const cellStyleMapper = (cellType: string): string => {
    const { cellStyle } = props;
    switch (cellType) {
      case CellType.HEADER:
        return cellStyle.header;
      case CellType.INPUT:
        return cellStyle.input;
      case CellType.READONLY:
        return cellStyle.readonly;
      case CellType.DEFAULT:
      default:
        return cellStyle.default;
    }
  };

  function getEmptyHeader() {
    const emptyColumnTitle = { title: ' ' };
    const headers: any = [];
    for (let c = 0; c < props.dimensions[0]; c++) {
      headers.push(emptyColumnTitle);
    }
    return headers;
  }

  function getFillHandlerDirection(
    selectedRange: CellRange,
    row: number,
    col: number
  ) {
    const { row: selectedRow, column: selectedColumn } = selectedRange;
    const isRowInRange = row >= selectedRow.start && row <= selectedRow.end;
    const isColumnInRange =
      col >= selectedColumn.start && col <= selectedColumn.end;

    let direction;
    if (isRowInRange)
      direction =
        col >= selectedColumn.start
          ? FillHandlerDirection.RIGHT
          : FillHandlerDirection.LEFT;
    if (isColumnInRange)
      direction =
        row >= selectedRow.start
          ? FillHandlerDirection.DOWN
          : FillHandlerDirection.UP;

    return direction;
  }

  function getSourceCell(selectedRange: CellRange, row: number, col: number) {
    const { row: selectedRow, column: selectedColumn } = selectedRange;
    const direction = getFillHandlerDirection(selectedCells, row, col);
    const selectionSize = {
      row: selectedRow.end - selectedRow.start + 1,
      column: selectedColumn.end - selectedColumn.start + 1
    };

    switch (direction) {
      case FillHandlerDirection.RIGHT:
        return { row, column: col - selectionSize.column };
      case FillHandlerDirection.LEFT:
        return { row, column: col + selectionSize.column };
      case FillHandlerDirection.UP:
        return { row: row + selectionSize.row, column: col };
      case FillHandlerDirection.DOWN:
        return { row: row - selectionSize.row, column: col };
      default:
        return {
          row: selectedRange.row.start,
          column: selectedRange.column.start
        };
    }
  }

  function getConfig() {
    const options = {
      editable: editable,
      contextMenu: props.contextMenu,
      onbeforepaste: (
        // worksheet: worksheetInstance,
        data: any
        // x: number,
        // y: number,
        // style: Array<any>,
        // processedData: string
      ) => {
        isPasting = true;
        return data;
      },
      onclick: (
        worksheet: worksheetInstance,
        section: string
        // x: number,
        // y: number
      ) => {
        if (section === 'cloning') {
          isCornerDrag = true;
          isPasting = true;
        }
      },
      onafterchanges: props.onAfterChanges,
      onbeforechange: (
        worksheet: any,
        cell: any,
        x: number,
        y: number
        // value: any
      ) => {
        if (!isCornerDrag && !fromInsideSource) return;
        if (!selectedCells || (!isCornerDrag && !isPasting)) return;

        let sourcePosition: CellLocation;
        if (isCornerDrag) {
          sourcePosition = getSourceCell(selectedCells, y, x);
        } else {
          if (copySelectedCells && copyDistancePoint == null) {
            copyDistancePoint = {
              row: y - copySelectedCells[1],
              column: x - copySelectedCells[0]
            };
          }
          sourcePosition = {
            row: y - copyDistancePoint.row,
            column: x - copyDistancePoint.column
          };
        }

        const sourceCell = worksheet.helpers.getColumnNameFromCoords(
          sourcePosition.column,
          sourcePosition.row
        );
        const destinationCell = worksheet.helpers.getColumnNameFromCoords(x, y);

        const sourceValue = worksheet.getValueFromCoords(
          sourcePosition.column,
          sourcePosition.row
        );
        const destinationValue = worksheet.getValueFromCoords(x, y);

        if (sourceValue == destinationValue) {
          const sourceStyle = worksheet.getStyle(sourceCell).split(';');
          const destinationBackgroundColor = worksheet
            .getStyle(destinationCell)
            .split(';')
            .find((style: string) => style.includes('background-color'));
          const strippedStyle =
            sourceStyle
              .filter(
                (style: string) =>
                  !style.includes('border') &&
                  style !== destinationBackgroundColor
              )
              .join(';') + ';';
          worksheet.setStyle({ [destinationCell]: strippedStyle });
        }
      },
      onchange: props.onValueChange,
      oncopy: (worksheet: any, selectedCells: any, data: string) => {
        copySelectedCells = selectedCells;
        fromInsideSource = true;
        return data;
      },
      ondeleterow: props.onDeleteRow,
      oninsertrow: props.onInsertRow,
      onpaste: () => {
        fromInsideSource = false;
      },
      onresizecolumn: props.onColumnResize,
      onselection: (
        worksheet: any,
        px: number,
        py: number,
        ux: number,
        uy: number,
        origin?: any
      ) => {
        selectedCells = {
          row: { start: py, end: uy },
          column: { start: px, end: ux }
        };
        !!props.onCellRangeSelection && props.onCellRangeSelection(worksheet);
        if (props.clearEditingCell) props.clearEditingCell(worksheet);
        if (typeof origin === 'undefined') {
          isCornerDrag = false;
          isPasting = false;
          copyDistancePoint = null;
        }
      },
      plugins: { CellEditor },
      toolbar: props.toolbar && getToolbar(props.toolbar),
      worksheets: [
        {
          locked: props.nonEditable === true ? true : false,
          selectLockedCells: props.nonEditable === true ? false : true,
          columnResize: props.columnResize ?? false,
          columnSorting: props.columnSorting ?? false,
          columnSortingOnDblClick: props.columnSorting ?? false,
          columns: props.columnSetting ?? [],
          data: getData(props.data),
          defaultColAlign: 'left',
          defaultColWidth: DEFAULT_COL_WIDTH,
          minDimensions: props.dimensions,
          rowResize: false,
          style: getStyle(props.data),
          tableOverflow: props.overflow ? true : false,
          worksheetName: props.name,
          rows: props.rows ?? [],
          cells: props.cells ?? {}
        } as Worksheet
      ],
      oneditionstart: (worksheet: any, cell: HTMLElement) => {
        const cellCaret: any = {
          target: {
            x: Number(cell.getAttribute('data-x')),
            y: Number(cell.getAttribute('data-y'))
          },
          from: 'oneditionstart'
        };
        selectedPosition = {
          start: cell.innerText.length,
          end: cell.innerText.length
        };
        caret = cell.innerText.length;

        setSelectedCellEditor(
          worksheet,
          cellCaret,
          cell.innerText,
          caret,
          selectedPosition
        );
      }
    };
    if (props.showHeader === false)
      options.worksheets[0].columns = getEmptyHeader();
    return options;
  }

  function resetStyles(data: Array<Array<CellContent>>, cellRange?: CellRange) {
    if (worksheet) {
      setData(data);
      disableContent(data);
      if (cellRange) setBorder(cellRange);
    }
  }

  // TODO: move plugin(s) below to their own files
  const CellEditor = function (spreadsheet: any) {
    const plugin: any = {};
    let activeSheet: any;

    plugin.init = function (worksheet: any) {
      activeSheet = worksheet;
    };

    function handleCellEditorEvent(e: any) {
      if (bannedKey.includes(e.key)) {
        return;
      }

      onEditValue = e.target.innerText;
      caret = getCaretPosition(e.currentTarget);
      selectedPosition = getSelectedPos();

      setSelectedCellEditor(
        activeSheet,
        e,
        onEditValue,
        caret,
        selectedPosition
      );
    }

    spreadsheet.input.addEventListener('keyup', handleCellEditorEvent);
    spreadsheet.input.addEventListener('mouseup', handleCellEditorEvent);
    spreadsheet.input.addEventListener('mouseout', handleCellEditorEvent);

    return plugin;
  };

  function setSelectedCellEditor(
    worksheet: any,
    cell: any,
    value: string,
    cursorPosition: number,
    selectedPosition: Record<string, unknown>
  ) {
    if (props.onEditing)
      props.onEditing(worksheet, cell, value, cursorPosition, selectedPosition);
  }

  function getCellProcessedValue(col: number, row: number) {
    const cellName = getCellName(col, row);
    return worksheet[0].getValue(cellName, true);
  }

  function setCellReadOnly(cell: string, readOnly: boolean) {
    worksheet[0].setReadOnly(cell, readOnly);
  }

  function setCellProperties(
    cellName: string,
    properties: Record<string, unknown>
  ) {
    worksheet[0].setCells(cellName, properties);
  }

  return <Container ref={jssRef} showHeader={showHeader} />;
};

export default forwardRef(FormulaTable);

type CellContent = {
  value: string | number;
  type: string;
  isDisabled?: boolean;
  alignment: string;
};

type CellLocation = {
  row: number;
  column: number;
};

type Range = {
  start: number;
  end: number;
};

type CellRange = {
  row: Range;
  column: Range;
};

type CellStyle = {
  default: string;
  header: string;
  input: string;
  readonly: string;
};

let caret: number;
let onEditValue = '';
let selectedPosition: Record<string, unknown>;

type RowIndex = {
  title: string;
};
