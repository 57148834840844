import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Dispatch } from 'redux';

import {
  FETCHING_INPUT_FILE_FAILED,
  FETCHING_INPUT_FILE_SUCCESS,
  FETCHING_DATA,
  FETCH_INPUT_FILE_BY_CALCULATION_ID_FAILED,
  FETCH_INPUT_FILE_BY_CALCULATION_ID_SUCCESS,
  REMOVE_ERROR_MESSAGE,
  SET_ERROR_MESSAGE,
  SHOW_HIDE_LOGGER_MODAL_ACTION,
  CLEAR_ALL_INPUTS,
  CLEAR_INPUTS_AT_PAGE,
  SET_SELECTED_PAGE,
  SET_LOGGER_FORM_VALUES,
  SAVING_INPUT_FILES,
  LOGGER_ACTION_LOADING,
  REFRESH_INPUT_FILE,
  UPLOAD_LOGO,
  FORM_STATE_ERROR,
  SAVING_TABLE,
  TABLE_STATE,
  RENDERING_LATEX,
  LOGGER_ERROR_FORM,
  LOGGER_VARIABLE_INPUT
} from '../types/loggerTypes';
import { submitLoading } from '../appState/global/globalActions';
import { toastSetupActionPopUp } from '../appState/popUp/toast/toastAction';
import InputFileService from '../../service/InputFileService';
import { localDatetime } from '../../utils/dateConvert';
import { getUserDetails } from '../../utils/storage';
import { convertLatex, insertLatex } from '../../utils/helpersFunction';
import TagManagerService from '../../service/tagManagerService';

const tagManagerService = TagManagerService();

export const fetchData = (isFetching: boolean) => {
  return { type: FETCHING_DATA, isFetching };
};

export const fetchSuccess = (payload: Array<any>) => {
  return {
    type: FETCHING_INPUT_FILE_SUCCESS,
    payload
  };
};

export const fetchFailed = (error: string) => {
  return {
    type: FETCHING_INPUT_FILE_FAILED,
    error
  };
};

export const setLoggerFormValues = (payload: any) => {
  return {
    type: SET_LOGGER_FORM_VALUES,
    payload
  };
};

export const savingInputFile = (payload: boolean) => {
  return {
    type: SAVING_INPUT_FILES,
    payload
  };
};

export const savingInputFileToApi = (
  inputFileId: string,
  inputFiles: any
): ThunkAction<
  Promise<void>,
  Record<string, unknown>,
  Record<string, unknown>,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<
      Record<string, unknown>,
      Record<string, unknown>,
      any
    >
  ): Promise<void> => {
    dispatch(savingInputFile(true));
    await InputFileService.update(inputFileId, inputFiles)
      .then(response => {
        tagManagerService.saveInputFile();
        dispatch(refreshInputFile(response.id));
        dispatch(savingInputFile(false));
      })
      .catch((error: any) => dispatch(fetchFailed(error.message)));
  };
};

export const fetchDataInputFile = (
  inputFileId: string
): ThunkAction<
  Promise<void>,
  Record<string, unknown>,
  Record<string, unknown>,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<
      Record<string, unknown>,
      Record<string, unknown>,
      any
    >
  ): Promise<void> => {
    return new Promise<void>(() => {
      dispatch(fetchData(true));
      InputFileService.getById(inputFileId)
        .then((data: any) => {
          dispatch(setLoggerFormValues([]));
          dispatch(fetchSuccess(data));
        })
        .catch(error => dispatch(fetchFailed(error)));
    });
  };
};

export const CreateNewInputFile = (
  calculationId: string
): ThunkAction<
  Promise<void>,
  Record<string, unknown>,
  Record<string, unknown>,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<
      Record<string, unknown>,
      Record<string, unknown>,
      AnyAction
    >
  ): Promise<void> => {
    return new Promise<void>(() => {
      dispatch(fetchData(true));
      InputFileService.create({ calculationId: calculationId })
        .then(data => dispatch(fetchSuccess(data)))
        .catch(error => dispatch(fetchFailed(error.message)));
    });
  };
};

export const SaveAsInputFile = (
  payload: any
): ThunkAction<
  Promise<void>,
  Record<string, unknown>,
  Record<string, unknown>,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<
      Record<string, unknown>,
      Record<string, unknown>,
      AnyAction
    >
  ): Promise<void> => {
    return new Promise<void>(() => {
      dispatch(fetchData(true));
      InputFileService.saveAs(payload)
        .then(data => dispatch(fetchSuccess(data)))
        .catch(error => dispatch(fetchFailed(error.message)));
    });
  };
};

// export const fetchInputFileByCalculationId = (calculationId: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
//   return async (dispatch: ThunkDispatch<{}, {}, any>) => {
//     dispatch({
//       type: FETCH_INPUT_FILE_BY_CALCULATION_ID
//     });
//     service.getDataWithParams({calculationId: calculationId})
//     .then(response => dispatch(fetchInputFileByCalculationIdSuccess(response.data)))
//     .catch(error => dispatch(fetchInputFileByCalculationIdFailed(error)))
//   }
// }

export const setSelectedPage = (pageIndex: number) => {
  return {
    type: SET_SELECTED_PAGE,
    pageIndex
  };
};

export const clearAllInputs = (clearedInputFile: any) => {
  return {
    type: CLEAR_ALL_INPUTS,
    clearedInputFile
  };
};

export const clearInputsAtPage = (clearedInputFile: any) => {
  return {
    type: CLEAR_INPUTS_AT_PAGE,
    clearedInputFile
  };
};

export const fetchInputFileByCalculationIdSuccess = (data: any) => {
  return {
    type: FETCH_INPUT_FILE_BY_CALCULATION_ID_SUCCESS,
    data
  };
};

export const fetchInputFileByCalculationIdFailed = (error: string) => {
  return {
    type: FETCH_INPUT_FILE_BY_CALCULATION_ID_FAILED,
    error
  };
};

export const showHideLoggerModalAction = (
  isVisible: boolean,
  modalActionType: string
) => {
  return {
    type: SHOW_HIDE_LOGGER_MODAL_ACTION,
    isVisible,
    modalActionType
  };
};

export const setErrorMessage = (error: string) => {
  return {
    type: SET_ERROR_MESSAGE,
    error
  };
};

export const removeErrorMessage = (error: string) => {
  return {
    type: REMOVE_ERROR_MESSAGE,
    error
  };
};

export const loggerActionLoading = (payload: boolean) => {
  return {
    type: LOGGER_ACTION_LOADING,
    payload
  };
};

export function renameInputFile(inputFileId: string, payload: any) {
  return async (dispatch: Dispatch) => {
    dispatch(submitLoading(true));
    try {
      await InputFileService.rename(inputFileId, payload.title);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };
}

export const uploadLogo = (
  e: any,
  logoType: string,
  inputFileId: string
): ThunkAction<
  Promise<void>,
  Record<string, unknown>,
  Record<string, unknown>,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<
      Record<string, unknown>,
      Record<string, unknown>,
      any
    >
  ): Promise<any> => {
    return new Promise<void>(() => {
      const payload = new FormData();
      payload.append('file', e.target.files[0]);
      payload.append('type', logoType);
      dispatch(
        logoUploading({
          uploadLoading: true,
          logoType: logoType
        })
      );
      dispatch(loggerActionLoading(true));
      InputFileService.uploadLogo(inputFileId, payload)
        .then(data => {
          dispatch(
            logoUploading({
              uploadLoading: false,
              logoType: ''
            })
          );
          dispatch({
            type: FETCHING_INPUT_FILE_SUCCESS,
            payload: data
          });
          dispatch(loggerActionLoading(false));
          dispatch(toastSetupActionPopUp('INFO', 'Upload Complete'));
        })
        .catch((error: any) => {
          console.error(error);
        });
    });
  };
};

export const logoUploading = (payload: any) => {
  return {
    type: UPLOAD_LOGO,
    payload
  };
};

export function refreshInputFile(inputFileId: string) {
  return async (dispatch: Dispatch) => {
    try {
      await InputFileService.getById(inputFileId)
        .then((data: any) => {
          dispatch(setLoggerFormValues([]));
          dispatch(fetchSuccess(data));
        })
        .catch((err: any) => console.error(err?.message));
    } catch (error) {
      return error;
    }
  };
}

export function formStateError(payload: any) {
  return {
    type: FORM_STATE_ERROR,
    payload
  };
}

export function savingTable(payload: any) {
  return {
    type: SAVING_TABLE,
    payload
  };
}

export function setTableState(payload: any) {
  return {
    type: TABLE_STATE,
    payload
  };
}

export function renderingLatex(payload: any) {
  return {
    type: RENDERING_LATEX,
    payload
  };
}

export function setLoggerErrorForm(payload: any) {
  return {
    type: LOGGER_ERROR_FORM,
    payload
  };
}

export function setLoggerVariableInput(payload: any) {
  return {
    type: LOGGER_VARIABLE_INPUT,
    payload
  };
}

export function getTempInputFile(calculationId: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(fetchData(true));
      const response = await InputFileService.temp(calculationId);
      const newResponse = {
        ...response,
        id: 'temp',
        createdAt: localDatetime(),
        createdBy: getUserDetails()
      };
      dispatch(setLoggerFormValues([]));
      dispatch(fetchSuccess(newResponse));
    } catch (error: any) {
      return Promise.reject(error);
    }
  };
}

export function clearRecentInputFile() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: REFRESH_INPUT_FILE,
        payload: {}
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };
}

export function saveTempInputFile(inputFileId: string, payload: any) {
  return async () => {
    try {
      const result: any = await InputFileService.update(inputFileId, payload);
      return Promise.resolve(result);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };
}

export const saveLoggerFromNavbar = async (payload: any, dispatch: any) => {
  try {
    const { renderingLatex, currentInputFile, loggerFormValues } = payload;
    if (renderingLatex.length > 0) {
      const latexData: any = await convertLatex(renderingLatex);
      const savingData =
        latexData.length > 0
          ? await insertLatex(
              latexData,
              loggerFormValues.length > 0
                ? loggerFormValues
                : currentInputFile.pages
            )
          : loggerFormValues.length > 0
            ? loggerFormValues
            : currentInputFile.pages;
      const setLogger = {
        pages: savingData
      };
      await Promise.all([
        dispatch(
          savingInputFileToApi(
            currentInputFile.id,
            currentInputFile.id === 'temp'
              ? {
                  ...setLogger,
                  id: null,
                  calculationId: currentInputFile.calculationId,
                  title: currentInputFile.title
                }
              : setLogger
          )
        )
      ]).catch(error => console.error(error));
    } else {
      const savingData =
        loggerFormValues.length > 0 ? loggerFormValues : currentInputFile.pages;
      const setLogger = {
        pages: savingData
      };
      await Promise.all([
        dispatch(
          savingInputFileToApi(
            currentInputFile.id,
            currentInputFile.id === 'temp'
              ? {
                  ...setLogger,
                  id: null,
                  calculationId: currentInputFile.calculationId,
                  title: currentInputFile.title
                }
              : setLogger
          )
        )
      ]).catch(error => console.error(error));
    }

    return Promise.resolve();
  } catch (error: any) {
    return Promise.reject(error);
  }
};
