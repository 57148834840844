import { InputProps } from '../../logger.types';
import { Tooltip, Typography } from '@mui/material';
import { useController } from 'react-hook-form';
import parser from 'html-react-parser';
import {
  DescriptionBoxField,
  // InputBox,
  InputBoxField,
  InputWrapper
  // NotationBox
} from './styles';
import Input from '../../../../components/atoms/Input';

function DateField({ name, control, properties, defaultValue }: InputProps) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error }
  } = useController({
    control,
    name,
    rules: {
      validate: {
        required: value => {
          if (!value) return `${properties.description} is required`;
          return true;
        }
      }
    },
    defaultValue
  });

  return (
    <InputWrapper>
      <DescriptionBoxField contentEditable={false}>
        <Typography variant="body3" component={'span'}>
          {properties.description
            ? parser(properties.description)
            : properties.description}
        </Typography>
      </DescriptionBoxField>

      <InputBoxField>
        <Tooltip title={properties.tooltip === null ? '' : properties.tooltip}>
          <Input
            fullWidth
            type={'date'}
            onChange={onChange}
            onBlur={onBlur}
            value={value ?? ''}
            name={name}
            inputRef={ref}
            error={Boolean(error) || value === null || value === ''}
            size="small"
            placeholder={'dd/mm/yyyy'}
          />
        </Tooltip>
      </InputBoxField>
    </InputWrapper>
  );
}

export default DateField;
