import { InputProps } from '../../logger.types';
import { Tooltip, Typography } from '@mui/material';
import { useController } from 'react-hook-form';
import parser from 'html-react-parser';
import { StyledTextArea } from '../../../../components/atoms/TextArea';
import {
  DescriptionBox,
  InputBox,
  InputWrapper,
  NotationBox,
  UnitBox
} from './styles';

function Formula({ name, control, properties, defaultValue }: InputProps) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error }
  } = useController({
    control,
    name,
    defaultValue
  });

  return (
    <InputWrapper>
      <DescriptionBox contentEditable={false}>
        <Typography variant="body3" component={'span'}>
          {properties.description
            ? parser(properties.description)
            : properties.description}
        </Typography>
      </DescriptionBox>

      <NotationBox>
        <Typography variant="body3">
          {properties.notation
            ? parser(properties.notation)
            : properties.notation}
        </Typography>
      </NotationBox>

      <InputBox>
        <Tooltip title={properties.tooltip === null ? '' : properties.tooltip}>
          <StyledTextArea
            fullWidth
            onChange={onChange}
            onBlur={onBlur}
            value={value ?? ''}
            name={name}
            inputRef={ref}
            error={Boolean(error)}
            disabled={true}
            placeholder={'Waiting for input..'}
            size="small"
            sx={{
              input: { textAlign: 'right' }
            }}
          />
        </Tooltip>
      </InputBox>

      <UnitBox>
        <Typography variant="body3" component={'span'}>
          {properties.unit ? parser(properties.unit) : properties.unit}
        </Typography>
      </UnitBox>
    </InputWrapper>
  );
}

export default Formula;
