import { styled } from '@mui/material';
import NumberFormat from 'react-number-format';

export const PageContainer = styled('div')(({ theme }) => ({
  padding: '24px 0',
  display: 'flex',
  backgroundColor: theme.palette.secondary.main,
  alignItems: 'flex-start',
  gap: '10px',
  justifyContent: 'center',
  position: 'absolute',
  width: '100%'
}));

export const SidePanel = styled('div')({
  display: 'flex'
});

export const StickyWrapper = styled('div')({
  flexDirection: 'column',
  position: 'fixed'
});

export const SideBar = styled('div')(({ theme }) => ({
  // display: 'flex',
  // flexDirection: 'column',
  width: 236,
  backgroundColor: theme.palette.common.white,
  padding: 24,
  // maxheight: "289px",
  color: theme.customText.disable,
  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.24), 0px 0px 16px rgba(0, 0, 0, 0)',
  borderRadius: 6,
  '& > h2': {
    fontSize: 10,
    lineHeight: 1.4,
    textTransform: 'uppercase',
    marginBottom: 24,
    letterSpacing: '1.5px'
  }
}));

export const PanelSection = styled('div')(({ theme }) => ({
  marginBottom: 24,
  overflowWrap: 'anywhere',
  '& > p': {
    fontSize: 12,
    lineHeight: '18px',
    color: theme.customText.disable,
    marginBottom: 4
  },
  '& > h4': {
    fontSize: 14,
    lineHeight: '20px',
    color: theme.customText.high
  }
}));

export const InputFieldNumeric = styled(NumberFormat)(({ theme }) => ({
  padding: '9.5px 14px',
  margin: 0,
  width: 130,
  fontSize: 12,
  gap: 4,
  border: `1px solid #E0E6EB`,
  borderRadius: 6,
  textAlign: 'right',
  color: theme.palette.secondary.contrastText,
  '&.danger': {
    border: `1px solid ${theme.palette.error.main} !important`
  },
  '&.warning': {
    border: '1px solid orange !important'
  },
  "&[data-error='true']": {
    borderColor: theme.palette.error.main
  }
}));

export const InputFieldFormula = styled(NumberFormat)(() => ({
  padding: '9.5px 14px',
  margin: 0,
  width: 130,
  fontSize: 12,
  gap: 4,
  border: `1px solid #E0E6EB`,
  borderRadius: 6,
  textAlign: 'right',
  backgroundColor: '#F0F2F5',
  color: '#29333D'
}));
