import { APIResponse } from '../utils/interfaces';
import api from './api';

const URL = 'users';

export interface ResponseGetUsersI extends APIResponse<Array<UserDataI>> {
  page: number;
  page_size: number;
  size: number;
  total: number;
  total_pages: number;
}

export interface UserDataI {
  activeAt: string;
  company: any;
  email: string;
  id: string;
  isActive: boolean;
  name: string;
  paymentStatus: {
    status: string | null;
    pretty: string;
  };
  personalUserCompany: string;
  role: Array<string>;
  subscription: {
    id: string;
    plan: string | null;
    pretty: string;
  };
  agreement?: any;
  companyId?: string;
}

export interface CompanyInterface {
  id: string;
  isActive: boolean;
  title: string;
  workspaceId: string;
}

const UserService = {
  getAllUsers: async (
    page: number,
    size: number,
    role: string,
    sort?: string
  ): Promise<ResponseGetUsersI> => {
    const response = sort
      ? await api.get(`${URL}?${sort}page=${page}&size=${size}&role=${role}`)
      : await api.get(`${URL}?page=${page}&size=${size}&role=${role}`);
    return response.data;
  },
  getListSubscription: async (): Promise<void> => {
    const response = await api.get('subscriptions');
    return response.data;
  },
  getCompanyList: async (): Promise<APIResponse<Array<CompanyInterface>>> => {
    const response = await api.get('companies?paged=false');
    return response.data;
  },
  postAddCompany: async ({
    title
  }: {
    title: string;
  }): Promise<APIResponse<CompanyInterface>> => {
    const response = await api.post('company', { title });
    return response.data;
  }
};

export default UserService;
