import styled from 'styled-components';
import { styled as styledMUI } from '@mui/material';

interface Props {
  marginBottom?: string;
  padding?: string;
  activeForm?: boolean;
  expanded?: boolean;
  disabled?: boolean;
  width?: string;
  height?: string;
  isHeader?: boolean;
  isDragging?: boolean;
  isDisabled?: boolean;
  isActive?: boolean;
  formulaError?: boolean;
  tableError?: boolean;
  imageLeft?: string;
  imageRight?: string;
  imageComponent?: string;
  imageIndicator?: string;
  idProps?: string;
  showMenu?: boolean;
  fontSize?: string;
  alignment?: string;
  imageHeight?: number;
  imageWidth?: number;
  type?: string;
  mandatoryError?: boolean;
  windowSize?: { [key: string]: number };
}

type InputFieldProps = {
  error?: boolean;
  component?: string;
} & React.HTMLAttributes<HTMLInputElement>;

// SUPER CONTENT

export const Container = styled.div({
  paddingTop: '8px',
  display: 'flex',
  backgroundColor: '#F0F0F1',
  justifyContent: 'center',
  alignItems: 'flex-start',
  height: 'calc(100vh - 72px)',
  overflowY: 'auto'
});

// LEFT CONTENT

export const Interaction = styled.div<Props>(() => ({
  height: 'calc(100vh - 72px)',
  width: '236px',
  position: 'fixed',
  zIndex: 1,
  left: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  userSelect: 'none'
}));

//// MISC

export const TheXAtMisc = styled.div({
  position: 'absolute',
  right: 0,
  top: 0,
  marginTop: 22.5,
  marginRight: 27.5,
  '& > img': {
    cursor: 'pointer'
  }
});

//// COMPONENT

export const ComponentContent = styled.div({
  flex: 1,
  padding: '24px',
  overflowY: 'auto',
  backgroundColor: '#FFFFFF',
  borderTopRightRadius: '4px',
  borderBottomRightRadius: '4px',
  position: 'relative',
  alignSelf: 'stretch',
  boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.12)'
});

export const TitleAtComponentContent = styled.div({
  display: 'flex',
  marginBottom: '24px',
  '& > p': {
    color: '#9A9DB3',
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase'
  }
});

export const DataContainerAtComponentContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px'
});

export const DNDWrapperDataAtComponentContent = styled.div({
  display: 'flex',
  flexDirection: 'column'
});

export const DataAtComponentContent = styled.div<Props>(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '24px',
  width: '156px',
  padding: '8px 16px',
  backgroundColor: '#F0F2F5',
  borderRadius: '6px'
}));

export const CloneDataAtComponentContent = styled(DataAtComponentContent)`
  + div {
    display: none !important;
  }
`;

export const TextDataAtComponentContent = styled.p<Props>(props => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  color: props.disabled ? '#A3B3C2' : '#29333D'
}));

////// VARIABLE NETWORK

export const VarianbleNetworkContent = styled.div({
  flex: 1,
  padding: '24px',
  overflowY: 'auto',
  backgroundColor: '#FFFFFF',
  borderTopRightRadius: '4px',
  borderBottomRightRadius: '4px',
  position: 'relative',
  alignSelf: 'stretch',
  boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.12)'
});

export const TitleAtVarianbleNetworkContent = styled.div({
  display: 'flex',
  marginBottom: '24px',
  '& > p': {
    color: '#9A9DB3',
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase'
  }
});

////// VARIABLE MANAGER

export const VariableManagerContent = styled.div({
  flex: 1,
  padding: '24px',
  overflowY: 'auto',
  backgroundColor: '#FFFFFF',
  borderTopRightRadius: '4px',
  borderBottomRightRadius: '4px',
  position: 'relative',
  alignSelf: 'stretch',
  boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.12)'
});

export const TitleAtVariableManagerContent = styled.div({
  display: 'flex',
  marginBottom: '24px',
  '& > p': {
    color: '#9A9DB3',
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase'
  }
});

export const SearchAtVariableManagerContent = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: '10px',
  paddingBottom: '10px',
  paddingRight: '12px',
  paddingLeft: '12px',
  border: '1px solid #E0E6EB',
  borderRadius: '6px',
  marginBottom: '34px',
  '& > input': {
    width: '100%',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    border: 'none',
    '&::placeholder': {
      color: '#B3BFCC'
    },
    '&:focus': {
      outline: 'none'
    }
  },
  '& > img': {
    cursor: 'pointer'
  }
});

export const DataContainerAtVariableManagerContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
});

export const DataAtVariableManagerContent = styled.div<Props>(props => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '10px',
  paddingBottom: '10px',
  paddingLeft: '12px',
  paddingRight: '12px',
  backgroundColor: '#F0F2F5',
  borderRadius: '6px',
  gap: '8px',
  userSelect: 'none',
  cursor: props.type === 'table' ? 'default' : 'pointer',
  '& > .headData': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > .leftHeadDataText': {
      maxWidth: '100px',
      wordWrap: 'break-word',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px'
    },
    '& > .rightHeadDataText': {
      maxWidth: '37px',
      wordWrap: 'break-word',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px'
    }
  },
  '& > .footData': {
    display: 'flex',
    '& > .footDataText': {
      maxWidth: '147px',
      wordWrap: 'break-word',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: '#9A9DB3'
    }
  }
}));

////// COMMENT

export const CommentContent = styled.div({
  flex: 1,
  padding: '24px',
  overflowY: 'auto',
  backgroundColor: '#FFFFFF',
  borderTopRightRadius: '4px',
  borderBottomRightRadius: '4px',
  position: 'relative',
  alignSelf: 'stretch',
  boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.12)'
});

// MIDDLE CONTENT

export const ComposerMainContent = styled.div({
  display: 'flex',
  justifyContent: 'center'
});

export const WrapperMainContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '684px'
});

//// HEAD CONTENT

export const HeaderMainContent = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxShadow: 'none',
  overflow: 'auto',
  '& > div': {
    display: 'flex',
    gap: '0.5rem',
    width: '100%',
    overflow: 'hidden'
  },
  '& > .arrowIcon': {
    cursor: 'pointer',
    ':active': {
      transform: 'scale(0.8)'
    }
  }
});

export const PagesAtHeaderMainContent = styled.div({
  height: '32px',
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  paddingBottom: '12px',
  paddingTop: '12px'
});

export const Page = styled.div<Props>(props => ({
  width: 'max-content',
  minWidth: '104px',
  padding: '7px',
  display: 'flex',
  flexShrink: 0,
  borderRadius: '6px',
  backgroundColor: '#FFFFFF',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  gap: '8px',
  border:
    props.isDragging === true ? '1px dashed #118CFF' : '1px solid transparent',
  '& > p': {
    fontSize: '12px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'bold',
    letterSpacing: '0.4px',
    color: props.isActive ? '#118CFF' : props.hidden ? '#A3B3C2' : '#29333D',
    userSelect: 'none'
  },
  '& > img': {
    userSelect: 'none'
  },
  '& > input': {
    width: '200px',
    outline: 'none',
    color: '#118CFF',
    fontSize: '12px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'bold',
    letterSpacing: '0.4px',
    '::selection': {
      backgroundColor: '#29333D'
    },
    '&:focus': {
      outline: 'none',
      border: 'none',
      backgroundColor: 'transparent'
    }
  }
}));

export const AddPage = styled.div({
  minWidth: '104px',
  padding: '8px',
  display: 'flex',
  borderRadius: '6px',
  backgroundColor: '#FFFFFF',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  gap: '8px',
  '& > p': {
    fontSize: '12px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'bold',
    letterSpacing: '0.4px',
    userSelect: 'none'
  },
  '& > img': {
    userSelect: 'none'
  }
});

//// BODY CONTENT

export const ComposerBodyMainContent = styled.div({
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '854px',
  marginBottom: '20px'
});

////// HEAD AT BODY

export const HeadAtBodyMainContent = styled.div({
  borderBottom: '1px solid #E0E6EB'
});

export const HeadAtBodyMainContentWrapper = styled.div({
  padding: '16px',
  display: 'flex',
  flexDirection: 'row'
});

export const HeadAtBodyMainContentLeft = styled.div<Props>(props => ({
  gap: '8px',
  display: 'flex',
  flexDirection: 'row',
  marginRight: '16px',
  paddingTop: '6px',
  paddingBottom: '6px',
  '& > #divImgLeft': {
    width: '64px',
    height: '64px',
    backgroundImage: `url("${props.imageLeft}")`,
    borderRadius: '100%'
  },
  '& > #divImgRight': {
    width: '64px',
    height: '64px',
    backgroundImage: `url("${props.imageRight}")`,
    borderRadius: '100%'
  }
}));

export const HeadAtBodyMainContentCenter = styled.div({
  width: '588px',
  marginLeft: '16px',
  '& > #topMainContentCenter': {
    fontFamily: 'Inter',
    fontSize: '18px',
    letterSpacing: '0.44px',
    marginBottom: '12px',
    // height: '24px',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '95%',
    '& > b': {
      // overflow: "hidden",
      // textOverflow: "ellipsis",
      // whiteSpace: "nowrap",
      // width: "95%",
      overflowWrap: 'anywhere'
    },
    '& > img': {
      marginLeft: '9px',
      width: '20px',
      height: '20px',
      cursor: 'pointer'
    }
  },
  '& > #botMainContentCenter': {
    color: '#9A9DB3',
    fontFamily: 'Inter',
    fontSize: '10px',
    letterSpacing: '0.4px',
    '& > #botMainContentCenterWrapperTop': {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '8px',
      '& > div': {
        width: '50%',
        display: 'flex',
        flexDirection: 'row',
        '& > .titleHeadBody': {
          width: '70px'
        },
        '& > .dotHeadBody': {
          marginLeft: '4px',
          marginRight: '4px'
        },
        '& > .dataHeadBody': {
          color: '#29333D'
        }
      }
    },
    '& > #botMainContentCenterWrapperBot': {
      display: 'flex',
      flexDirection: 'row',
      '& > div': {
        width: '50%',
        display: 'flex',
        flexDirection: 'row',
        '& > .titleHeadBody': {
          width: '70px'
        },
        '& > .dotHeadBody': {
          marginLeft: '4px',
          marginRight: '4px'
        },
        '& > .dataHeadBody': {
          color: '#29333D'
        }
      }
    }
  }
});

export const HeadAtBodyMainContentRight = styled.div({
  // empty div :)
});

////// BODY AT BODY

export const EmptyBody = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  pointerEvents: 'none',
  userSelect: 'none',
  flex: 1
});

export const BodyAtBodyMainContent = styled.div({
  display: 'flex',
  padding: 7,
  flex: 1
});

export const BodyAtBodyMainContentWrapper = styled.div<Props>(props => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '11px 0',
  backgroundColor: props.isDragging === true ? '#E3F3FF' : '',
  border: props.isDragging === true ? '1px dashed #118CFF' : '1px dashed white',
  borderRadius: '6px',
  flex: 1,
  width: '100%'
}));

//////// BODY AT BODY COMPONENT WRAPPER & 2 3 4 CHILD

export const ComponentWrapper = styled.div<Props>(props => ({
  // position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  // minWidth: '636px',
  width: '100%',
  margin: '0 -12px 0 0',
  // flex: 1,
  '& .dragDotsDragging': {
    // position: 'absolute',
    height: '12px',
    width: '12px',
    left: '-18px',
    top: '12px',
    objectFit: 'contain',
    visibility: 'visible'
  },
  '& .dragDots': {
    // position: 'absolute',
    height: '12px',
    width: '12px',
    // left: '-18px',
    // top: '12px',
    objectFit: 'contain',
    visibility: 'hidden',
    opacity: 0,
    transition: '10s'
  },
  '& .threeDots': {
    // position: 'absolute',
    height: '12px',
    width: '12px',
    // right: '-18px',
    // top: '12px',
    objectFit: 'contain',
    visibility: props.showMenu === true ? 'visible' : 'hidden',
    opacity: props.showMenu === true ? 1 : 0,
    transition: '10s',
    cursor: 'pointer'
  },
  '& .copyDots': {
    // position: 'absolute',
    marginTop: '4px',
    height: '28px',
    width: '28px',
    right: '-64px',
    top: '0px',
    objectFit: 'contain',
    visibility: props.showMenu === true ? 'visible' : 'hidden',
    cursor: 'pointer'
  },
  '& .deleteDots': {
    // position: 'absolute',
    marginTop: '4px',
    height: '28px',
    width: '28px',
    right: '-104px',
    top: '0px',
    objectFit: 'contain',
    visibility: props.showMenu === true ? 'visible' : 'hidden',
    cursor: 'pointer'
  },
  '&:hover': {
    '& .dragDots': {
      visibility: 'visible',
      opacity: 1,
      transition: '0.25s'
    },
    '& .threeDots': {
      visibility: 'visible',
      opacity: 1,
      transition: '0.25s'
    }
  }
}));

////////// BODY AT BODY COMPONENT

export const InputTextComp = styled.div<Props>(props => ({
  border: props.isActive
    ? '1px dashed #189EFF'
    : props.mandatoryError
      ? '1px solid #DE294C'
      : '1px solid #E0E6EB',
  borderRadius: '6px',
  padding: '3px',
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
  backgroundColor: 'white',
  // marginBottom: '8px',
  alignItems: 'flex-start',
  cursor: 'pointer !important',
  width: '100%'
}));

export const InputDropDownComp = styled.div<Props>(props => ({
  border: props.isActive
    ? '1px dashed #189EFF'
    : props.mandatoryError
      ? '1px solid #DE294C'
      : '1px solid #E0E6EB',
  borderRadius: '6px',
  padding: '3px',
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
  backgroundColor: 'white',
  // marginBottom: '8px',
  cursor: 'pointer !important',
  alignItems: 'flex-start',
  width: '100%',
  '& > #selectInputDropDown': {
    height: '24x',
    width: '142px',
    padding: '4px',
    paddingLeft: '8px',
    paddingRight: '8px',
    background: '#E3F3FF',
    border: '1px solid #D6DFEB',
    borderRadius: '6px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > p': {
      color: '#B3BFCC',
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      fontStyle: 'normal',
      letterSpacing: '0.4px'
    }
  }
}));

export const InputRadioComp = styled.div<Props>(props => ({
  border: props.isActive
    ? '1px dashed #189EFF'
    : props.mandatoryError
      ? '1px solid #DE294C'
      : '1px solid #E0E6EB',
  padding: '3px',
  borderRadius: '6px',
  backgroundColor: 'white',
  // marginBottom: '8px',
  width: '100%',
  cursor: 'pointer !important',
  '& > .inputRadioRowWrapper': {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    '& > .inputRadioColumnWrapper': {
      display: 'flex',
      flexDirection: 'row',
      gap: '4px',
      alignItems: 'flex-start',
      '& > #newOptionInputRadio': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#118CFF',
        height: '18px',
        minWidth: '76px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '12px',
        letterSpacing: '0.4px',
        paddingLeft: '4px',
        cursor: 'pointer',
        padding: '4px 16px',
        border: '1px solid #118CFF',
        borderRadius: '6px'
      }
    }
  }
}));

export const InputNumberComp = styled.div<Props>(props => ({
  border: props.isActive
    ? '1px dashed #189EFF'
    : props.mandatoryError
      ? '1px solid #DE294C'
      : '1px solid #E0E6EB',
  borderRadius: '6px',
  padding: '3px',
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
  backgroundColor: 'white',
  // marginBottom: '8px',
  alignItems: 'flex-start',
  cursor: 'pointer !important',
  width: '100%',
  '& > #descriptionInputNumber': {
    width: '332px',
    height: '22px',
    padding: '4px',
    paddingLeft: '8px',
    paddingRight: '8px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #E0E6EB',
    borderRadius: '6px',
    fontFamily: 'Inter',
    fontSize: '9.5px',
    letterSpacing: '0.4px',
    fontWeight: 400,
    '&:focus': {
      outline: 'none',
      border: '1px solid #118CFF'
    },
    '&::placeholder': {
      color: '#B3BFCC'
    }
  },
  '& > #notationInputNumber': {
    width: '50px',
    height: '22px',
    padding: '4px',
    paddingLeft: '8px',
    paddingRight: '8px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #E0E6EB',
    borderRadius: '6px',
    fontFamily: 'Inter',
    fontSize: '9.5px',
    fontWeight: 400,
    fontStyle: 'normal',
    letterSpacing: '0.4px',
    textAlign: 'right',
    '&:focus': {
      outline: 'none',
      border: '1px solid #118CFF'
    },
    '&::placeholder': {
      color: '#B3BFCC'
    }
  },
  '& > #userInputNumber': {
    width: '92px',
    height: '22px',
    padding: '4px',
    paddingLeft: '8px',
    paddingRight: '8px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #E0E6EB',
    borderRadius: '6px',
    fontFamily: 'Inter',
    fontSize: '9.5px',
    fontWeight: 400,
    fontStyle: 'normal',
    letterSpacing: '0.4px',
    textAlign: 'right',
    backgroundColor: '#E3F3FF',
    '&:focus': {
      outline: 'none',
      border: '1px solid #118CFF'
    },
    '&:disabled': {
      backgroundColor: '#E3F3FF',
      border: '1px solid #D6DFEB'
    },
    '&::placeholder': {
      color: '#B3BFCC'
    }
  },
  '& > #unitInputNumber': {
    width: '50px',
    height: '22px',
    padding: '4px',
    paddingLeft: '8px',
    paddingRight: '8px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #E0E6EB',
    borderRadius: '6px',
    fontFamily: 'Inter',
    fontSize: '9.5px',
    fontWeight: 400,
    fontStyle: 'normal',
    letterSpacing: '0.4px',
    '&:focus': {
      outline: 'none',
      border: '1px solid #118CFF'
    },
    '&::placeholder': {
      color: '#B3BFCC'
    }
  }
}));

export const InputDateComp = styled.div<Props>(props => ({
  border: props.isActive
    ? '1px dashed #189EFF'
    : props.mandatoryError
      ? '1px solid #DE294C'
      : '1px solid #E0E6EB',
  borderRadius: '6px',
  padding: '3px',
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
  backgroundColor: 'white',
  // marginBottom: '8px',
  cursor: 'pointer !important',
  alignItems: 'flex-start',
  width: '100%',
  '& > #selectInputDate': {
    height: '18px',
    width: '218px',
    padding: '4px',
    paddingLeft: '8px',
    paddingRight: '8px',
    background: '#E3F3FF',
    border: '1px solid #D6DFEB',
    borderRadius: '6px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > p': {
      color: '#B3BFCC',
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      fontStyle: 'normal',
      letterSpacing: '0.4px'
    }
  }
}));

export const InputCurrencyComp = styled.div<Props>(props => ({
  border: props.isActive
    ? '1px dashed #189EFF'
    : props.mandatoryError
      ? '1px solid #DE294C'
      : '1px solid #E0E6EB',
  borderRadius: '6px',
  padding: '3px',
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  backgroundColor: 'white',
  // marginBottom: '8px',
  cursor: 'pointer !important',
  '& > #selectInputCurrency': {
    width: 72,
    height: '22px',
    padding: '4px',
    paddingLeft: '8px',
    paddingRight: '8px',
    border: '1px solid #E0E6EB',
    borderRadius: '6px',
    cursor: 'default',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > p': {
      color: '#B3BFCC',
      fontFamily: 'Inter',
      fontSize: '9.5px',
      fontWeight: 400,
      fontStyle: 'normal',
      letterSpacing: '0.4px',
      width: '100%',
      textAlign: 'right'
    }
  },
  '& > #userInputCurrency': {
    width: '92px',
    height: '22px',
    padding: '4px',
    paddingLeft: '8px',
    paddingRight: '8px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #E0E6EB',
    borderRadius: '6px',
    fontFamily: 'Inter',
    fontSize: '9.5px',
    fontWeight: 400,
    fontStyle: 'normal',
    letterSpacing: '0.4px',
    textAlign: 'right',
    backgroundColor: '#E3F3FF',
    '&:focus': {
      outline: 'none',
      border: '1px solid #118CFF'
    },
    '&:disabled': {
      backgroundColor: '#E3F3FF',
      border: '1px solid #D6DFEB'
    },
    '&::placeholder': {
      color: '#B3BFCC'
    }
  }
}));

export const FormulaComp = styled.div<Props>(props => ({
  border: props.isActive
    ? '1px dashed #189EFF'
    : props.mandatoryError
      ? '1px solid #DE294C'
      : '1px solid #E0E6EB',
  padding: '3px',
  borderRadius: '6px',
  backgroundColor: 'white',
  width: '100%',
  cursor: 'pointer !important',
  '& > .formulaRowWrapper': {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    '& > .formulaColumnWrapper': {
      display: 'flex',
      flexDirection: 'row',
      gap: '4px',
      alignItems: 'flex-start',
      '& > #userFormula': {
        width: '142px',
        height: '18px',
        padding: '4px',
        paddingLeft: '8px',
        paddingRight: '8px',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #E0E6EB',
        borderRadius: '6px',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 400,
        fontStyle: 'normal',
        letterSpacing: '0.4px',
        textAlign: 'right',
        '&:focus': {
          outline: 'none',
          border: '1px solid #118CFF'
        },
        '&:disabled': {
          backgroundColor: '#F0F2F5'
        },
        '&::placeholder': {
          color: '#B3BFCC'
        }
      }
    }
  }
}));

export const TableComp = styled.div<Props>(props => ({
  display: 'flex',
  border: props.isActive
    ? '1px dashed #189EFF'
    : props.mandatoryError
      ? '1px solid #DE294C'
      : '1px solid #E0E6EB',
  borderRadius: '6px',
  padding: '3px',
  backgroundColor: 'white',
  width: '100%',
  // marginBottom: '8px',
  cursor: 'pointer !important',
  '& > .rowTableWrapper': {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: '4px',
    '& > .descriptionTableWrapper': {
      display: 'flex',
      gap: '4px',
      alignItems: 'flex-start'
    },
    '& > .tableTableWrapper': {
      display: 'flex',
      minHeight: '200px',
      width: '624px',
      overflow: 'auto',
      border: props.tableError ? '2px solid #DE294C' : '',
      zIndex: 0
    },
    '& > .expandTableWrapper': {
      display: 'flex',
      justifyContent: 'flex-start',
      '& > div': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px',
        height: '20px',
        cursor: 'pointer',
        '& > p': {
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '12px',
          letterSpacing: '0.4px',
          color: '#118CFF'
        }
      }
    }
  }
}));

export const TextComp = styled.div<Props>(props => ({
  minHeight: '32px',
  display: 'flex',
  alignItems: 'center',
  border: props.isActive
    ? '1px dashed #189EFF'
    : props.mandatoryError
      ? '1px solid #DE294C'
      : '1px solid #E0E6EB',
  borderRadius: '6px',
  padding: '3px',
  backgroundColor: 'white',
  // marginBottom: '8px',
  cursor: 'pointer !important',
  width: '100%'
  // '& > #textText': {
  //   justifyContent: 'center',
  //   overflow: 'hidden',
  //   flex: 1,
  //   height: '20px',
  //   border: 'none',
  //   fontFamily: 'Inter',
  //   fontWeight: props.fontSize === '12px' ? 400 : 'bold',
  //   fontSize: props.fontSize,
  //   letterSpacing: '0.15px',
  //   resize: 'none',
  //   '&::placeholder': {
  //     color: '#B3BFCC',
  //     fontWeight: props.fontSize === '12px' ? 400 : 'bold',
  //   },
  //   '&:focus': {
  //     outline: 'none',
  //   },
  // }
}));

export const DividerComp = styled.div<Props>(props => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '32px',
  border: props.isActive ? '1px dashed #189EFF' : '1px solid #E0E6EB',
  borderRadius: '6px',
  padding: '3px',
  backgroundColor: 'white',
  // marginBottom: '8px',
  cursor: 'pointer !important',
  '& > .lineDivider': {
    display: 'flex',
    flex: 1,
    borderBottom: '1px solid #D6DFEB'
  },
  width: '100%'
}));

export const ImageComp = styled.div<Props>(props => ({
  display: 'flex',
  border: props.isActive
    ? '1px dashed #189EFF'
    : props.mandatoryError
      ? '1px dashed #DE294C'
      : '1px solid #E0E6EB',
  padding: '4px',
  borderRadius: '6px',
  backgroundColor: 'white',
  // marginBottom: '8px',
  cursor: 'pointer !important',
  width: '100%',
  '& > #imageRowWrapper': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: '4px',
    '& > .imageDescriptionWrapper': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '26px',
      // padding: '4px 0',
      border: '1px solid #E0E6EB',
      borderRadius: '6px',
      '& > .imageWrapperIcon': {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        letterSpacing: '0.4px',
        color: '#A3B3C2',
        background: '#F0F2F5',
        height: '100%',
        padding: '0px 8px',
        width: 56,
        justifyContent: 'center'
      },
      '& > input': {
        height: '16px',
        flex: 1,
        padding: '4px 8px',
        border: 'none',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        letterSpacing: '0.4px',
        '&:focus': {
          outline: 'none'
        },
        '&::placeholder': {
          color: '#B3BFCC'
        }
      }
    },
    '& > #imageInsideWrapper': {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #E0E6EB',
      borderRadius: '6px',
      minHeight: '195px',
      overflow: 'hidden',
      background: '#F0F2F5',
      cursor: 'default !important',
      position: 'relative',
      '& > .imageClose': {
        position: 'absolute',
        top: 10,
        right: 10,
        zIndex: 9
      },
      '& > #imageHaveImage': {
        display: 'flex',
        justifyContent:
          props.alignment === 'CENTER'
            ? 'center'
            : props.alignment === 'LEFT'
              ? 'left'
              : 'right',
        alignItems: 'center',
        width: '100%',
        position: 'relative',
        '& > .imageClose': {
          position: 'absolute',
          top: 10,
          right: 10,
          zIndex: 9
        },
        '& > #imageShow': {
          width: props.imageWidth,
          height: props.imageHeight
        }
      },
      '& > #imageNoImageTopIndicator': {
        padding: 12,
        borderRadius: '50%',
        backgroundColor: '#FFF',
        cursor: 'pointer'
      },
      '& > #imageButton': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '120px',
        height: '32px',
        fontFamily: 'Inter',
        fontSize: '12px',
        letterSpacing: '0.4px',
        fontWeight: 'bold',
        // backgroundColor: '#118CFF',
        color: '#118CFF',
        borderRadius: '6px',
        cursor: 'pointer'
      }
    },
    '& > #imageCaption': {
      borderRadius: '6px',
      border: '1px solid #E0E6EB',
      height: '30px',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '9.5px',
      letterSpacing: '0.4px',
      fontWeight: 400,
      '&:focus': {
        outline: 'none',
        border: '1px solid #118CFF'
      },
      '&::placeholder': {
        color: '#B3BFCC'
      }
    }
  }
}));

//////////// MODAL COMPONENT

export const TableBox = styled.div({
  display: 'flex',
  height: '100vh',
  width: '100vw',
  alignItems: 'center',
  justifyContent: 'center'
});

export const TableBoxWrapper = styled.div({
  display: 'block',
  backgroundColor: '#FFFFFF'
});

////////////// CONTENT

export const TableContentSection = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start'
});

////////////// LEFT CONTENT

export const TableLeftContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '1004px'
});

export const TableFormula = styled.div({
  display: 'flex',
  flexDirection: 'row',
  height: '32px'
});

export const TableMain = styledMUI('div')(({ theme }) => ({
  [theme.breakpoints.down('desktop')]: {
    height: '512px'
  },
  [theme.breakpoints.up('desktop')]: {
    height: '712px'
  },
  display: 'flex',
  width: '1004px'
}));

export const TableLeftFormula = styled.div({
  display: 'flex',
  height: '16px',
  width: '64px',
  padding: '8px',
  backgroundColor: '#F0F2F5',
  boxShadow: 'inset -1px 0px 0px #E0E6EB',
  '& > p': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '10px',
    letterSpacing: '1.5px'
  }
});

export const TableRightFormula = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flex: 1,
  gap: '8px',
  height: '24px',
  padding: '4px 8px',
  backgroundColor: '#F0F2F5',
  boxShadow: 'inset -1px 0px 0px #E0E6EB',
  '& > .tableFormula': {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    padding: '4px 8px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #E0E6EB',
    borderRadius: '6px',
    '& > input': {
      display: 'flex',
      alignItems: 'center',
      height: '16px',
      width: '100%',
      border: 'none',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      letterSpacing: '0.4px',
      '&:focus': {
        outline: 'none'
      }
    }
  }
});

////////////// RIGHT CONTENT

export const TableRightContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '236px',
  boxShadow: 'inset 0px -1px 0px #E0E6EB',
  maxHeight: 585
});

export const TableRightWrapperContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px'
});

////////////// BUTTON

export const TableButtonSection = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '8px',
  height: '40px',
  padding: '12px 8px',
  boxShadow: 'inset -1px 0px 0px #E0E6EB, inset 0px -1px 0px #E0E6EB',
  '& > .saveAndCloseTable': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px',
    backgroundColor: '#118CFF',
    borderRadius: '6px',
    cursor: 'pointer',
    '& > p': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontSize: '12px',
      letterSpacing: '0.4px',
      color: '#FFFFFF'
    }
  },
  '& > .closeTable': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px',
    backgroundColor: '#F0F2F5',
    borderRadius: '6px',
    cursor: 'pointer',
    '& > p': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontSize: '12px',
      letterSpacing: '0.4px'
    }
  }
});

//////////// MENU COMPONENT

export const PageBox = styled.div({
  outline: 'none',
  borderRadius: '6px',
  maxHeight: '240px',
  overflowY: 'auto'
});

export const PageItem = styled.div({
  display: 'flex',
  padding: '12px 16px',
  alignItems: 'center',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 'normal',
  letterSpacing: '0.4px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#E3F3FF'
  }
});

export const OptionBox = styled.div({
  width: '180px',
  outline: 'none',
  padding: '8px',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '8px',
    height: '32px',
    fontFamily: 'Inter',
    fontSize: '9.5px',
    fontWeight: 'normal',
    letterSpacing: '0.4px',
    color: '#030B42'
  }
});

export const MenuBox = styled.div({
  width: '140px',
  outline: 'none',
  borderRadius: '6px',
  paddingLeft: '24px',
  paddingRight: '24px',
  paddingBottom: '8px',
  paddingTop: '8px',
  maxHeight: '240px',
  overflowY: 'auto',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 'normal',
    letterSpacing: '0.4px',
    cursor: 'pointer'
  }
});

// RIGHT CONTENT

//// HEAD AT PROPERTIES CONTENT

export const Properties = styled.div({
  position: 'fixed',
  zIndex: 1,
  right: 0,
  boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.12)',
  width: '188px',
  height: 'calc(100vh - 120px)',
  overflowY: 'auto',
  backgroundColor: '#FFFFFF',
  padding: '24px',
  borderTopLeftRadius: '4px',
  borderBottomLeftRadius: '4px',
  gap: '24px',
  display: 'flex',
  flexDirection: 'column'
});

export const HeaderProperties = styled.div({
  color: '#9A9DB3',
  fontFamily: 'Inter',
  fontWeight: 600,
  fontSize: '10px',
  lineHeight: '16px',
  letterSpacing: '1.5px',
  textTransform: 'uppercase'
});

export const SubHeaderProperties = styled.div({
  fontFamily: 'Inter',
  fontWeight: 'bold',
  fontSize: '14px',
  lineHeight: '17px',
  letterSpacing: '0.75px',
  textTransform: 'uppercase',
  flex: 1
});

//// BODY AT PROPERTIES CONTENT

export const TypeProperties = styled.div<Props>(({ isDisabled }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  '& > #typePropertiesHead': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > p': {
      color: '#9A9DB3',
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px'
    },
    '& > img': {
      cursor: 'pointer'
    }
  },
  '& > #typePropertiesBody': {
    height: '26px',
    padding: '4px',
    paddingLeft: '8px',
    paddingRight: '8px',
    border: '1px solid #E0E6EB',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: isDisabled ? '#F0F2F5' : '',
    cursor: isDisabled ? '' : 'pointer',
    '& > p': {
      color: isDisabled ? '#B3BFCC' : '#29333D',
      fontFamily: 'Inter',
      fontSize: '12px',
      letterSpacing: '0.4px',
      fontWeight: 400
    }
  }
}));

export const SmallProperties = styled.div<Props>(
  ({ mandatoryError, formulaError }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '& > #smallPropertiesHead': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '& > #labelSmallProperties': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        '& > #textLabelSmallProperties': {
          color: '#9A9DB3',
          fontFamily: 'Inter',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '16px',
          letterSpacing: '0.4px'
        },
        '& > #starLabelSmallProperties': {
          color: '#DE294C',
          fontFamily: 'Inter',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '16px',
          letterSpacing: '0.4px'
        }
      },
      '& > img': {
        cursor: 'pointer'
      }
    },
    '& > input': {
      height: '26px',
      padding: '4px',
      paddingLeft: '8px',
      paddingRight: '8px',
      display: 'flex',
      alignItems: 'center',
      border: mandatoryError ? '1px solid #DE294C' : '1px solid #E0E6EB',
      borderRadius: '6px',
      fontFamily: 'Inter',
      fontSize: '12px',
      letterSpacing: '0.4px',
      fontWeight: 400,
      '&:focus': {
        outline: 'none',
        border: mandatoryError ? '1px solid #D51F3C' : '1px solid #118CFF'
      },
      '&:disabled': {
        backgroundColor: '#F0F2F5'
      }
    },
    '& > #smallPropertiesMandatoryError': {
      color: '#DE294C',
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px'
    },
    '& > #formulaSmallProperties': {
      border: formulaError ? '1px solid #DE294C' : '1px solid #E0E6EB',
      '&:focus': {
        outline: 'none',
        border: formulaError ? '1px solid #D51F3C' : '1px solid #118CFF'
      }
    },
    '& > #formulaErrorSmallProperties': {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: '12px',
      color: '#DE294C'
    }
  })
);

export const BigProperties = styled.div<Props>(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  '& > #bigPropertiesHead': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > #labelBigProperties': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      '& > #textLabelBigProperties': {
        color: '#9A9DB3',
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.4px'
      },
      '& > #starLabelBigProperties': {
        color: '#DE294C',
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.4px'
      }
    },
    '& > img': {
      cursor: 'pointer'
    }
  },
  '& > textarea': {
    resize: 'vertical',
    height: '72px',
    padding: '8px',
    paddingLeft: '8px',
    paddingRight: '8px',
    display: 'flex',
    alignItems: 'center',
    border: props.mandatoryError ? '1px solid #DE294C' : '1px solid #E0E6EB',
    borderRadius: '6px',
    fontFamily: 'Inter',
    fontSize: '12px',
    letterSpacing: '0.4px',
    fontWeight: 400,
    '&:focus': {
      outline: 'none',
      border: '1px solid #118CFF'
    }
  },
  '& > #bigPropertiesMandatoryError': {
    color: '#DE294C',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px'
  }
}));

export const SummaryProperties = styled.div({
  display: 'flex',
  height: '20px',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
  '& > p': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    userSelect: 'none'
  },
  '& > img': {
    userSelect: 'none',
    marginRight: '8px'
  }
});

export const OptionProperties = styled.div<Props>(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  '& > #optionPropertiesHead': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > #labelOptionProperties': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      '& > #textLabelOptionProperties': {
        color: '#9A9DB3',
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.4px'
      },
      '& > #starLabelOptionProperties': {
        color: '#DE294C',
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.4px'
      }
    },
    '& > img': {
      cursor: 'pointer'
    }
  },
  '& > #optionPropertiesBody': {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    gap: '10px',
    border: props.mandatoryError ? '1px solid #DE294C' : '1px solid #E0E6EB',
    borderRadius: '6px',
    '& > div': {
      backgroundColor: '#E3F3FF',
      borderRadius: '6px',
      height: '24px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '4px',
      paddingLeft: '8px',
      paddingRight: '8px',
      '& > #optionPropertiesNoPlaceHolder': {
        fontFamily: 'Inter',
        fontSize: '12px',
        letterSpacing: '0.4px'
      },
      '& > #optionPropertiesPlaceHolder': {
        color: '#B3BFCC',
        fontFamily: 'Inter',
        fontSize: '12px',
        letterSpacing: '0.4px'
      },
      '& > img': {
        cursor: 'pointer'
      }
    },
    '& > input': {
      border: 'none',
      fontFamily: 'Inter',
      fontSize: '12px',
      letterSpacing: '0.4px',
      '&:focus': {
        outline: 'none'
      }
    }
  },
  '& > #optionPropertiesMandatoryError': {
    color: '#DE294C',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px'
  }
}));

export const FormulaProperties = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  '& > #formulaPropertiesHead': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '16px',
    '& > p': {
      color: '#9A9DB3',
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: '12px',
      letterSpacing: '0.4px'
    },
    '& > img': {
      cursor: 'pointer'
    }
  },
  '& > #formulaPropertiesBody': {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    '& > div': {
      flex: 1,
      height: '14px',
      padding: '8px',
      border: '1px solid #E0E6EB',
      borderRadius: '6px',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      '& > p': {
        userSelect: 'none',
        color: '#9A9DB3',
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.4px'
      },
      '& > img': {
        userSelect: 'none',
        marginRight: '8px'
      }
    }
  }
});

export const ImageProperties = styled.div<Props>(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  '& > #imagePropertiesHead': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > p': {
      color: '#9A9DB3',
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px'
    },
    '& > img': {
      cursor: 'pointer'
    }
  },
  '& > #imagePropertiesBody': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > #imageSizeContainer': {
      flex: 1,
      padding: '8px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: '1px solid #E0E6EB',
      borderRadius: '6px',
      height: '18px',
      backgroundColor: props.isDisabled ? '#F0F2F5' : '#FFFFFF',
      '& > p': {
        color: '#9A9DB3',
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.4px'
      },
      '& > input': {
        width: '40px',
        color: '#9A9DB3',
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.4px',
        border: 'none',
        textAlign: 'right',
        '&:focus': {
          outline: 'none'
        }
      }
    },
    '& > #imageImageContainer': {
      cursor: 'pointer',
      '& > img': {
        marginLeft: '16px',
        marginRight: '16px'
      }
    }
  }
}));

export const ContainerSubHeaderToggle = styled.div({
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer'
});

export const InputField = styled.input<InputFieldProps>(props => ({
  fontFamily: 'Inter',
  fontSize: 12,
  fontWeight: 'normal',
  padding: '4px 8px',
  minHeight: 18,
  lineHeight: 1.5,
  width: props.component === 'inputValue' ? 142 : 54,
  textAlign: 'left',
  border: props.error ? '1px solid #DE294C' : '1px solid #E0E6EB',
  borderRadius: 6,
  '&:focus': {
    outline: 'none',
    border: '1px solid #118CFF'
  },
  '&::placeholder': {
    color: '#B3BFCC'
  },
  backgroundColor: props.component === 'inputValue' ? '#E3F3FF' : 'inherit'
}));

export const TextSubMenuField = styled.p({
  textTransform: 'capitalize'
});
