import { CALIDNAME, CALNAME, UPDATE_CAL_INFO } from '../types/calInfoTypes';

import { Dispatch } from 'redux';
import Service from '../../service/generic/generic';
import { setCalculations } from './calculationsActions';

const service = new Service('');

export function calIdAction(
  calId: string,
  calName: string,
  calInputFiles: number,
  calApproval: number,
  calDescription: string,
  id: string
) {
  return {
    type: CALIDNAME,
    calId,
    calName,
    calInputFiles,
    calApproval,
    calDescription,
    id
  };
}

export function calNameAction(calName: string) {
  return { type: CALNAME, calName };
}

export function calInfoRename(calInfo: any, payload: any) {
  return async (dispatch: Dispatch) => {
    try {
      await service.putSubResource(
        'calculations',
        calInfo.calId,
        'info',
        payload
      );
      dispatch(calNameAction(payload.title));
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function calInfoDelete(calId: string) {
  return async () => {
    try {
      await service.deleteSubResource('calculations', 'softdelete', calId);
      return 'Success Delete';
    } catch (error) {
      return error;
    }
  };
}

export function calInfoMultipleDelete(ids: any) {
  const serviceDelete = new Service('/calculations');
  return async () => {
    try {
      return await serviceDelete.deleteBulk(ids);
    } catch (error) {
      return error;
    }
  };
}

export function updateCalInfo(data: any) {
  return { type: UPDATE_CAL_INFO, data };
}

export function fetchCalculationInfo(calId: string) {
  return async (dispatch: Dispatch) => {
    try {
      const res: any = await service.getSubresource(
        `calculations/${calId}`,
        'info'
      );
      dispatch(setCalculations(res.data));
    } catch (error) {
      return error;
    }
  };
}
