import BootstrapTooltip from '../Tooltip';
import TooltipIcon from '../tooltipIcon';

import { VMTitleLink, VMTitleText, VMTitleWrapper } from './styles';

import { NavigatingTooltipPropsI } from './types';

const NavigatingTooltip = ({
  id,
  pageIndex,
  navigateClick
}: NavigatingTooltipPropsI) => {
  const Title = () => {
    return (
      <VMTitleWrapper>
        <VMTitleText>{pageIndex ? pageIndex : 'ERROR'}</VMTitleText>
        <VMTitleLink onClick={() => navigateClick(id)}>
          Go to Component
        </VMTitleLink>
      </VMTitleWrapper>
    );
  };

  return (
    <BootstrapTooltip placement="bottom" title={<Title />}>
      <TooltipIcon symbol="?" />
    </BootstrapTooltip>
  );
};

export default NavigatingTooltip;
