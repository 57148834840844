import { InputProps } from '../../logger.types';
import { RadioGroup, Typography } from '@mui/material';
import { useController } from 'react-hook-form';
import parser from 'html-react-parser';
import {
  Break,
  // DescriptionBox,
  DescriptionBoxField,
  InputWrapper
  // NotationBox
} from './styles';
// import { theme } from '../../../../utils/theme';
import StyledRadioButton from '../../../../components/atoms/RadioButton';

function RadioField({ name, control, properties, defaultValue }: InputProps) {
  const {
    field: { onChange, onBlur, value }
    // fieldState: { error }
  } = useController({
    control,
    name,
    defaultValue
  });

  return (
    <>
      <InputWrapper>
        <DescriptionBoxField contentEditable={false}>
          <Typography variant="body3" component={'span'}>
            {properties.description
              ? parser(properties.description)
              : properties.description}
          </Typography>
        </DescriptionBoxField>
        <Break />

        <RadioGroup
          onChange={onChange}
          onBlur={onBlur}
          value={value ?? ''}
          name={name}
        >
          {properties?.options?.map((item: any, idx: number) => (
            <StyledRadioButton
              key={`radio-button-menu-${idx}`}
              value={item}
              label={parser(item)}
              size="small"
            />
          ))}
        </RadioGroup>
      </InputWrapper>
    </>
  );
}

export default RadioField;
