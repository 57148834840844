import { Autocomplete } from '@mui/material';
import styled from 'styled-components';
import { withStyles } from '@mui/styles';

interface Props {
  error?: any;
  align?: string;
  disabled?: boolean;
  upSize?: boolean;
  preview?: boolean;
  isDrive?: boolean;
  upgrade?: boolean;
  copyToDrives?: boolean;
}

// MAIN CONTAINER

export const FlyingContainer = styled.div({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  outline: 'none'
});

export const Container = styled('div')<Props>(props => ({
  width: props.upSize
    ? '538px'
    : props.preview
      ? '764px'
      : props.isDrive
        ? '408px'
        : props.upgrade
          ? '1440px'
          : props.copyToDrives
            ? '336px'
            : '346px',
  display: 'flex',
  flexDirection: 'column',
  padding: props.preview ? '40px 32px 24px' : '40px 32px 32px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
  boxShadow: '0px 3px 6px rgba(120, 138, 155, 0.25)'
}));

export const Form = styled('form')({
  padding: 0
});

//// MISC

export const XButton = styled.div({
  position: 'absolute',
  top: '0px',
  right: '0px',
  marginTop: '16px',
  marginRight: '16px',
  cursor: 'pointer'
});

//// INSIDE CONTAINER

export const Head = styled.div<Props>(props => ({
  display: 'flex',
  alignItems: 'center',
  lineHeight: '32px',
  fontSize: '20px',
  fontWeight: 800,
  letterSpacing: '0.15px',
  marginBottom: '16px',
  alignSelf: props.align === 'center' ? 'center' : 'flex-start'
}));

export const Body = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // marginBottom: "12px",
  padding: '40px 0'
});

export const Foot = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '8px',
  '& > .cancelButtonFormModal': {
    width: '160px',
    height: '32px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#F0F2F5',
    fontFamily: 'Inter',
    fontWeight: 800,
    fontSize: '12px',
    letterSpacing: '0.4px',
    cursor: 'pointer'
  },
  '& > .submitButtonFormModal': {
    width: '160px',
    height: '32px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#118CFF',
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontWeight: 800,
    fontSize: '12px',
    letterSpacing: '0.4px',
    cursor: 'pointer'
  },

  '& > .submitButtonDangerFormModal': {
    width: '160px',
    height: '32px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#DE294C',
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontWeight: 800,
    fontSize: '12px',
    letterSpacing: '0.4px',
    cursor: 'pointer'
  },
  '& > .cancelButtonSecondaryFormModal': {
    width: '160px',
    height: '32px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    color: '#118CFF',
    fontFamily: 'Inter',
    fontWeight: 800,
    fontSize: '12px',
    letterSpacing: '0.4px',
    cursor: 'pointer',
    borderColor: '#118CFF'
  }
});

////// INSIDE BODY

//////// INPUT TEXT

export const LabelInputText = styled.div<Props>(props => ({
  marginBottom: '4px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  color: '#686D8E',
  textAlign: props.align === 'center' ? 'center' : 'start'
}));

export const InputText = styled.input<Props>(props => ({
  height: '36px',
  border: props.error ? '1px solid #DE294C' : '1px solid #E0E6EB',
  borderRadius: '4px',
  marginBottom: props.error ? '0px' : '16px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  paddingLeft: '10px',
  paddingRight: '10px',
  letterSpacing: '0.4px',
  fontSize: '12px',
  '&:focus': {
    outline: 'none',
    border: props.error ? '1px solid #DE294C' : '1px solid #118CFF'
  },
  '&:disabled': {
    backgroundColor: '#F0F2F5'
  },
  '&::placeholder': {
    color: '#B3BFCC',
    letterSpacing: '0.4px'
  }
}));

export const ErrorInputText = styled.div({
  marginTop: '4px',
  marginBottom: '16px',
  width: '100%',
  color: '#DE294C',
  fontSize: '10px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400
});

//////// LABEL

export const Label = styled.div<Props>(props => ({
  marginBottom: '16px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '21px',
  color: '#686D8E',
  textAlign: props.align === 'center' ? 'center' : 'start',
  ...props.style
}));

//////// INPUT SELECT

export const InputSelect = styled.div<Props>(props => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: '10px',
  paddingBottom: '10px',
  paddingRight: '12px',
  paddingLeft: '12px',
  border: props.error ? '1px solid #DE294C' : '1px solid #E0E6EB',
  borderRadius: '4px',
  cursor: 'pointer',
  backgroundColor: props.disabled ? '#F0F2F5' : '#ffffff',
  marginBottom: '4px',
  '& > .defaultValueSelect': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#B3BFCC'
  },
  '& > .valueSelect': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px'
  }
}));

export const OptionBox = styled.div({
  width: '285px',
  paddingTop: '8px',
  paddingBottom: '8px',
  paddingRight: '8px',
  paddingLeft: '8px',
  backgroundColor: '#FFFFFF',
  borderRadius: '4px',
  maxHeight: '224px',
  overflowY: 'auto',
  '& > .optionValue': {
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingRight: '16px',
    paddingLeft: '16px',
    borderRadius: '4px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E3F3FF'
    },

    '& > img': {
      paddingRight: 10
    }
  },
  '& > .createNewInputFile': {
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingRight: '16px',
    paddingLeft: '16px',
    borderRadius: '4px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#118CFF',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E3F3FF'
    }
  },

  '& > .loadNewInputFIle': {
    backgroundColor: 'rgb(249, 249, 249)'
  }
});
//////// BIG PATH

export const BigPath = styled.div<Props>(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: '10px',
  paddingBottom: '10px',
  paddingRight: '12px',
  paddingLeft: '12px',
  border: '1px solid #E0E6EB',
  borderRadius: '4px',
  cursor: 'pointer',
  '& > .defaultValueSelect': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#B3BFCC'
  },
  '& > .valueSelect': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px'
  }
}));

export const BigPathBox = styled.div({
  width: '299px',
  backgroundColor: '#FFFFFF',
  borderRadius: '4px',
  alignItems: 'flex-start',
  border: '1px solid #E0E6EB',
  '& > .headBigPathBox': {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '40px',
    backgroundColor: '#F9F9F9',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    '& > .leftHeadBigPathBox': {
      width: '40px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: 'inset -1px 0px 0px #E0E6EB',
      '& > .imgLeftHeadBigPathBox': {
        cursor: 'pointer'
      }
    },
    '& > .rightHeadBigPathBox': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '& > p': {
        marginLeft: '16px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        letterSpacing: '0.25px',
        inlineSize: '259px',
        overflowWrap: 'break-word'
      }
    }
  },
  '& > .bodyBigPathBox': {
    padding: '8px',
    borderTop: '1px solid #E0E6EB',
    borderBottom: '1px solid #E0E6EB',
    maxHeight: '200px',
    overflowY: 'auto',
    '& > .contentBodyBigPathBox': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '8px',
      borderRadius: '4px',
      cursor: 'pointer',
      '& > p': {
        marginLeft: '16px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 400,
        letterSpacing: '0.44px'
      },
      '&:hover': {
        backgroundColor: '#E3F3FF'
      }
    },
    '& > .noDataBodyBigPathBox': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontSize: '16px',
      fontWeight: 400,
      letterSpacing: '0.44px'
    }
  },
  '& > .footBigPathBox': {
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    '& > .cancelFootBigPathBox': {
      height: '32px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      borderRadius: '4px',
      backgroundColor: '#F0F2F5',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '12px',
      letterSpacing: '0.4px',
      userSelect: 'none',
      cursor: 'pointer'
    },
    '& > .selectFootBigPathBox': {
      height: '32px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      borderRadius: '4px',
      backgroundColor: '#118CFF',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '12px',
      letterSpacing: '0.4px',
      color: '#FFFFFF',
      userSelect: 'none',
      cursor: 'pointer'
    }
  }
});

export const NoPaddingAutocomplete = withStyles({
  inputRoot: {
    '&&[class*="MuiOutlinedInput-root"] $input': {
      padding: 1
    },
    // marginBottom: "16px",
    height: '36px',
    borderRadius: '4px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    paddingLeft: '10px',
    paddingRight: '10px',
    letterSpacing: '0.4px',
    fontSize: '12px',

    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #118CFF',
      color: 'red'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #E0E6EB'
      // borderColor: "#",
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #E0E6EB'
    }
  },

  input: {}
})(Autocomplete);

export const PreviewContainer = styled.div({
  width: '764px',
  flexDirection: 'row',
  display: 'flex'
});

export const ImagePreview = styled.div({
  border: '1px solid #E3F3FF',
  width: '380px',
  height: '542px',
  display: 'flex',
  borderRadius: '6px',
  '& > #imagePreview': {
    width: '380px',
    height: 'auto',
    borderRadius: '6px',
    objectFit: 'cover',
    objectPosition: 'top'
  }
});
export const PreviewDesc = styled.div({
  display: 'flex',
  width: '352px',
  height: '566px',
  flexDirection: 'column',
  marginLeft: '40px',
  '& > #originator': {
    marginTop: '24px',
    flexDirection: 'column'
  }
});

export const InformationWrapper = styled.div({
  display: 'flex',
  marginTop: 16,
  flexDirection: 'column',
  '& > #line': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start'
  }
});
