import { MenuList as List, MenuListProps, styled } from '@mui/material';

export const StyledMenuList = styled(List)<MenuListProps>(() => ({
  padding: 0,
  minWidth: 192,
  maxWidth: 'calc(100% - 32px)',
  '.MuiList-': {
    padding: 0
  }
}));

export const InlineMenuItem = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center'
});
