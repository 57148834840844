import { CstNode, tokenMatcher } from 'chevrotain';
import {
  mapExpression,
  mapFunction,
  mapMathOperandExpression,
  mapPercentage
} from './mapper';
import { TokenTypeDict } from './lexer';
import { FormulaParser } from './parser';

export interface IVisitor {
  visit(cst: CstNode, state?: any): any;
}

export function createEvalVisitor(
  parser: FormulaParser,
  dict: TokenTypeDict
): IVisitor {
  const FormulaVisitorBase = parser.getBaseCstVisitorConstructorWithDefaults();

  class InterpreterVisitor extends FormulaVisitorBase {
    constructor() {
      super();
      this.validateVisitor();
    }

    expression(ctx: any, state: any): any {
      if (ctx.additionExpression)
        return this.visit(ctx.additionExpression, state);
      if (ctx.functionExpression)
        return this.visit(ctx.functionExpression, state);
    }

    additionExpression(ctx: any, state: any): any {
      let found = '';
      let lhs = this.visit(ctx.lhs, state);
      if (!ctx.rhs) return lhs;
      ctx.rhs.forEach((operand: any, idx: number) => {
        const operator = ctx.operator[idx].image;
        const rhs = this.visit(operand);
        const mapped = mapExpression({ lhs, operator, rhs });
        found += mapped;
        lhs = '';
      });

      return found;
    }

    multiplicationExpression(ctx: any, state: any): any {
      let found = '';
      let lhs = this.visit(ctx.lhs, state);
      if (!ctx.rhs) return lhs;
      ctx.rhs.forEach((operand: any, idx: number) => {
        let operator = ctx.operator[idx].image;
        const rhs = this.visit(operand);

        const isDiv = tokenMatcher(ctx.operator[idx], dict.Div);
        if (isDiv && lhs.length === 0) {
          lhs = found;
          found = '';
        }
        if (state?.isInParentheses && operator === '*') {
          operator = '*p';
        }
        const mapped = mapExpression({ lhs, operator, rhs });
        found += mapped;
        lhs = '';
      });

      return found;
    }

    exponentiationExpression(ctx: any, state: any): any {
      let found = '';
      let lhs = this.visit(ctx.lhs, state);

      if (!ctx.rhs) return lhs;

      ctx.rhs.forEach((operand: any, idx: number) => {
        const operator = ctx.operator[idx].image;
        const rhs = this.visit(operand);

        const mapped = mapExpression({ lhs, operator, rhs });
        found += mapped;
        lhs = '';
      });

      return found;
    }

    functionExpression(ctx: any, state: any): any {
      const name: any = ctx.functionName[0].image;
      let operator: any;
      let args: any[] = [];

      if (ctx.CommaColon) {
        operator = ctx.CommaColon[0].image;
      }

      if (ctx.arguments) {
        args = ctx.arguments.map((arg: any) => this.visit(arg, state));
      }

      return mapFunction({ name, args, operator });
    }

    parenthesisExpression(ctx: any, state: any): any {
      const newState = {
        ...state,
        isInParentheses: true
      };
      return '(' + this.visit(ctx.additionExpression, newState) + ')';
    }

    mathOperandExpression(ctx: any, state: any): any {
      let operator: any;
      let args: any[] = [];
      let ext: any;
      let math: any;

      if (ctx.CommaColon) {
        operator = ctx.CommaColon[0].image;
      }

      if (ctx.arguments) {
        args = ctx.arguments.map((arg: any) => this.visit(arg, state));
      }

      if (ctx.Trigonometry) {
        math = ctx.Trigonometry[0].image;
      } else if (ctx.Degrees) {
        math = ctx.Degrees[0].image;
      } else if (ctx.Radians) {
        math = ctx.Radians[0].image;
      } else if (ctx.Exponential) {
        math = ctx.Exponential[0].image;
      } else if (ctx.Rounding) {
        math = ctx.Rounding[0].image;
      } else if (ctx.LogNatural) {
        math = ctx.LogNatural[0].image;
      } else if (ctx.LogNumeric) {
        math = 'LOGNUM';
        ext = ctx.LogNumeric[0].image.substring(3);
      } else if (ctx.Log) {
        math = ctx.Log[0].image;
      } else if (ctx.Modulus) {
        math = ctx.Modulus[0].image;
      } else if (ctx.Cript) {
        math = ctx.Cript[0].image;
      }

      return mapMathOperandExpression({ math, args, operator, ext });
    }

    operandExpression(ctx: any): any {
      if (ctx.parenthesisExpression) {
        return this.visit(ctx.parenthesisExpression);
      }
      if (ctx.mathOperandExpression) {
        return this.visit(ctx.mathOperandExpression);
      }
      if (ctx.PI) {
        return ctx.PI[0].image[0] === '-' ? '-\\pi' : `\\pi`;
      }
      if (ctx.Constant) {
        return ctx.Constant[0].image;
      }
      if (ctx.PercentageNumber) {
        return ctx.PercentageNumber[0].image[0] === '-'
          ? '-' + mapPercentage(ctx.PercentageNumber[0].image, 'minus')
          : mapPercentage(ctx.PercentageNumber[0].image);
      }
      if (ctx.SuperNumber) {
        return ctx.SuperNumber[0].image;
      }
      if (ctx.PowerFunction) {
        return ctx.PowerFunction[0].Image;
      }
    }
  }

  return new InterpreterVisitor();
}
