import { Card as MuiCard, CardProps } from '@mui/material';
import { styled } from '@mui/material/styles';

interface CardPropsI extends CardProps {
  selected?: boolean;
  // content: React.ReactNode;
  // action?: React.ReactNode;
  handleClick?: (event: React.MouseEvent) => void;
  height?: number;
  width?: number;
  padding?: string;
  currentPlan?: boolean;
  colorBorder?: string;
  alignItems?: string;
}

const Card = styled(MuiCard)<CardPropsI>(
  ({
    height,
    width,
    selected,
    // action,
    theme,
    padding,
    // currentPlan,
    colorBorder,
    alignItems
  }) => ({
    height: height ?? '16.5rem',
    width: width ?? '16.5rem',
    borderRadius: '16px',
    boxSizing: 'border-box',
    backgroundColor: '#FFFFFF',
    textAlign: 'center',
    border: selected
      ? `2px solid ${theme.palette.primary.main}`
      : colorBorder
        ? `2px solid ${colorBorder}`
        : '2px solid transparent',
    padding: padding ?? '1.5rem',
    justifyContent: 'space-between',
    boxShadow: 'none',

    '& > .MuiCardContent-root': {
      display: 'flex',
      alignItems: alignItems ?? 'stretch',
      justifyContent: 'center'
    },

    '& > .MuiCardActions-root': {
      padding: '0'
    }
  })
);

export const StyledCardV2 = (props: CardPropsI) => {
  const { children, ...restProps } = props;
  return <Card {...restProps}>{children}</Card>;
};
