import * as React from "react";
import { SVGProps } from "react";

const SvgPython = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 10.281v3.438c-.01.023-.028.044-.032.068-.274 1.53-1.438 2.509-2.986 2.51h-2.685v.228c0 .852.004 1.703-.001 2.555a2.93 2.93 0 0 1-2.185 2.824c-.13.034-.262.064-.393.096h-3.437c-.023-.01-.044-.028-.068-.032-1.529-.273-2.51-1.439-2.51-2.986v-2.685h-.228c-.852 0-1.704.004-2.555-.001a2.932 2.932 0 0 1-2.824-2.185c-.035-.13-.064-.261-.096-.392v-3.438c.01-.022.027-.044.032-.068.273-1.529 1.437-2.508 2.986-2.51h2.686v-.228c0-.858-.005-1.716.001-2.575a2.936 2.936 0 0 1 2.203-2.81c.124-.032.249-.06.374-.09h3.437c.023.01.044.027.068.032 1.529.273 2.51 1.438 2.51 2.986v2.685h.228c.859 0 1.717-.004 2.575.002a2.923 2.923 0 0 1 2.786 2.129c.042.148.076.298.114.447ZM8.88 7.703h.23c.943 0 1.887.002 2.831 0 .275-.002.483.105.595.358.179.402-.122.813-.593.813-2.311.002-4.622 0-6.933.002-1.087 0-1.837.754-1.838 1.844-.001.86-.002 1.718 0 2.577.002 1.073.758 1.826 1.836 1.828h2.5c.062 0 .124-.006.195-.01V12.13c0-.5.214-.716.708-.716h6.71c0-2.219.014-4.405-.007-6.592-.008-.896-.79-1.633-1.691-1.646-.95-.013-1.9-.013-2.85 0-.884.014-1.653.728-1.685 1.61-.036.963-.008 1.926-.008 2.917Zm6.245 8.594h-.265c-.944 0-1.888.002-2.832-.001-.327 0-.572-.215-.61-.517a.586.586 0 0 1 .457-.637c.08-.019.168-.016.252-.016 2.291-.001 4.582 0 6.873-.002 1.065 0 1.824-.756 1.827-1.816.003-.872.003-1.744 0-2.616-.003-1.061-.762-1.815-1.827-1.817-.84-.002-1.679 0-2.519 0-.056 0-.113.006-.184.01v2.995c0 .485-.22.706-.7.706H8.88c-.002.063-.006.101-.006.14 0 2.115-.005 4.23.002 6.346a1.746 1.746 0 0 0 1.75 1.754c.891.008 1.783.004 2.675.002 1.063-.003 1.819-.759 1.823-1.821.002-.658 0-1.315 0-1.972v-.738Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.5}
    />
    <path
      d="M10.767 5.637a.532.532 0 0 1 .529.52.532.532 0 0 1-.534.534.533.533 0 0 1-.52-.53.533.533 0 0 1 .525-.524ZM13.228 17.309a.533.533 0 0 1 .53.52.532.532 0 0 1-.534.534.533.533 0 0 1-.52-.53.533.533 0 0 1 .524-.524Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.5}
    />
  </svg>
);

export default SvgPython;
