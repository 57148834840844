import { useEffect } from 'react';
import { withLazyImageContext } from './LazyImageContext';
import '../Carousel/carousel.css';
import { LazyImageWrapper } from '../elements';

const LazyImage = ({ src, aspectRatio, lazyLoad }: any) => {
  const paddingTop = `${(aspectRatio[1] / aspectRatio[0]) * 100}%`;
  useEffect(() => {
    if (lazyLoad) lazyLoad.update();
  }, [src, aspectRatio, lazyLoad]);
  return (
    <LazyImageWrapper>
      <div id="lazyImage" style={{ paddingTop }}>
        <img className="lazyImage__img" data-src={src} />
      </div>
    </LazyImageWrapper>
  );
};

export default withLazyImageContext(LazyImage);
