// debounce.ts
export const debounce = <T extends (...args: any[]) => void>(
  func: T,
  wait: number
) => {
  let timeout: NodeJS.Timeout | null = null;

  // The debounced function
  const debounced = (...args: any[]) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  };

  // The cancel method to clear the timeout
  debounced.cancel = () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  };

  // Return the debounced function along with cancel method
  return debounced;
};

// isString.ts
export const isString = (value: any): boolean => {
  return typeof value === 'string';
};

// isNan.ts
export const isNaN = (value: any): boolean => {
  return typeof value === 'number' && isNaN(value);
};
