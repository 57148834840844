import StyledButton from '../../../../atoms/Button';

interface ButtonPropsI {
  enabled: boolean;
  onClick: () => void;
}

interface DoneButtonPropsI {
  onDone: () => void;
}

export const NextButton = ({ enabled, onClick }: ButtonPropsI) => (
  <StyledButton
    variant="contained"
    size="small"
    onClick={onClick}
    disabled={!enabled}
    style={{ width: 104, left: 8 }}
  >
    NEXT
  </StyledButton>
);

export const PrevButton = ({ enabled, onClick }: ButtonPropsI) => (
  <StyledButton
    variant="contained"
    size="small"
    onClick={onClick}
    disabled={!enabled}
    style={{ width: 104 }}
  >
    PREVIOUS
  </StyledButton>
);

export const DoneButton = ({ onDone }: DoneButtonPropsI) => (
  <StyledButton
    variant="contained"
    size="small"
    onClick={onDone}
    style={{ width: 104, left: 8 }}
  >
    DONE
  </StyledButton>
);
